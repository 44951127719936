import { deleteBenefitsDependent, deleteBenefitsDependentSuccess, loadAvailableBenefitPlans, loadBenfitPlanTypes, saveBenefitsDependent, saveBenefitsDependentSuccess } from './../../../../core/store/benefits/benefits.actions';
import { BaseComponent } from './../../../../core/abstracts/baseComponent';
import { BenefitsState, initBenefitsDependentForm } from './../../../../core/store/benefits/benefits.reducer';
import { BenefitPlanModel, BenefitsDependentModel, BenefitPlanTypeModel, BenefitPlanFullModel } from './../../../../models/benefitsModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { MultiSelect } from 'primeng/multiselect';
import { AlertService } from '../../../../shared/services/alert.service';
import { deepClone, getAllControlsErrors, markFormGroupTouched, updateFormGroupValidity } from '../../../../helpers/utils';
import { loadBenefitsDependents, loadBenefitsPlans, updateBenefitsDependentForm } from '../../../../core/store/benefits/benefits.actions';
import { FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { tap, filter } from 'rxjs/operators';

@Component({
  selector: 'app-benefit-plan-detail',
  templateUrl: './benefit-plan-detail.component.html',
  styleUrls: ['./benefit-plan-detail.component.scss']
})
export class BenefiPlanDetailComponent extends BaseComponent implements OnInit {

  @Input() benefitPlan: BenefitPlanFullModel;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {
    this.subs.push(

    );

  }

  formatLink(url: string) {
    if (!(url.toLowerCase().startsWith('http://') || url.toLowerCase().startsWith('https://'))) {
      return 'http://' + url;
    }
    return url;

  }

}


