
  <div [formGroup]="form"  class="w-100">
    <small *ngIf="field.formFieldType.formFieldType!='Title' && field.formFieldType.formFieldType!='Section'" [class.font-weight-bold]="field.formFieldType.formFieldType=='Label'">{{getFieldLabel(field)}} <i *ngIf="getTooltip(field)" class="fas fa-exclamation-circle" [pTooltip]="getTooltip(field)" [escape]="false" ></i></small>
    <h4 *ngIf="field.formFieldType.formFieldType=='Title'" class="mb-0 mt-2">{{getFieldLabel(field)}} <i *ngIf="getTooltip(field)" class="fas fa-exclamation-circle" [pTooltip]="getTooltip(field)" [escape]="false" ></i></h4>
    <h5 *ngIf="field.formFieldType.formFieldType=='Section'" class="mb-0 mt-2">{{getFieldLabel(field)}} <i *ngIf="getTooltip(field)" class="fas fa-exclamation-circle" [pTooltip]="getTooltip(field)" [escape]="false" ></i>
      <button *ngIf="field.formFieldType.formFieldType=='Section' && field.repeat && !readOnly" class="btn btn-success" (click)="addSectionEntry(field)">Add</button>
    </h5>

    <ng-container >
      <div *ngIf="!readOnly">
        <input *ngIf="field.formFieldType.formFieldType=='Text' || field.formFieldType.formFieldType=='Phone' || field.formFieldType.formFieldType=='Email'" [readonly]="checkIsReadOnly(field)" type="text" class="form-control w-100" [formControlName]="field.formControlIdentifier" />
        <input *ngIf="field.formFieldType.formFieldType=='Number'" [readonly]="checkIsReadOnly(field)" type="number" class="form-control w-100" [formControlName]="field.formControlIdentifier" />
        <textarea *ngIf="field.formFieldType.formFieldType=='Big Text'" [readonly]="checkIsReadOnly(field)" class="form-control w-100" [formControlName]="field.formControlIdentifier"></textarea>
        <p-calendar dateMask *ngIf="field.formFieldType.formFieldType=='Date' && !field.readOnly"
                    inputStyleClass="form-control"
                    [monthNavigator]="true"
                    [yearNavigator]="true"
                    styleClass="w-100"
                    panelStyleClass="calendarPanel"
                    appendTo="body"
                    [hourFormat]="12"
                    [yearRange]="'1900:2100'"
                    [showTime]="false"
                    placeholder="Select date..."
                    [formControlName]="field.formControlIdentifier"
                    dataType="string"></p-calendar>
        <input *ngIf="field.formFieldType.formFieldType=='Date' && field.readOnly" [readonly]="checkIsReadOnly(field)" type="text" class="form-control w-100" [formControlName]="field.formControlIdentifier" />
        <p-dropdown *ngIf="field.formFieldType.formFieldType=='One Option'" [readonly]="checkIsReadOnly(field)" styleClass="form-control w-100" [options]="field.fieldOptionsList" placeholder="Select ..." [formControlName]="field.formControlIdentifier"></p-dropdown>
        <p-multiSelect *ngIf="field.formFieldType.formFieldType=='Many Options'" #multiSelectManyOptions (onFocus)="openMultiSelect(multiSelectManyOptions)" autofocusFilter="true"
                       class="w-100" styleClass="w-100 d-flex" inputStyleClass="form-control" [readonly]="checkIsReadOnly(field)" [options]="field.fieldOptionsList" placeholder="Select ..." [formControlName]="field.formControlIdentifier"></p-multiSelect>
        <input *ngIf="field.formFieldType.formFieldType=='File' && !getFieldValue(field)" class=" w-100" type="file" accept="image/*,.pdf,.doc,.docx" [readonly]="checkIsReadOnly(field)" (change)="changedFile($event, field)" />
        <div *ngIf="field.formFieldType.formFieldType=='File' && getFieldValue(field)" class="form-control w-100">
          <span>{{getFieldFileName(field)}}</span>

          <i class="far fa-times-circle text-danger ml-1" style="cursor: pointer" *ngIf="!field.readOnly" title="Delete file" (click)="deleteFile(field)"></i>
        </div>
        <img *ngIf="field.formFieldType.formFieldType=='File' && getFieldValue(field) && isImage(field)" [src]="getFieldFileUrl(field)" class="" style="max-height: 300px;" />
        <div *ngIf="field.formFieldType.formFieldType=='TextWithValue'" class="form-control w-100">
          <span>{{getTextWithValueStart(field)}}
            <input [readonly]="checkIsReadOnly(field)" type="text" class="form-control w-100" [formControlName]="field.formControlIdentifier" />
            {{getTextWithValueEnd(field)}}</span>
        </div>


        <app-validation-message [form]="form" [path]="field.formControlIdentifier" [name]="field.formControlIdentifier" [label]="getFieldLabel(field)"> </app-validation-message>
        <div *ngIf="field.allowOther && containsOther(field)">
          Other: <input type="text" [readonly]="checkIsReadOnly(field)" class="form-control w-100" [formControlName]="field.formControlIdentifier+'Other'" />
        </div>
      </div>
      <div *ngIf="readOnly">
        <span *ngIf="field.formFieldType.formFieldType!='File'">{{getFieldValue(field)}}</span>
        <div *ngIf="field.formFieldType.formFieldType=='File' && getFieldValue(field)" class="w-100">
          <span>
            <a [href]="getFieldFileUrl(field)" target="_blank">
              {{getFieldFileName(field)}}
            </a>
          </span>
        </div>
      </div>
      <div *ngIf="field.formFieldType.formFieldType == 'Section' && field.sections">
        <div *ngFor="let section of field.sections" class="row shadow m-1 mb-3 p-3 bg-white rounded ">
          <ng-container *ngFor="let sectionField of section.formFields">
            <div class="mt-1 mb-1" *ngIf="showField(sectionField)" [class.col-6]="sectionField.fieldWidthRatio==2"
                 [class.col-4]="sectionField.fieldWidthRatio==3"
                 [class.col-3]="sectionField.fieldWidthRatio==4"
                 [class.col-2]="sectionField.fieldWidthRatio==5 || sectionField.fieldWidthRatio==6"
                 [class.col-1]="sectionField.fieldWidthRatio==12"
                 [class.col-12]="!sectionField.fieldWidthRatio || sectionField.fieldWidthRatio==1">
              <app-form-field [field]="sectionField"  [readOnly]="readOnly" [form]="form" [fbForm]="fbForm" [formSubmission]="formSubmission"></app-form-field>
            </div>
          </ng-container>

          <button class="btn btn-danger m-3" *ngIf="field.repeat && !section.readOnly" (click)="removeSection(field, section)">Remove</button>

        </div>
      </div>
    </ng-container>
  </div>

