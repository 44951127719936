import { deleteBenefitsDependent, deleteBenefitsDependentSuccess, loadAvailableBenefitPlans, loadBenfitPlanTypes, saveBenefitsDependent, saveBenefitsDependentSuccess, loadBenefitsDeclineReasons, loadEmployerContribution, getBenefitsFormDocumentsList, loadPreviousBenefitPlanSet } from './../../../../core/store/benefits/benefits.actions';
import { BaseComponent } from './../../../../core/abstracts/baseComponent';
import { BenefitsState, initBenefitsDependentForm } from './../../../../core/store/benefits/benefits.reducer';
import { BenefitPlanModel, BenefitsDependentModel, BenefitPlanTypeModel, BenefitPlanFullModel, BenefitPlanDeclineReasonModel, EmployerContributionModel, BenefitPlanDeclinedModel, BenefitsSettingsModel, BenefitPlanEnrollmentSetModel, BenefitPlanEnrollmentModel } from './../../../../models/benefitsModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { MultiSelect } from 'primeng/multiselect';
import { AlertService } from '../../../../shared/services/alert.service';
import { addDays, deepClone, getAllControlsErrors, markFormGroupTouched, updateFormGroupValidity } from '../../../../helpers/utils';
import { loadBenefitsDependents, loadBenefitsPlans, updateBenefitsDependentForm } from '../../../../core/store/benefits/benefits.actions';
import { FormGroup } from '@angular/forms';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { tap, filter } from 'rxjs/operators';
import { loadProfile } from 'src/app/core/store/profile/profile.actions';
import { UserProfileModel } from 'src/app/models/userProfileModel';

@Component({
  selector: 'app-benefits-enrollment-detail',
  templateUrl: './benefits-enrollment-detail.component.html',
  styleUrls: ['./benefits-enrollment-detail.component.scss']
})
export class BenefitsEnrollmentDetailComponent extends BaseComponent implements OnInit {

  _selectedBenefitPlans: BenefitPlanFullModel[];
  get selectedBenefitPlans(): BenefitPlanFullModel[] {
    return this._selectedBenefitPlans;
  }
  @Input() set selectedBenefitPlans(value: BenefitPlanFullModel[]) {
    this._selectedBenefitPlans = value;
    this.initAvailableBenefitTypes();
  }
  @Input() checkedDependent = {};
  @Input() rejectedBenefitPlanTypes: { type: BenefitPlanTypeModel, reason: BenefitPlanDeclineReasonModel }[] = [];
  @Input() readonly;
  @Input() availableBenefitPlans: BenefitPlanFullModel[];
  @Input() benefitPlanEnrollmentSetCreatedDate;
  @Input() loadingBenefitPlanEnrollmentSet;

  @Output() editDependentsClick = new EventEmitter();
  @Output() editPlanTypeClick = new EventEmitter<number>();
  @Output() declinePlanTypeClick = new EventEmitter<number>();
  @Output() editClick = new EventEmitter<number>();



  benefitPlanTypes: BenefitPlanTypeModel[];
  benefitsDependents: BenefitsDependentModel[];
  allBenefitPlanTypes: BenefitPlanTypeModel[];
  openedPlanDetail = false;
  planDetailToOpen = null;
  dependentsWithPlanTypes: { dependent: BenefitsDependentModel, planTypeIds: number[] }[];
  notSignedDocs;
  hasAnyDoc;
  profile: UserProfileModel;
  benefitsSettings: BenefitsSettingsModel;
  previousBenefitPlanSet: BenefitPlanEnrollmentSetModel;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private confirmationService: ConfirmationService,
    private store: Store<State>,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.benefitsState.benefitsDependentsList).subscribe(benefitsDependentsList => {
        if (benefitsDependentsList && benefitsDependentsList.length && this.checkedDependent) {
          this.benefitsDependents = benefitsDependentsList;

          this.dependentsWithPlanTypes = [];
          this.benefitsDependents.forEach(dep => {
            for (const typeId in this.checkedDependent) {
              if (this.checkedDependent[typeId][dep.id]) {
                let depWithTypes = this.dependentsWithPlanTypes.find(x => x.dependent.id === dep.id);
                if (!depWithTypes) {
                  depWithTypes = { dependent: dep, planTypeIds: [] };
                  this.dependentsWithPlanTypes.push(depWithTypes);
                }
                depWithTypes.planTypeIds.push(Number(typeId));
              }
            }
          });
        }
      }),

      this.store.select(s => s.benefitsState.benefitPlanTypesList).subscribe(benefitPlanTypesList => {
        if (benefitPlanTypesList) {
          this.allBenefitPlanTypes = benefitPlanTypesList;
          this.initAvailableBenefitTypes();
        }
      }),

      this.store.select(s => s.benefitsState.previousBenefitPlanSet).subscribe(previousBenefitPlanSet => {
        if (previousBenefitPlanSet) {
          this.previousBenefitPlanSet = previousBenefitPlanSet;
        }
      }),


      this.store.select(s => s.benefitsState.docsListBenefit).subscribe(enrollmentFormDocs => {
        if (enrollmentFormDocs) {
          this.hasAnyDoc = enrollmentFormDocs.length > 0;
          this.notSignedDocs = enrollmentFormDocs.filter(doc => !doc.signedDate).length;
        }
      }),

      this.store.select(s => s.profileState.profile).subscribe(profile => {
        this.profile = profile;
      }),

      this.store.select(s => s.benefitsState.benefitsSettings).subscribe(benefitsSettings => {
        if (benefitsSettings) {
          this.benefitsSettings = benefitsSettings;
        }
      }),
    );

    this.store.dispatch(getBenefitsFormDocumentsList({ userId: null }));
    this.store.dispatch(loadBenefitsDependents());
    this.store.dispatch(loadBenfitPlanTypes());
    this.store.dispatch(loadBenefitsDeclineReasons());
    this.store.dispatch(loadPreviousBenefitPlanSet());
    this.store.dispatch(loadProfile());
  }

  getPlanTypeClass(planTypeID: number) {
    const planTypeName: string = this.getPlanTypeName(planTypeID);
    const baseClass = 'display-3 ';
    if (planTypeName === 'Medical') {
      return baseClass + 'fas fa-clinic-medical redicon';
    }
    if (planTypeName === 'Cash Rewards') {
      return baseClass + 'fas fa-badge-dollar greenicon';
    }
    if (planTypeName === 'Universal') {
      return baseClass + 'far fa-handshake';
    }
    if (planTypeName === 'Select Health (Prescription)') {
      return baseClass + 'fas fa-prescription-bottle-alt';
    }
    if (planTypeName === 'Dental') {
      return baseClass + 'far fa-tooth oceanblue';
    }
    if (planTypeName === 'Vision') {
      return baseClass + 'fas fa-glasses purple';
    }
    if (planTypeName === 'Accident') {
      return baseClass + 'far fa-hospital orange';
    }
    if (planTypeName === 'Supplemental Medical') {
      return baseClass + 'far fa-heartbeat';
    }
    if (planTypeName === 'Health Savings Account') {
      return baseClass + 'fas fa-badge-dollar greenicon';
    }
    if (planTypeName === 'Employee Perk') {
      return baseClass + 'fad fa-users-crown';
    }
  }

  getPlanOfType(planType: BenefitPlanTypeModel): BenefitPlanFullModel[] {
    return this.selectedBenefitPlans.filter(x => x.benefitPlan.benefitPlanTypeID === planType.id);
  }

  getPreviousPlanOfType(planType: BenefitPlanTypeModel): BenefitPlanEnrollmentModel[] {
    if (this.previousBenefitPlanSet && this.previousBenefitPlanSet.enrollments) {
      return this.previousBenefitPlanSet.enrollments
        .filter(x => x.benefitPlan.benefitPlan.benefitPlanTypeID === planType.id);
    }
    return null;
  }
  wasPreviousPlanOfTypeDeclined(planType: BenefitPlanTypeModel): boolean {
    if (this.previousBenefitPlanSet && this.previousBenefitPlanSet.declineds) {
      return this.previousBenefitPlanSet.declineds
        .some(x => x.benefitPlanTypeID === planType.id);
    }
    return false;
  }


  getDeclinedPlanOfType(planType: BenefitPlanTypeModel) {
    return this.rejectedBenefitPlanTypes.find(x => x.type.id === planType.id);
  }

  getCost(benefitPlan: BenefitPlanFullModel): number {
    if (benefitPlan.benefitPlan.benefitPlanTypeLabel === 'Health Savings Account' && benefitPlan.enrollment.employeePerPayElection) {
      let costValueHSA = benefitPlan.enrollment.employeePerPayElection;
      return costValueHSA;
    }

    if (benefitPlan.enrollment && benefitPlan.enrollment.id) {
      return Number(benefitPlan.enrollment.costPerPayPeriod);
    }

    if (!benefitPlan.costs) {
      return 0;
    }
    let CostCoverageId: number = this.getCostCoverageId(benefitPlan);
    let cost = benefitPlan.costs.find(x => x.coverageLevelID === CostCoverageId);
    while (!cost && CostCoverageId > 0) {
      CostCoverageId--;
      cost = benefitPlan.costs.find(x => x.coverageLevelID === CostCoverageId);
    }
    if (!cost) {
      return 0;
    }
    let costValue = benefitPlan.costs.find(x => x.coverageLevelID === CostCoverageId).costPerPayPeriod * 12 / 26;
    if (benefitPlan.enrollment && benefitPlan.enrollment.employerContribution) {
      costValue = costValue - benefitPlan.enrollment.employerContribution;
    }
    return costValue;
  }

  getCostCoverageId(benefitPlan: BenefitPlanFullModel) {

    if (benefitPlan.enrollment && benefitPlan.enrollment.id) {
      return benefitPlan.enrollment.coverageLevelID;
    }

    let hasSpouse = false;
    let hasChild = false;
    let dependentCount = 0;
    // tslint:disable-next-line:forin
    for (const i in this.checkedDependent[benefitPlan.benefitPlan.benefitPlanTypeID]) {
      if (this.checkedDependent[benefitPlan.benefitPlan.benefitPlanTypeID][i]) {
        dependentCount++;
        const dependent = this.benefitsDependents.find(x => (x.id + '') === i);
        if (dependent.relationship === 'Spouse') {
          hasSpouse = true;
        }
        if (dependent.relationship === 'Child' ||
          dependent.relationship === 'Legal Guardian') {
          hasChild = true;
        }
      }
    }

    let coverageLevelID;
    if (benefitPlan.costs.find(x => x.coverageLevelID === 5) && dependentCount === 1 && hasChild) {
      coverageLevelID = 5;
    } else if (hasSpouse && hasChild) {
      coverageLevelID = 4;
    } else if (hasChild) {
      coverageLevelID = 3;
    } else if (hasSpouse) {
      coverageLevelID = 2;
    } else {
      coverageLevelID = 1;
    }


    //Check if the coverage level has any cost, if not, get the family
    if (benefitPlan.costs && benefitPlan.costs.length) {
      // use employee+famaily if not found
      if (!benefitPlan.costs.find(x => x.coverageLevelID === coverageLevelID)
        && benefitPlan.costs.find(x => x.coverageLevelID === 4)) {
        return 4;
      }
    }
    return coverageLevelID;
  }
  getCostCoverageLabel(benefitPlan: BenefitPlanFullModel) {
    const CostCoverageId = this.getCostCoverageId(benefitPlan);

    if (CostCoverageId === 5) {
      return 'Employee + Child';
    } else if (CostCoverageId === 4) {
      return 'Employee + Family';
    } else if (CostCoverageId === 3) {
      return 'Employee + Children';
    } else if (CostCoverageId === 2) {
      return 'Employee + Spouse';
    } else {
      return 'Employee';
    }
  }

  getEffectiveDate(benefitPlan: BenefitPlanFullModel) {

    if (benefitPlan.enrollment && benefitPlan.enrollment.dateStart) {
      return new Date(benefitPlan.enrollment.dateStart);
    }

    if (new Date() > new Date(benefitPlan.benefitPlan.dateStart)) {
      if (benefitPlan.benefitPlan.ellegibilityBeginLabel === '1st of the month after ') {
        return new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1);
      }
    } else {
      return new Date(benefitPlan.benefitPlan.dateStart);
    }

  }

  getPlanTypeName(id: number) {
    return this.allBenefitPlanTypes.find(x => x.id === id) ? this.allBenefitPlanTypes.find(x => x.id === id).planTypeName : '';
  }

  openPlanDetail(benefitPlan: BenefitPlanFullModel) {
    this.openedPlanDetail = true;
    this.planDetailToOpen = benefitPlan;
  }

  getDifferentPlanTypes() {
    const distinctBenefitPlanType = (value, index, self) => {
      return self.findIndex(x => x.benefitPlan.benefitPlanTypeID === value.benefitPlan.benefitPlanTypeID) === index;
    };
    return this.selectedBenefitPlans.filter(distinctBenefitPlanType).length;
  }

  getTotalCost() {
    return this.selectedBenefitPlans.reduce((sum, plan) => {
      const cost = this.getCost(plan);
      return sum + Number(cost);
    }, 0);
  }

  hsaPlan(): BenefitPlanFullModel {
    return this.selectedBenefitPlans.find(x => x.benefitPlan.benefitPlanTypeLabel === 'Health Savings Account');
  }


  initAvailableBenefitTypes() {
    if (this.availableBenefitPlans && this.availableBenefitPlans.length > 0
      && this.allBenefitPlanTypes && this.allBenefitPlanTypes.length > 0) {

      this.benefitPlanTypes = this.allBenefitPlanTypes
        .filter(type =>
          this.availableBenefitPlans
            .filter(x => !x.benefitPlan.dependOnPlanID || this.selectedBenefitPlans.find(y => y.benefitPlan.id === x.benefitPlan.dependOnPlanID))
            .find(plan => plan.benefitPlan.benefitPlanTypeID === type.id));
    }
  }

  goToDependents() {
    this.editDependentsClick.emit();
  }

  goToPlanType(id: number) {
    this.editPlanTypeClick.emit(id);
  }
  goToDecline(id: number) {
    this.declinePlanTypeClick.emit(id);
  }

  clickEdit() {
    this.editClick.emit();
  }

  dependentsOfPlan(plan: BenefitPlanFullModel) {
    const result = [];
    const objectWithDependentIds = this.checkedDependent[plan.benefitPlan.benefitPlanTypeID];
    for (const dependenId in objectWithDependentIds) {
      if (objectWithDependentIds[dependenId] && this.benefitsDependents.find(x => x.id.toString() === dependenId)) {
        result.push(this.benefitsDependents.find(x => x.id.toString() === dependenId));
      }
    }
    return result;
  }

  isTypeDeclined(typeId: number) {
    return !!this.rejectedBenefitPlanTypes.find(x => x.type.id === typeId);
  }

  hasUpdatedProfile() {
    if (this.profile && this.profile.employee) {
      const lastProfileDate = new Date(this.profile.employee.lastUpdate);
      let today = new Date(2021, 10, 30, 23, 56);
      if (lastProfileDate > today) { //new Date(2021, 11, 1)) {
        return true;
      }
    }

    return false;
  }

  isDuringOpenEnrollment() {
    const now = new Date();
    return this.benefitsSettings
      && now < new Date(this.benefitsSettings.openEnrollmentEnd)
      && now > new Date(this.benefitsSettings.openEnrollmentStart);
  }

  clickLifeEvent() {

  }
}


