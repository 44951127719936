import { BaseComponent } from './../../../../core/abstracts/baseComponent';
import { BenefitsState, initBenefitsDependentForm } from './../../../../core/store/benefits/benefits.reducer';
import { BenefitPlanModel, BenefitPlanEnrollmentSetModel, BenefitPlanUserWithEnrollmentSetModel, BenefitClassModel, BenefitPlanEnrollmentModel, BenefitsDependentModel } from './../../../../models/benefitsModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { MultiSelect } from 'primeng/multiselect';
import { AlertService } from '../../../../shared/services/alert.service';
import { deepClone, downloadFile, formatDateString, getAllControlsErrors, getDateString, getShortDateFormatted, getTimeStampString, markFormGroupTouched, updateFormGroupValidity } from '../../../../helpers/utils';
import { deleteBenefitsDependentSuccess, deleteBenefitsPlans, deleteBenefitsPlansSuccess, downloadEnrollmentSetSignedForms, downloadEnrollmentSetSignedFormsSuccess, exportManageEmployeesListToExcel, exportManageEmployeesListToExcelSuccess, loadAllBenefitPlans, loadBenefitClasses, loadBenefitPlanUserWithEnrollmentSet, loadBenefitsPlans, loadBenfitPlanTypes, saveBenefitsDependent, saveBenefitsDependentSuccess, saveBenefitsEnrollment, saveBenefitsEnrollmentSuccess, updateBenefitsDependentForm } from '../../../../core/store/benefits/benefits.actions';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { catchError, map, tap } from 'rxjs/operators';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BenefitsService } from '../../../../core/services/benefits.service';
import { errorHappened } from '../../../../core/store/profile/profile.actions';
import { of } from 'rxjs';

@Component({
  selector: 'app-list-benefits-employees',
  templateUrl: './list-benefits-employees.component.html',
  styleUrls: ['./list-benefits-employees.component.scss']
})
export class ListBenefitsEmployeesComponent extends BaseComponent implements OnInit {

  benefitsEmployeesList: PagingResultsModel<BenefitPlanUserWithEnrollmentSetModel>;
  canEdit: boolean;
  loadingBenefitsEmployeesList = false;
  filter: any = { first: 0 };
  downloadingZipFile: boolean = false;
  zipDownloadEmployee: string = "ZipFile";

  employeeNameFilter;
  ssnFilter;
  enrolledPlanNameFilter;
  enrolledPlanCarrierFilter;
  declinedPlanNameFilter;
  declinedPlanCarrierFilter;
  enrollmentStatusFilter = 'active';
  yearFilter;
  signedFilter;
  classesFilter = [1, 2];
  openedPlans = {};
  openedDeclineds = {};
  filterTimeout;

  rowsAmount = 20;

  yesNoAllOptions = [
    { label: 'All', value: null },
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
  ];
  yearEnrollmentOptions= [
    { label: 'All', value: null },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
    { label: '2025', value: '2025' },
  ];
  enrollmentStatusOptions = [
    { label: 'All', value: null },
    { label: 'Active', value: 'active' },
    { label: 'Planned', value: 'planned' },
    { label: 'Expired', value: 'expired' },
    { label: 'Pending', value: 'incomplete' },
    { label: 'Deactivated', value: 'deactivated' },
  ];

  plansOptions = [];
  planTypesOptions = [];
  planCarriersOptions = [];


  benefitClasses: BenefitClassModel[];
  classOptions;


  benefitsDependentEdit: BenefitsDependentModel;
  benefitsDependentForm: FormGroup;


  relationshipOptions = [
    { label: 'Spouse', value: 'Spouse' },
    { label: 'Child', value: 'Child' },
    { label: 'Legal Guardian', value: 'Legal Guardian' },
  ];

  suffixOptions = [
    { label: '', value: '' },
    { label: 'CPA', value: 'CPA' },
    { label: 'CRNP', value: 'CRNP' },
    { label: 'D.O.', value: 'D.O.' },
    { label: 'DDS', value: 'DDS' },
    { label: 'DMD', value: 'DMD' },
    { label: 'II', value: 'II' },
    { label: 'III', value: 'III' },
    { label: 'IV', value: 'IV' },
    { label: 'JD', value: 'JD' },
    { label: 'Jr.', value: 'Jr.' },
    { label: 'MD', value: 'MD' },
    { label: 'PA-C', value: 'PA-C' },
    { label: 'PhD', value: 'PhD' },
    { label: 'RN', value: 'RN' },
    { label: 'Sr.', value: 'Sr.' },
    { label: 'V', value: 'V' },

  ];

  genderOptions: SelectItem[] = [
    { label: 'Male', value: 1 },
    { label: 'Female', value: 0 }];




  enrollmentSetEdit: BenefitPlanEnrollmentSetModel;
  enrollmentSetForm: FormGroup;
  enrollmentSetDependents: SelectItem[];

  lifeEventOptions: SelectItem[] = [
    { label: 'Open Enrollment', value: 1 },
    { label: 'New Hire', value: 2 },
    { label: 'Marriage', value: 3 },
    { label: 'Divorce/Legal Separation/Annulment', value: 4 },
    { label: 'PT to FT Employment', value: 5 },
    { label: 'Loss of other coverage', value: 6 },
    { label: 'Other', value: 7 },
    { label: 'New Baby Born', value: 8 }
  ];

  coverageLevels: SelectItem[] = [
    { label: 'EMP	- Employee', value: 1 },
    { label: 'ESP	- Employee + Spouse', value: 2 },
    { label: 'ECH	- Employee + Children', value: 3 },
    { label: 'FAM	- Employee + Family', value: 4 },
    { label: 'E1D	- Employee + 1', value: 5 }
  ];

  loadingExcel: boolean = false;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router,
    private confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    private benefitsService: BenefitsService,
  ) {
    super();
  }
   
  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 6050);
      }),
      this.store.select(s => s.benefitsState.benefitPlanUserWithEnrollmentSetList).subscribe(benefitPlanUserWithEnrollmentSetList => {
        if (benefitPlanUserWithEnrollmentSetList) {
          this.benefitsEmployeesList = deepClone(benefitPlanUserWithEnrollmentSetList);

          // Remove Perk from the list
          this.benefitsEmployeesList.result.forEach(employeeWithBenefits => {
            if (employeeWithBenefits.enrollmentSet) {
              employeeWithBenefits.enrollmentSet.enrollments = employeeWithBenefits.enrollmentSet.enrollments.filter(x => x.benefitPlan.benefitPlan.benefitPlanTypeLabel !== 'Employee Perk');
            }
          })
        }
      }),
      this.store.select(s => s.benefitsState.benefitPlanTypesList).subscribe(benefitPlanTypesList => {
        if (benefitPlanTypesList) {
          this.planTypesOptions = benefitPlanTypesList.filter(x => x.planTypeName !== 'Employee Perk').map(x => ({ label: x.planTypeName, value: x.planTypeName }));
          this.planTypesOptions.unshift({ label: 'All', value: null });
        }
      }),
      this.store.select(s => s.benefitsState.allBenefitPlans).subscribe(allBenefitPlans => {
        if (allBenefitPlans) {
          allBenefitPlans = allBenefitPlans.filter(x => x.benefitPlanTypeLabel !== 'Employee Perk');
          this.plansOptions = allBenefitPlans.map(x => ({ label: x.planName, value: x.planName }));
          this.plansOptions.unshift({ label: 'All', value: null });
          this.planCarriersOptions = [];
          allBenefitPlans.forEach(plan => {
            if (!this.planCarriersOptions.find(x => x.value == plan.carrier)) {
              this.planCarriersOptions.push({ label: plan.carrier, value: plan.carrier });
            }
          });
          this.planCarriersOptions.unshift({ label: 'All', value: null });

        }
      }),
      this.store.select(s => s.benefitsState.loadingBenefitPlanUserWithEnrollmentSetList).subscribe(loadingBenefitPlanUserWithEnrollmentSetList => {
        this.loadingBenefitsEmployeesList = loadingBenefitPlanUserWithEnrollmentSetList;
      }),
      this.store.select(s => s.benefitsState.benefitClasses).subscribe(benefitClasses => {
        if (benefitClasses) {
          this.benefitClasses = benefitClasses;
          this.classOptions = this.benefitClasses.map(x => ({ label: x.class, value: x.id }));
          this.classOptions.push({ label: 'Not Set', value: 0 });
          if (benefitClasses.length) {
            this.classesFilter = this.benefitClasses.filter(x => x.class !== 'Variable Hour Ineligible').map(x => (x.id));
          }
        }
      }),

      this.store.select(s => s.benefitsState.loadingManageEmployeesListToExcel).subscribe(loadingManageEmployeesListToExcel => {
        this.loadingExcel = loadingManageEmployeesListToExcel;
      }),

      this.actions$.pipe(
        ofType(downloadEnrollmentSetSignedFormsSuccess),
        tap(action => {
          downloadFile(action.doc, this.getZipFileName());
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(exportManageEmployeesListToExcelSuccess),
        tap(action => {
          downloadFile(action.doc, 'EmployeeBenefitsList.xlsx');
        })
      ).subscribe(),


      this.store.select(s => s.benefitsState.benefitsDependentInfo).subscribe(benefitsDependentInfo => {
        if (benefitsDependentInfo) {
          this.benefitsDependentEdit = benefitsDependentInfo;
        }
      }),

      this.actions$.pipe(
        ofType(saveBenefitsDependentSuccess),
        tap(action => {
          this.alertService.success('Benefits Dependent saved!');
          this.loadPage(this.filter);
          this.benefitsDependentEdit = null;
        })
      ).subscribe(),

      this.actions$.pipe(
        ofType(deleteBenefitsDependentSuccess),
        tap(action => {
          this.alertService.success('Benefits Dependent removed!');
          this.loadPage(this.filter);
        })
      ).subscribe(),


      this.actions$.pipe(
        ofType(saveBenefitsEnrollmentSuccess),
        tap(action => {
          this.alertService.success('Enrolled Plans saved!');
          this.loadPage(this.filter);
          this.enrollmentSetEdit = null;
        })
      ).subscribe(),

    );

    this.store.dispatch(loadBenefitClasses());
    this.store.dispatch(loadBenfitPlanTypes());
    this.store.dispatch(loadAllBenefitPlans());


  }

  getZipFileName() {
    let fileName = this.zipDownloadEmployee + "_" + getTimeStampString() + ".zip";

    return fileName;
  }

  loadPage(event) {
    if (!this.loadingBenefitsEmployeesList) {
      this.loadingBenefitsEmployeesList = true;
      this.filter = deepClone(event);

      this.filter.filters.employeeNameFilter = { Name: 'employeeNameFilter', Value: { value: this.employeeNameFilter } };
      this.filter.filters.ssnFilter = { Name: 'ssnFilter', Value: { value: this.ssnFilter } };
      this.filter.filters.enrolledPlanNameFilter = { Name: 'enrolledPlanNameFilter', Value: { value: this.enrolledPlanNameFilter } };
      this.filter.filters.enrolledPlanCarrierFilter = { Name: 'enrolledPlanCarrierFilter', Value: { value: this.enrolledPlanCarrierFilter } };
      this.filter.filters.declinedPlanNameFilter = { Name: 'declinedPlanNameFilter', Value: { value: this.declinedPlanNameFilter } };
      this.filter.filters.declinedPlanCarrierFilter = { Name: 'declinedPlanCarrierFilter', Value: { value: this.declinedPlanCarrierFilter } };
      this.filter.filters.enrollmentStatusFilter = { Name: 'enrollmentStatusFilter', Value: { value: this.enrollmentStatusFilter } };
      this.filter.filters.signedFilter = { Name: 'signedFilter', Value: { value: this.signedFilter } };
      this.filter.filters.yearFilter = { Name: 'yearFilter', Value: { value: this.yearFilter } };
      this.filter.filters.classesFilter = { Name: 'classesFilter', Value: { value: '' + this.classesFilter + '' } };


      if (this.filterTimeout) {
        clearTimeout(this.filterTimeout);
      }
      this.store.dispatch(loadBenefitPlanUserWithEnrollmentSet({
        filter: deepClone(this.filter)
      }));
    }
  }


  changedFilters() {
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }
    this.filterTimeout = setTimeout(() => {
      this.filter.first = 0;
      this.loadPage(this.filter);
    }, 1000);

  }
  getAllDependents(enrollmentSet: BenefitPlanEnrollmentSetModel) {
    const distinctDependents = [];
    for (const enrollment of enrollmentSet.enrollments) {
      for (const dependent of enrollment.dependents) {
        if (!distinctDependents.find(x => x.id === dependent.id)) {
          distinctDependents.push(dependent);
        }
      }
    }

    return distinctDependents;
  }


  getEnrollmentLifeChangingEvent(enrollmentSet: BenefitPlanEnrollmentSetModel) {
    if (!enrollmentSet )
      return '';

    
    if (enrollmentSet.lifeChangingEventID) {
      var lifeChangingEvent = this.lifeEventOptions.find(x => x.value === enrollmentSet.lifeChangingEventID);
      if (lifeChangingEvent) {
        if (enrollmentSet.lifeChangingEventDate)
          return lifeChangingEvent.label + ' on ' + getShortDateFormatted(new Date(enrollmentSet.lifeChangingEventDate));
        else
          return lifeChangingEvent.label
      }
    }
    if (enrollmentSet.activatedDate)
      return 'Open Enrollment';
    return '';

  }

  getEnrollmentDates(enrollmentSet: BenefitPlanEnrollmentSetModel) {
    //               {{employeeWithBenefits.enrollmentSet?.activatedDate | date:'shortDate' }}
    if (!enrollmentSet)
      return '';
    if (!enrollmentSet.enrollments || !enrollmentSet.enrollments.length)
      return enrollmentSet.plansYear;

    var periods = [];
    for (var en of enrollmentSet.enrollments) {

      let dateStart = new Date(en.dateStart);
      let dateEnd = new Date(en.dateEnd);
      var eP = '';

      if (dateStart.getMonth() == 0 && dateStart.getDate() == 1
        && (!en.dateEnd || (dateEnd.getFullYear() == dateStart.getFullYear() && dateEnd.getMonth() == 11 && dateEnd.getDate() == 31))) {
        eP = `${dateStart.getFullYear()}`
      }
      else if (en.dateEnd) {
        eP = `${getShortDateFormatted(dateStart)} - ${getShortDateFormatted(dateEnd)}`;
      } else {
        eP = `${getShortDateFormatted(dateStart)}`;
      }
      
      if (periods.indexOf(eP) == -1)
        periods.push(eP);
    }

    return periods.join(', ');
  }

  getTotalCost(enrollmentSet: BenefitPlanEnrollmentSetModel) {
    return enrollmentSet.enrollments.reduce((sum, enrollment) => {
      const cost = enrollment.costPerPayPeriod;
      return sum + Number(cost);
    }, 0);
  }
  getTotalEmployerCost(enrollmentSet: BenefitPlanEnrollmentSetModel) {
    return enrollmentSet.enrollments.reduce((sum, enrollment) => {
      const cost = enrollment.employerContribution;
      return sum + Number(cost);
    }, 0);
  }
  getClassLabel(id?: number) {
    if (!id) {
      return 'Not Set';
    }
    const benefitClass = this.benefitClasses.find(x => x.id === id);
    if (benefitClass) {
      return benefitClass.class;
    }
    return '';
  }

  openPlan(obj: BenefitPlanUserWithEnrollmentSetModel) {
    this.openedPlans[obj.user.id] = !this.openedPlans[obj.user.id];
  }
  isPlanOpen(obj: BenefitPlanUserWithEnrollmentSetModel) {
    return !!this.openedPlans[obj.user.id];
  }
  openDeclined(obj: BenefitPlanUserWithEnrollmentSetModel) {
    this.openedDeclineds[obj.user.id] = !this.openedDeclineds[obj.user.id];
  }
  isDeclinedOpen(obj: BenefitPlanUserWithEnrollmentSetModel) {
    return !!this.openedDeclineds[obj.user.id];

  }

  downloadSignDocumentZipFile(model: BenefitPlanUserWithEnrollmentSetModel) {
    if (this.downloadingZipFile) {
      return;
    }

    this.zipDownloadEmployee = model.user.firstName + "_" + model.user.lastName;

    this.store.dispatch(downloadEnrollmentSetSignedForms({ enrollmentSetId: model.enrollmentSet.id }));
  }

  exportToExcel() {
    this.filter = deepClone(this.filter);
    this.store.dispatch(exportManageEmployeesListToExcel({
      filter: this.filter
    }));
  }



  editDependent(benefitsDependent: BenefitsDependentModel) {
    
    this.benefitsDependentEdit = benefitsDependent;
    this.initDependentForm();
    
  }

  initDependentForm() {
    this.benefitsDependentForm = initBenefitsDependentForm(this.benefitsDependentEdit);
    this.subs.push(
      this.benefitsDependentForm.valueChanges.subscribe(formValues => {
        const formErrors = getAllControlsErrors(this.benefitsDependentForm);
        this.store.dispatch(updateBenefitsDependentForm({ benefitsDependentValues: formValues, formErrors }));
      }));
  }


  saveDependent() {
    console.debug(this.benefitsDependentForm.value);
    updateFormGroupValidity(this.benefitsDependentForm);
    if (this.benefitsDependentForm.valid) {
      this.store.dispatch(saveBenefitsDependent({ benefitsDependent : this.benefitsDependentEdit }));
    } else {
      markFormGroupTouched(this.benefitsDependentForm);
    }
  }


  editEnrollmentSet(enrollmentSet: BenefitPlanEnrollmentSetModel) {

    if (!this.enrollmentSetForm)
      this.initEnrollmentSetForm();

    this.enrollmentSetEdit = enrollmentSet;


    this.loadEnrollmentSetForm()
  }
  
  initEnrollmentSetForm() {
    this.enrollmentSetForm = this.formBuilder.group(
      {
        lifeChangingEventOther: ['', [Validators.maxLength(1000)]],
        lifeChangingEventID: [],
        lifeChangingEventDate: [],
        activatedDate: [],
        deactivatedDate: [],

        enrollments: this.formBuilder.array([]),
        declineds: this.formBuilder.array([])
      });


  }


  get enrollmentsFormArray() {
    if (this.enrollmentSetForm)
      return this.enrollmentSetForm.controls["enrollments"] as FormArray;
    return null;
  }

  loadEnrollmentSetForm() {

    if (!this.enrollmentSetEdit)
      return;
    this.enrollmentSetForm.clearValidators();

    var data = {
      lifeChangingEventID: this.enrollmentSetEdit.lifeChangingEventID ?? 1,
      lifeChangingEventOther: this.enrollmentSetEdit.lifeChangingEventOther,
      lifeChangingEventDate: this.enrollmentSetEdit.lifeChangingEventDate ? new Date(this.enrollmentSetEdit.lifeChangingEventDate) : null, 
      activatedDate: this.enrollmentSetEdit.activatedDate ? new Date(this.enrollmentSetEdit.activatedDate) : null,
      deactivatedDate: this.enrollmentSetEdit.deactivatedDate ? new Date(this.enrollmentSetEdit.deactivatedDate) : null,
      
      enrollments:[]
    };

    this.enrollmentsFormArray.clear();
    if (this.enrollmentSetEdit.enrollments)
      this.enrollmentSetEdit.enrollments.forEach(t => {
        var item: FormGroup = this.formBuilder.group({
          id: [0], 
          benefitPlanID: [0],
          benefitPlan: [],
          benefitPlanTypeID: [],
          benefitPlanType: [],
          coverageLevelID: [],
          dateStart: [null, [Validators.required]],
          dateEnd: [],
          costPerPayPeriod: [],
          employerContribution: [],
          employeePerPayElection: [],
          deleted: [false],
          generateNewDocumentToSign: [false],
          dependentIds: [],
          dependents: [],
          //dependentIds: this.formBuilder.array([])
        });
        this.enrollmentsFormArray.push(item);

        data.enrollments.push({
          id: t.id,
          benefitPlanID: t.benefitPlanID,
          benefitPlan: t.benefitPlan.benefitPlan.planName,
          benefitPlanTypeID: t.benefitPlan.benefitPlan.benefitPlanTypeID,
          benefitPlanType: t.benefitPlan.benefitPlan.benefitPlanTypeLabel,
          coverageLevelID: t.coverageLevelID,
          dateStart: t.dateStart ? (new Date(t.dateStart)) : null,
          dateEnd: t.dateEnd ? (new Date(t.dateEnd)) : null,
          costPerPayPeriod: t.costPerPayPeriod,
          employerContribution: t.employerContribution,
          employeePerPayElection: t.employeePerPayElection,
          deleted: false,
          generateNewDocumentToSign: false,
          dependentIds: t.dependents.map(d => d.id.toString())
          //dependents: t.dependents
        });
      });

    //console.debug(data);
    this.enrollmentSetDependents = this.getAllDependents(this.enrollmentSetEdit).map(d => { return { value: d.id.toString(), label: `${d.firstName} ${d.lastName} (${d.relationship})` } as SelectItem; });
    //this.enrollmentSetDependents = this.getAllDependents(this.enrollmentSetEdit).map(d => { return { value: d.id.toString(), label: `${d.firstName}` } as SelectItem; });
   // console.debug(this.enrollmentSetEdit);
    this.enrollmentSetForm.patchValue(data);
   
  }


  saveEnrollmentSet() {

    updateFormGroupValidity(this.enrollmentSetForm);
    if (this.enrollmentSetForm.valid) {
      try {
        var formValue = this.enrollmentSetForm.getRawValue();
        //console.debug(formValue);

        var enrollments = [];
        var allDependents = this.getAllDependents(this.enrollmentSetEdit);

        for (var fE of formValue.enrollments) {
          var enrollment = this.enrollmentSetEdit.enrollments.find(x => x.id == fE.id)
          if (!enrollment) {
            enrollment = {
              id: 0,
              benefitPlanID: fE.benefitPlanID,
            } as BenefitPlanEnrollmentModel;
            this.enrollmentSetEdit.enrollments.push(enrollment);
          }
          enrollment.dateStart = (fE.dateStart) ? getDateString(new Date(fE.dateStart)) : null;
          enrollment.dateEnd = (fE.dateEnd) ? getDateString(new Date(fE.dateEnd)) : null;
          enrollment.costPerPayPeriod = fE.costPerPayPeriod;
          enrollment.employeePerPayElection = fE.employeePerPayElection;
          enrollment.employerContribution = fE.employerContribution;
          enrollment.coverageLevelID = fE.coverageLevelID;
          enrollment.deleted = fE.deleted;
          enrollment.generateNewDocumentToSign = fE.generateNewDocumentToSign;

          var fDependents = allDependents.filter(x => {
            return fE.dependentIds.indexOf(x.id.toString()) !== -1;
          });
          enrollment.dependents = fDependents;
        }
        this.enrollmentSetEdit.isOtherUser = true;
        this.enrollmentSetEdit.lifeChangingEventID = formValue.lifeChangingEventID;
        this.enrollmentSetEdit.lifeChangingEventOther = formValue.lifeChangingEventOther;
        this.enrollmentSetEdit.lifeChangingEventDate = (formValue.lifeChangingEventDate) ? getDateString(new Date(formValue.lifeChangingEventDate)) : null;
        this.enrollmentSetEdit.activatedDate = (formValue.activatedDate) ? getDateString(new Date(formValue.activatedDate)) : null;
        this.enrollmentSetEdit.deactivatedDate = (formValue.deactivatedDate) ? getDateString(new Date(formValue.deactivatedDate)) : null;

        //  console.debug(this.enrollmentSetEdit);

        this.store.dispatch(saveBenefitsEnrollment({ enrollmentSet: this.enrollmentSetEdit }));
        /*
        this.benefitsService.postBenefitsEnrollment(this.enrollmentSetEdit).pipe(
          map(enrollmentSet => {
            this.alertService.success('Enrolled Plans saved!');
            this.loadPage(this.filter);
            this.enrollmentSetEdit = null;
  
          }),
          catchError(err => {
            
            return of(errorHappened({ err }));
          })
        ).subscribe();
          */
      } catch (ex) {
        this.alertService.error(ex);
      }
    } else {
      markFormGroupTouched(this.enrollmentSetForm);
    }
  }
}

