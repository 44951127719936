import {
  BenefitPlanModel, BenefitPlanTypeModel, BenefitPlanEllegibilityBeginModel,
  BenefitPlanEllegibilityWaitingPeriodModel, BenefitPlanTerminationUponModel, BenefitPlanFullModel,
  BenefitPlanCoverageLevelModel, BenefitPlanDocumentModel, BenefitsDependentModel, BenefitPlanDeclineReasonModel,
  BenefitPlanEnrollmentModel, BenefitPlanEnrollmentSetModel, EmployerContributionModel, BenefitClassModel,
  BenefitPlanFormDocumentsEnrollmentModel,
  BenefitsSettingsModel,
  BenefitStatusCheckModel,
  BenefitPlanUserWithEnrollmentSetModel,
  BenefitsLifeChangingEventModel,
  BenefitPlanDeclinedReportModel,
  BenefitPlanWithdrawnReportModel,
  BenefitLogReportModel,
  BenefitHsaReportModel,
  CarrierBenefitPlanModel
} from './../../models/benefitsModels';
import { ProspectiveCandidateReferenceModel } from 'src/app/models/prospectiveCandidateModel';
import { ProspectiveCandidateModel } from './../../models/prospectiveCandidateModel';
import { PagingResultsModel } from './../../models/pagingResultsModel';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { deepClone } from '../../helpers/utils';
import { SelectItem } from 'primeng/api';


@Injectable()
export class BenefitsService {

  constructor(private http: HttpClient) {
  }

  getBenfitPlans(filters: any): Observable<PagingResultsModel<BenefitPlanModel>> {
    return this.http.post<PagingResultsModel<BenefitPlanModel>>
      (`${environment.apiServer.webApiURL}/Benefits/BenefitPlanDatatable`, filters);
  }

  postBenfitPlan(benefitPlan: BenefitPlanFullModel) {
    return this.http.post<BenefitPlanFullModel>(`${environment.apiServer.webApiURL}/Benefits/BenefitPlan`,
      benefitPlan);
  }


  deleteBenefitPlans(benefitPlanID: number): Observable<any> {
    return this.http.delete<any>
      (`${environment.apiServer.webApiURL}/Benefits/BenefitPlan/${benefitPlanID}`,);
  }

  postBenfitPlanDocument(benefitPlanDoc: BenefitPlanDocumentModel) {
    const formData = new FormData();

    formData.append('files.Index', '0');
    formData.append('files[0].File', benefitPlanDoc.file);

    for (const key in benefitPlanDoc) {
      if (benefitPlanDoc[key] && key !== 'references') {
        formData.append('files[0].' + key, benefitPlanDoc[key]);
      }
    }

    const options = { headers: new HttpHeaders() };
    options.headers.append('enctype', 'multipart/form-data');

    return this.http.post<BenefitPlanDocumentModel>(`${environment.apiServer.webApiURL}/Benefits/UploadBenefitPlanDocument`,
      formData, options);
  }

  getBenfitPlanInfo(benefitPlanId: number): Observable<BenefitPlanFullModel> {
    return this.http.get<BenefitPlanFullModel>
      (`${environment.apiServer.webApiURL}/Benefits/BenefitPlan/${benefitPlanId}`);
  }

  getCarrierBenfitPlans(): Observable<CarrierBenefitPlanModel[]> {
    return this.http.get<CarrierBenefitPlanModel[]>
      (`${environment.apiServer.webApiURL}/Benefits/CarrierBenefitPlans`);
  }

  getBenfitPlanTypes(): Observable<BenefitPlanTypeModel[]> {
    return this.http.get<BenefitPlanTypeModel[]>
      (`${environment.apiServer.webApiURL}/Benefits/BenefitPlanTypes`);
  }

  getElegibilityWaitingPeriods(): Observable<BenefitPlanEllegibilityWaitingPeriodModel[]> {
    return this.http.get<BenefitPlanEllegibilityWaitingPeriodModel[]>
      (`${environment.apiServer.webApiURL}/Benefits/EllegibilityWaitingPeriods`);
  }
  getElegibilityBegins(): Observable<BenefitPlanEllegibilityBeginModel[]> {
    return this.http.get<BenefitPlanEllegibilityBeginModel[]>
      (`${environment.apiServer.webApiURL}/Benefits/EllegibilityBegins`);
  }
  getTerminationUpons(): Observable<BenefitPlanTerminationUponModel[]> {
    return this.http.get<BenefitPlanTerminationUponModel[]>
      (`${environment.apiServer.webApiURL}/Benefits/TerminationUpons`);
  }
  getCoverageLevels(): Observable<BenefitPlanCoverageLevelModel[]> {
    return this.http.get<BenefitPlanCoverageLevelModel[]>
      (`${environment.apiServer.webApiURL}/Benefits/CoverageLevels`);
  }


  getbenefitsDependents(): Observable<BenefitsDependentModel[]> {
    return this.http.get<BenefitsDependentModel[]>
      (`${environment.apiServer.webApiURL}/Benefits/BenefitsDependents`);
  }

  postbenefitsDependents(benefitsDependent: BenefitsDependentModel) {
    return this.http.post<BenefitsDependentModel>(`${environment.apiServer.webApiURL}/Benefits/BenefitsDependents`,
      benefitsDependent);
  }

  getbenefitsDependentInfo(benefitsDependentId: number): Observable<BenefitsDependentModel> {
    return this.http.get<BenefitsDependentModel>
      (`${environment.apiServer.webApiURL}/Benefits/BenefitsDependent/${benefitsDependentId}`);
  }

  deletebenefitsDependent(benefitsDependentId: number): Observable<any> {
    return this.http.delete(`${environment.apiServer.webApiURL}/Benefits/BenefitsDependent/${benefitsDependentId}`);
  }

  getAvailableBenefitPlans(): Observable<BenefitPlanFullModel[]> {
    return this.http.get<BenefitPlanFullModel[]>
      (`${environment.apiServer.webApiURL}/Benefits/AvailableBenefitPlans`);
  }

  getBenefitPlanDeclineReasons(): Observable<BenefitPlanDeclineReasonModel[]> {
    return this.http.get<BenefitPlanDeclineReasonModel[]>
      (`${environment.apiServer.webApiURL}/Benefits/DeclineReasons  `);
  }

  postBenefitsEnrollment(enrollmentSet: BenefitPlanEnrollmentSetModel) {
    return this.http.post<BenefitPlanEnrollmentSetModel>(`${environment.apiServer.webApiURL}/Benefits/BenefitsEnrollment`,
      enrollmentSet);
  }


  getBenefitPlanEnrollmentSet(): Observable<BenefitPlanEnrollmentSetModel> {
    return this.http.get<BenefitPlanEnrollmentSetModel>
      (`${environment.apiServer.webApiURL}/Benefits/BenefitPlanEnrollmentSet`);
  }

  getPreviousBenefitPlanEnrollmentSet(): Observable<BenefitPlanEnrollmentSetModel> {
    return this.http.get<BenefitPlanEnrollmentSetModel>
      (`${environment.apiServer.webApiURL}/Benefits/BenefitPlanEnrollmentSet/Previous`);
  }


  getEmployerContribution(): Observable<EmployerContributionModel> {
    return this.http.get<EmployerContributionModel>
      (`${environment.apiServer.webApiURL}/Benefits/EmployerContribution`);
  }

  getBenefitClasses(): Observable<BenefitClassModel[]> {
    return this.http.get<BenefitClassModel[]>
      (`${environment.apiServer.webApiURL}/Benefits/BenefitClasses`);
  }

  postBenefitClass(benefitClass: BenefitClassModel) {
    return this.http.post<BenefitClassModel>(`${environment.apiServer.webApiURL}/Benefits/BenefitClass`,
      benefitClass);
  }

  deleteBenefitClass(benefitClassId: number) {
    return this.http.delete(`${environment.apiServer.webApiURL}/Benefits/BenefitClass/${benefitClassId}`);
  }



  getFormDocumentsEnrollment(benefitPlanFormDocumentsEnrollmentID: number): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${environment.apiServer.webApiURL}/Benefits/DocumentToSign/${benefitPlanFormDocumentsEnrollmentID}`,
      { responseType: 'arraybuffer' as 'json' });
  }

  getFormDocumentsEnrollmentInfo(benefitPlanFormDocumentsEnrollmentID: number): Observable<BenefitPlanFormDocumentsEnrollmentModel> {
    return this.http.get<BenefitPlanFormDocumentsEnrollmentModel>
      (`${environment.apiServer.webApiURL}/Benefits/FormDocumentsEnrollmentInfo/${benefitPlanFormDocumentsEnrollmentID}`);
  }

  signFormDocumentsEnrollment(benefitPlanFormDocumentsEnrollmentID: number, signedFields: string[]): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>
      (`${environment.apiServer.webApiURL}/Benefits/SignFormDocumentsEnrollment/${benefitPlanFormDocumentsEnrollmentID}`,
        signedFields,
        { responseType: 'arraybuffer' as 'json' });
  }

  getDocumentEnrollmentSet(benefitPlanEnrollmentSetID: number): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${environment.apiServer.webApiURL}/Benefits/EnrollmentSet/DocumentToSign/${benefitPlanEnrollmentSetID}`,
      { responseType: 'arraybuffer' as 'json' });
  }


  signDocumentEnrollmentSet(benefitPlanEnrollmentSetID: number, signedFields: string[]): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>
      (`${environment.apiServer.webApiURL}/Benefits/SignDocumentEnrollmentSet/${benefitPlanEnrollmentSetID}`,
        signedFields,
        { responseType: 'arraybuffer' as 'json' });
  }


  getMyDocs(userId: number): Observable<BenefitPlanFormDocumentsEnrollmentModel[]> {
    if (userId) {
      return this.http.get<BenefitPlanFormDocumentsEnrollmentModel[]>
        (`${environment.apiServer.webApiURL}/Benefits/ListFormDocumentsEnrollment/${userId}`);

    } else {
      return this.http.get<BenefitPlanFormDocumentsEnrollmentModel[]>
        (`${environment.apiServer.webApiURL}/Benefits/MyFormDocumentsEnrollment`);
    }
  }

  getAllBenefitPlans(): Observable<BenefitPlanModel[]> {
    return this.http.get<BenefitPlanModel[]>
      (`${environment.apiServer.webApiURL}/Benefits/AllBenefitPlans`);
  }

  getBenefitsSettings(): Observable<BenefitsSettingsModel> {
    return this.http.get<BenefitsSettingsModel>
      (`${environment.apiServer.webApiURL}/Benefits/Settings`);
  }

  getBenefitStatusQueue(filter: any): Observable<PagingResultsModel<BenefitStatusCheckModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<BenefitStatusCheckModel>>(`${environment.apiServer.webApiURL}/Benefits/BenefitStatusQueue`, filter);
  }

  verifyBenefitCheck(check: BenefitStatusCheckModel): Observable<any> {
    return this.http.put<BenefitStatusCheckModel>(`${environment.apiServer.webApiURL}/Benefits/VerifyCheck/${check.benefitStatusCheckId}`, check);
  }

  switchBenefitStatus(check: BenefitStatusCheckModel): Observable<any> {
    return this.http.post<BenefitStatusCheckModel>(`${environment.apiServer.webApiURL}/Benefits/SwitchStatus/${check.benefitStatusCheckId}`, check);
  }

  markAsExemptBenefit(check: BenefitStatusCheckModel): Observable<any> {
    return this.http.post<BenefitStatusCheckModel>(`${environment.apiServer.webApiURL}/Benefits/MarkAsExempt/${check.benefitStatusCheckId}`, check);
  }

  getBenefitHsaReport(filter: any): Observable<PagingResultsModel<BenefitHsaReportModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<BenefitHsaReportModel>>(`${environment.apiServer.webApiURL}/Benefits/BenefitHsaReport`, filter);
  }

  getLogTypes(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(`${environment.apiServer.webApiURL}/Benefits/LogTypes`);
  }

  getBenefitLogList(filter: any): Observable<PagingResultsModel<BenefitLogReportModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<BenefitLogReportModel>>(`${environment.apiServer.webApiURL}/Benefits/BenefitLogList`, filter);
  }

  getWithdrawnReasons(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(`${environment.apiServer.webApiURL}/Benefits/WithdrawnReasons`);
  }

  getBenefitWithdrawnList(filter: any): Observable<PagingResultsModel<BenefitPlanWithdrawnReportModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<BenefitPlanWithdrawnReportModel>>(`${environment.apiServer.webApiURL}/Benefits/BenefitWithdrawnList`, filter);
  }

  getBenefitDeclinedList(filter: any): Observable<PagingResultsModel<BenefitPlanDeclinedReportModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<BenefitPlanDeclinedReportModel>>(`${environment.apiServer.webApiURL}/Benefits/BenefitDeclinedList`, filter);
  }

  getBenefitStatusList(filter: any): Observable<PagingResultsModel<BenefitStatusCheckModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<BenefitStatusCheckModel>>(`${environment.apiServer.webApiURL}/Benefits/BenefitStatusChecksList`, filter);
  }

  exportEmployeeBenefitStatusListToExcel(filter: any): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/Benefits/ExportBenefitStatusListToExcel`, filter, { responseType: 'arraybuffer' as 'json' });
  }

  exportBenefitHsaReportToExcel(filter: any): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/Benefits/ExportBenefitHsaReportToExcel`, filter, { responseType: 'arraybuffer' as 'json' });
  }

  exportBenefitWithdrawnToExcel(filter: any): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/Benefits/ExportBenefitWithdrawnToExcel`, filter, { responseType: 'arraybuffer' as 'json' });
  }

  getManageEmployeesDatatable(filter: any): Observable<PagingResultsModel<BenefitPlanUserWithEnrollmentSetModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<BenefitPlanUserWithEnrollmentSetModel>>(`${environment.apiServer.webApiURL}/Benefits/ManageEmployeesDatatable`, filter);
  }

  getHelpGuide(): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${environment.apiServer.webApiURL}/Benefits/HelpGuide`,
      { responseType: 'arraybuffer' as 'json' });
  }


  getBenefitsLifeChangingEvents(): Observable<BenefitsLifeChangingEventModel[]> {
    return this.http.get<BenefitsLifeChangingEventModel[]>
      (`${environment.apiServer.webApiURL}/Benefits/BenefitsLifeChangingEvents`);
  }


  postStartChangingEnrollment(LifeChangingEventID: number, LifeChangingEventOther: string, LifeChangingEventDate: string) {
    return this.http.post<BenefitPlanEnrollmentSetModel>(`${environment.apiServer.webApiURL}/Benefits/StartChangingEnrollment`,
      {
        LifeChangingEventID,
        LifeChangingEventOther,
        LifeChangingEventDate
      });
  }

  cancelChangingEnrollment() {
    return this.http.get<BenefitPlanEnrollmentSetModel>(`${environment.apiServer.webApiURL}/Benefits/CancelChangingEnrollment`);
  }

  exportEnrollmentListToExcel(filter: any): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/Benefits/ExportEnrollmentListToExcel`, filter, { responseType: 'arraybuffer' as 'json' });
  }

  exportAllDentalSelectEnrollmentListToExcel(filter: any): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/Benefits/ExportAllDentalSelectEnrollmentListToExcel`, filter, { responseType: 'arraybuffer' as 'json' });
  }

  downloadEnrollmentSetSignedForms(enrollmentSetId: number): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${environment.apiServer.webApiURL}/Benefits/DownloadEnrollmentSetSignedForms/${enrollmentSetId}`, { responseType: 'arraybuffer' as 'json' });
  }

  downloadEnrollmentSignedForms(benefitPlanId: number): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${environment.apiServer.webApiURL}/Benefits/DownloadEnrollmentSignedForms/${benefitPlanId}`, { responseType: 'arraybuffer' as 'json' });
  }


  exportManageEmployeesListToExcel(filter: any): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/Benefits/ExportManageEmployeesListToExcel`, filter, { responseType: 'arraybuffer' as 'json' });
  }
  
}
