import { FBFormFieldModel, FBFormModel, FBFormSubmissionModel, FBFormFieldValueModel, FBFormFieldSection, FBFormLanguageModel } from './../../../../models/formBuilderModels';
import { filter, tap } from 'rxjs/operators';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { EmailValidator, FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { markFormGroupTouched, getAllControlsErrors, updateFormGroupValidity, deepClone, formatDateString } from './../../../../../app/helpers/utils';
import { BaseComponent } from './../../../../core/abstracts/baseComponent';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { AlertService } from 'src/app/shared/services/alert.service';
import { clearForm, getFBForm, saveFBForm, updatedSavingSubmissionForm } from 'src/app/core/store/form-builder/form-builder.actions';
import { createFormFieldControl, createSection, getFieldOptions, getOptions, initFormBuilderForm, initFormBuilderSubmissionForm, parseField } from 'src/app/core/store/form-builder/form-builder.reducer';
import { OverlayPanel } from 'primeng/overlaypanel';
import * as Editor from 'src/assets/ckeditor/build/ckeditor.js';
import PageBreak from 'src/assets/ckeditor/build/ckeditor.js';
import Mention from 'src/assets/ckeditor/build/ckeditor.js';
import Base64UploadAdapter from 'src/assets/ckeditor/build/ckeditor.js';

import { environment } from 'src/environments/environment';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { MultiSelect } from 'primeng/multiselect';
import { FormBuilderService } from '../../../../core/services/formBuilder.service';
import { ControlValueAccessor } from '@angular/forms';


@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent extends BaseComponent implements OnInit {

  @Input() field: FBFormFieldModel;
  @Input() readOnly: boolean;
  @Input() form: FormGroup;
  @Input() fbForm: FBFormModel;
  @Input() formSubmission: FBFormSubmissionModel;
  @Input() isPrefillingForm: boolean;
  @Input() selectedFormLanguage: FBFormLanguageModel;

  formFieldTypeOptions = [];
  formTypeOptions = [];
  savingForm = false;
  loadingForm = false;
  uploadedFiles = {};

  private innerValue: any = '';
  propagateChange = (_: any) => { }

  constructor(
    private formBuilderService: FormBuilderService,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedFormLanguage && changes.selectedFormLanguage.currentValue) {
      this.field.fieldOptionsList = getOptions(this.field, changes.selectedFormLanguage.currentValue);
    }
  }


  getFieldTypeLabel(field: FBFormFieldModel) {
    const formFieldTypeOption = this.formFieldTypeOptions.find(x => x.value === field.fieldTypeID);
    if (formFieldTypeOption) {
      return formFieldTypeOption.label;
    }
  }


  containsOther(field: FBFormFieldModel) {
    if (this.formSubmission && this.formSubmission.fbFormFieldValues) {
      const fieldValue = this.getFieldValue(field);// this.formSubmission.fbFormFieldValues.find(x => x.formFieldID.toString() === field.id.toString());
      if (field.formFieldType.formFieldType === 'One Option') {
        return fieldValue
          && (fieldValue === 'Other' || !getFieldOptions(field, this.selectedFormLanguage).split('\n').some(x => x === fieldValue));
      } else if (field.formFieldType.formFieldType === 'Many Options') {
        return fieldValue && fieldValue.indexOf('Other') >= 0;
      }
    }
  }


  getFieldValue(field: FBFormFieldModel) {
    if (this.formSubmission && this.formSubmission.fbFormFieldValues) {
      const fieldValue = this.formSubmission.fbFormFieldValues.find(x => x.formFieldID.toString() === field.formControlIdentifier);
      if (fieldValue) {
        return fieldValue.value;
      }
    }
  }

  getTextWithValueStart(field: FBFormFieldModel) {
    if (field.fieldDescription.split('#value#').length > 0) {
      return field.fieldDescription.split('#value#')[0];
    }
  }
  getTextWithValueEnd(field: FBFormFieldModel) {
    if (field.fieldDescription.split('#value#').length > 1) {
      return field.fieldDescription.split('#value#')[1];
    }
  }

  getFieldFileName(field: FBFormFieldModel) {
    if (this.formSubmission && this.formSubmission.fbFormFieldValues) {
      const fieldValue = this.formSubmission.fbFormFieldValues.find(x => x.formFieldID.toString() === field.formControlIdentifier);
      if (fieldValue) {
        const file = this.uploadedFiles[fieldValue.value];
        if (file) {
          return file.name;
        } else {
          return fieldValue.fileName;
        }
      }
    }
  }

  checkIsReadOnly(field: FBFormFieldModel){
    if(field.readOnly){
      return true;
    }
    if (!this.isPrefillingForm && field.fillableBeforeSending){
      return true;
    }
    return false;
  }

  getFieldFileUrl(field: FBFormFieldModel) {
    if (this.formSubmission && this.formSubmission.fbFormFieldValues) {
      const fieldValue = this.formSubmission.fbFormFieldValues.find(x => x.formFieldID.toString() === field.formControlIdentifier);
      if (fieldValue) {
        const file = this.uploadedFiles[fieldValue.value];
        if (file) {
          return file.fileUrl;
        } else {
          return fieldValue.fileDownloadUrl;
        }
      }
    }
  }


  isImage(field: FBFormFieldModel) {
    const fileName = this.getFieldFileName(field);
    if (fileName) {
      return fileName.endsWith('.jpg') || fileName.endsWith('.jpeg') || fileName.endsWith('.png') || fileName.endsWith('.gif');
    }
  }

  openMultiSelect(multiselect: MultiSelect) {
    multiselect.show();
  }

  showField(field: FBFormFieldModel) {

    if (!field.formControlIdentifier || !this.form.get(field.formControlIdentifier)) {
      return false;
    }

    let formControl = this.form.get(field.formControlIdentifier);


    if (!field.relatedFormField)
      return true;
    else {
      let showField = true;
      if (field.relatedFormFieldID) {
        if (field.showIfRelatedAnswerIs.startsWith('>')) {
          showField = Number(this.getFieldValue(field.relatedFormField)) > Number(field.showIfRelatedAnswerIs.replace('>', ''));
        } else if (field.showIfRelatedAnswerIs.startsWith('<')) {
          showField = Number(this.getFieldValue(field.relatedFormField)) < Number(field.showIfRelatedAnswerIs.replace('<', ''));
        } else {
          showField = this.getFieldValue(field.relatedFormField) === field.showIfRelatedAnswerIs;
        }
      }

      formControl.clearValidators();
      if (showField) {
        let validators = [];

        if (field.formFieldType.formFieldType === 'Email') {
          validators.push(Validators.email);
        }
        if (field.formFieldType.formFieldType === 'Phone') {
          validators.push(Validators.pattern('^[+]{0,1}[0-9]{0,4}[( ]{0,2}[0-9]{3,4}[-) ]{0,2}[0-9]{3,4}[-]{0,1}[0-9]{4,5}$'));
        }
        if (field.formFieldType.formFieldType === 'Number') {
          validators.push(Validators.pattern('^([0-9]*[.])?[0-9]+$'));
        }

        if (showField) {//make it required
          if (field.isRequired)
            validators.push(Validators.required);
        }
        formControl.setValidators(validators);
      }
      return showField;
    }
  }

  changedFile(fileInput, field: FBFormFieldModel) {
    if (fileInput.target.files.length > 0) {
      this.store.dispatch(updatedSavingSubmissionForm({ savingFormSubmission: true }));
      this.savingForm = true;
      var file = fileInput.target.files[0];
      this.formBuilderService.uploadValueFieldFile(this.formSubmission.id, field.id, file).subscribe(fileId => {
        this.store.dispatch(updatedSavingSubmissionForm({ savingFormSubmission: false }));
        if (fileId > 0) {
          if (file) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
              file.fileUrl = e.target.result;
            };
            reader.readAsDataURL(file);
          }
          this.uploadedFiles[fileId] = file;
          this.form.get(field.formControlIdentifier.toString()).setValue(fileId.toString());
          var fieldValue = this.formSubmission.fbFormFieldValues.find(x => x.formFieldID.toString() === field.formControlIdentifier.toString());
          if (fieldValue) {
            fieldValue.fileName = file.name;
          }
        }
      }, error => {
        this.store.dispatch(updatedSavingSubmissionForm({ savingFormSubmission: false })); });
    }
  }

  deleteFile(field: FBFormFieldModel) {
    var fieldValue = this.formSubmission.fbFormFieldValues.find(x => x.formFieldID.toString() === field.id.toString() && x.entryNumber == field.entryNumber);
    if (fieldValue) {
      fieldValue.value = null;
      fieldValue.fileName = null;
      this.form.get(field.formControlIdentifier.toString()).setValue("");
    }
  }

  addSectionEntry(field: FBFormFieldModel) {
    this.loadingForm = true;
    this.formBuilderService.getSectionFields(field.id).subscribe(sectionFields => {
      this.loadingForm = false;
      createSection(field, sectionFields, this.fbForm, this.form, false, this.selectedFormLanguage);
    });
  }

  removeSection(field: FBFormFieldModel, section: FBFormFieldSection) {
    section.formFields.forEach(ff => {
      this.form.removeControl(ff.formControlIdentifier);
    });
    let index = field.sections.indexOf(section);
    field.sections.splice(index, 1);
  }


  getTooltip(field: FBFormFieldModel) {
    let fieldLanguage;
    if (this.selectedFormLanguage && this.selectedFormLanguage.languageID) {
      fieldLanguage = field.formFieldLanguages.find(x => x.languageID === this.selectedFormLanguage.languageID);
      if (fieldLanguage && fieldLanguage.tooltip) {
        return fieldLanguage.tooltip;
      }
    }

    return field.tooltip;
  }



  getFieldLabel(field: FBFormFieldModel) {
    let fieldLanguage;
    if (this.selectedFormLanguage && this.selectedFormLanguage.languageID) {
      fieldLanguage = field.formFieldLanguages.find(x => x.languageID === this.selectedFormLanguage.languageID);
      if (fieldLanguage && fieldLanguage.fieldDescription) {
        return fieldLanguage.fieldDescription;
      }
    }

    return field.fieldDescription ? field.fieldDescription : field.fieldName;
  }
}
