<form class="card mt-2" *ngIf="canEdit">
  <div class="card-body">
      <button *ngIf="canEdit" class="btn btn-primary float-right pointer" routerLink="/benefits-management/benefit-plan/addit">New Plan</button>
      <h2 class="mb-3">Benefits Plans</h2>

      <h5>Filter</h5>
      <div class="row mb-1" >
        <div class="col-6">
          <div class="row mb-1" >
            <div class="col-4 d-flex align-items-center">
              <h6>Plan Name:</h6>
            </div>
            <div class="col-8 ">
              <input type="text" [(ngModel)]="planNameFilter" class="form-control" (ngModelChange)="changedFilters()"  [ngModelOptions]="{standalone: true}">
            </div>
          </div>

          <div class="row mb-1" >
            <div class="col-4 d-flex align-items-center">
              <h6>Carrier:</h6>
            </div>
            <div class="col-8 ">
              <input type="text" [(ngModel)]="carrierFilter" class="form-control" (ngModelChange)="changedFilters()"  [ngModelOptions]="{standalone: true}">
            </div>
          </div>

          <div class="row mb-1" >
            <div class="col-4 d-flex align-items-center">
              <h6>Period:</h6>
            </div>
            <div class="col-8 ">
              <p-dropdown styleClass="form-control  w-100" [options]="datesOptions" placeholder="Select ..."  [(ngModel)]="periodDateOptionFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-dropdown>

              <p-calendar  *ngIf="periodDateOptionFilter==0"
                inputStyleClass="form-control "
                [monthNavigator]="true"
                [yearNavigator]="true"
                styleClass="w-75"
                panelStyleClass="calendarPanel"
                [hourFormat]="12"
                [yearRange]="'2010:2100'"
                [showTime]="false"
                [selectionMode]="'range'"
                [(ngModel)]="periodDateFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"
                dataType="string"
              ></p-calendar>
            </div>
          </div>

          <div class="row mb-1" >
            <div class="col-4 d-flex align-items-center">
              <h6>Date Enrolled:</h6>
            </div>
            <div class="col-8 ">
              <p-dropdown styleClass="form-control  w-100" [options]="dateEnrolledOptionList" [(ngModel)]="dateEnrolledRange" (ngModelChange)="changedEnrolledDateRange()" [ngModelOptions]="{standalone: true}"></p-dropdown>

              <p-calendar  *ngIf="isCustomDateEnrolled"
                inputStyleClass="form-control "
                [monthNavigator]="true"
                [yearNavigator]="true"
                styleClass="w-75"
                panelStyleClass="calendarPanel"
                [hourFormat]="12"
                [yearRange]="'2010:2100'"
                [showTime]="false"
                [selectionMode]="'range'"
                [(ngModel)]="dateEnrolledCustomFilter" (ngModelChange)="changedDateEnrolledCustomFilter()" [ngModelOptions]="{standalone: true}"
                dataType="string"
              ></p-calendar>
            </div>
          </div>

        </div>

      </div>

    <i *ngIf="loadingBenefitPlanList || loadingExcelDownload || downloadingZipFile" class="material-icons rotate text-muted">loop</i>
    <p-table  [value]="benefitPlanList?.result" [responsive]="true"
    [paginator]="true" [rows]="20" [totalRecords]="benefitPlanList?.totalRecords" [lazy]="true"
    (onLazyLoad)="loadPage($event)">
        <ng-template pTemplate="header">
            <tr>
                <th width="145px"></th>
                <th [pSortableColumn]="'PlanName'" >Plan
                   <p-sortIcon [field]="'PlanName'"></p-sortIcon></th>
                    <th [pSortableColumn]="'PlanType'">Type
                      <p-sortIcon [field]="'PlanType'"></p-sortIcon>
                    </th>

                <th [pSortableColumn]="'Carrier'">Carrier
                  <p-sortIcon [field]="'Carrier'"></p-sortIcon>
                </th>

                <th [pSortableColumn]="'Active'">Active
                  <p-sortIcon [field]="'Active'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'StartDate'"width="90px"> Start Date
                  <p-sortIcon [field]="'StartDate'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'EndDate'"width="90px"> End Date
                  <p-sortIcon [field]="'EndDate'"></p-sortIcon>
                </th>

                <th [pSortableColumn]="'TotalEmployeeEnrolled'"width="110px">Newly <br /> Enrolled <br />Employees <br />(Total)
                  <p-sortIcon [field]="'TotalEmployeeEnrolled'"></p-sortIcon>
                </th>

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-benefitsPlan>
            <tr >
                <td>
                  <span class="pointer mr-2" *ngIf="canEdit"   (click)="editBenefitsPlan(benefitsPlan)"><i title="Edit Benefits Plan" class="fas fa-edit"></i></span>
                  <span class="pointer mr-2" *ngIf="canEdit"   (click)="deleteBenefitsPlan(benefitsPlan)"><i title="Deactivate Benefits Plan" class="fas fa-trash"></i></span>
                  <span class="pointer mr-2" *ngIf="canEdit"   (click)="downloadSignDocumentZipFile(benefitsPlan)"><i title="Download signed forms" class="fas fa-file-archive"></i></span>
                  <span class="pointer mr-2" *ngIf="showExport(benefitsPlan)"   (click)="exportEnrollments(benefitsPlan)"><i title="Export xls with all enrollments" class="fas fa-file-excel" style="color: green;"></i></span>
                  <span class="pointer mr-2" *ngIf="showDentalSelectExport(benefitsPlan)" (click)="exportAllDentalSelectEnrollments(benefitsPlan)"><i title="Export xls with all Dental Select enrollments in one file" class="fas fa-file-excel" style="color: #007bff;"></i></span>
                </td>
                <td>
                    <span class="ui-column-title">Plan Name</span>
                  {{benefitsPlan.planName }}
                </td>

                <td>
                  <span class="ui-column-title">Plan Name</span>
                {{benefitsPlan.benefitPlanTypeLabel }}
              </td>
                <td>
                    <span class="ui-column-title">Carrier</span>
                  {{benefitsPlan.carrier }}
                </td>

                <td>
                  <span class="ui-column-title">Active</span>
                {{benefitsPlan.active?'Yes':'No' }}
              </td>
                <td>
                  <span class="ui-column-title">Start Date</span>
                  {{benefitsPlan.dateStart | date:'shortDate' }}
                </td>

                <td>
                  <span class="ui-column-title">End Date</span>
                  {{benefitsPlan.dateEnd | date:'shortDate' }}
                </td>

                <td>
                  <span class="ui-column-title">Employees Enrolled</span>
                  {{benefitsPlan.enrolledEmployees}} ({{benefitsPlan.totalEmployeeEnrolled}})
                </td>

            </tr>
        </ng-template>
    </p-table>
  </div>
</form>

<p-confirmDialog></p-confirmDialog>
