import { MedclinicCreatePatientComponent } from './medclinic/create-patient/medclinic-create-patient.component';
import { FormFillComponent } from './form-filling/form-fill/form-fill.component';
import { BenefitsClassesComponent } from './benefits-management/benefits-classes/benefits-classes.component';
import { BenefitsPlanAddItComponent } from './benefits-management/benefits-plan-addit/benefits-plan-addit.component';
import { ListBenefitsPlansComponent } from './benefits-management/list-benefits-plans/list-benefits-plans.component';
import { SelfcareComponent } from './selfcare/selfcare.component';
import { ListProspectiveCandidatesComponent } from './prospective-candidates/list-prospective-candidates/list-prospective-candidates.component';
import { DownloadOnboardingdocComponent } from './onboardingdocs/download-onboardingdoc/download-onboardingdoc.component';
import { DocFillInfoBciComponent } from './onboardingdocs/doc-fill-info/doc-fill-info-bci/doc-fill-info-bci.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { MainRoutingModule } from './main-routing.module';
import { ContactInfoComponent } from './profile/contact-info/contact-info.component';
import { ProfileComponent } from './profile/profile.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TimeCardComponent } from './timecard/timecard.component';
import { ProfileHeaderComponent } from './profile/profile-header/profile-header.component';
import { TimeCardAddItComponent } from './timecard/timecard-addit/timecard-addit.component';
import { PayrollComponent } from './payroll/payroll.component';
import { PayrollItemsTableComponent } from './payroll/payroll-items-table/payroll-items-table.component';
import { OfferLetterComponent } from './offerletter/offerletter.component';
import { CreateOfferLetterComponent } from './offerletter/create-offerletter/create-offerletter.component';
import { ListOfferLetterComponent } from './offerletter/list-offerletter/list-offerletter.component';
import { CreateEquipmentComponent } from './equipment/create-equipment/create-equipment.component';
import { ListMyEquipmentComponent } from './equipment/list-my-equipment/list-my-equipment.component';
import { ListEquipmentComponent } from './equipment/list-equipment/list-equipment.component';
import { AssignEquipmentComponent } from './equipment/assign-equipment/assign-equipment.component';
import { ReturnEquipmentComponent } from './equipment/return-equipment/return-equipment.component';
import { SignReturnEquipmentComponent } from './equipment/sign-return-equipment/sign-return-equipment.component';
import { SignAssignEquipmentComponent } from './equipment/sign-assign-equipment/sign-assign-equipment.component';
import { ListMyOnboardingDocsComponent } from './onboardingdocs/list-my-onboardingdocs/list-my-onboardingdocs.component';
import { SignOnboardingDocComponent } from './onboardingdocs/sign-onboardingdoc/sign-onboardingdoc.component';
import { ManagementComponent } from './management/management.component';
import { TimeCardFixComponent } from './timecard/timecard-fix/timecard-fix.component';
import { PayrollApprovalComponent } from './payroll/payroll-approval/payroll-approval.component';
import { PayrollRejectItemsTableComponent } from './payroll/payroll-reject-items-table/payroll-reject-items-table.component';
import { DocFillInfoComponent } from './onboardingdocs/doc-fill-info/doc-fill-info.component';
import { ListReportOnboardingdocsComponent } from './onboardingdocs/list-report-onboardingdocs/list-report-onboardingdocs.component';
import { SurveyAnswerComponent } from './survey/survey-answer/survey-answer.component';
import { SurveyListComponent } from './survey/survey-list/survey-list.component';
import { SurveyReportComponent } from './survey/survey-report/survey-report.component';
import { SurveyReportDetailComponent } from './survey/survey-report-detail/survey-report-detail.component';
import { ListGivenCoachingLogsComponent } from './coachinglogs/list-given-coachinglog/list-given-coachinglog.component';
import { CreateCoachingLogComponent } from './coachinglogs/create-coachinglog/create-coachinglog.component';
import { FollowUpCoachingLogComponent } from './coachinglogs/follow-up-coachinglog/follow-up-coachinglog.component';
import { ListMyCoachingLogComponent } from './coachinglogs/list-my-coachinglog/list-my-coachinglog.component';
import { CreateImprovementPlanComponent } from './improvementplan/create-improvementplan/create-improvementplan.component';
import { SignImprovementPlanComponent } from './improvementplan/sign-improvementPlan/sign-improvementPlan.component';
import { ViewImprovementPlanComponent } from './improvementplan/view-improvementplan/view-improvementplan.component';
import {
  CreateImprovementPlanReviewComponent
} from './improvementplan/create-improvementplanreview/create-improvementplanreview.component';
import { SignImprovementPlanReviewComponent } from './improvementplan/sign-improvementPlanReview/sign-improvementPlanReview.component';
import { DocFillInfoCriminalBackgroundComponent } from './onboardingdocs/doc-fill-info/doc-fill-info-criminal-background/doc-fill-info-criminal-background.component';
import { DocFillInfoPrismProviderAgreementComponent } from './onboardingdocs/doc-fill-info/doc-fill-info-PRISM-provider-agreement/doc-fill-info-prism-provider-agreement.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { OnboardingUploadDocsComponent } from './onboarding/onboarding-uploaddocs/onboarding-uploaddocs.component';
import { DocFillInfoi9FormComponent } from './onboardingdocs/doc-fill-info/doc-fill-info-i9form/doc-fill-info-i9form.component';
import { DocFillInfow4FormComponent } from './onboardingdocs/doc-fill-info/doc-fill-info-w4form/doc-fill-info-w4form.component';
import { OnboardingManageListComponent } from './onboarding-manage/list/onboarding-manage-list.component';
import { EmployeeFileListComponent } from './employee-file/list/employee-file-list.component';
import { SignatureComponent } from './profile/signature/signature.component';
import { DocFillInfoBciHRComponent } from './onboardingdocs/doc-fill-info/doc-fill-info-bci-hr/doc-fill-info-bci-hr.component';
import { DocFillInfoi9FormHRComponent } from './onboardingdocs/doc-fill-info/doc-fill-info-i9form-hr/doc-fill-info-i9form-hr.component';
import { DocFillInfoHRComponent } from './onboardingdocs/doc-fill-info/doc-fill-info-hr.component';
import { UploadDocsComponent } from './uploaddocs/uploaddocs.component';
import { ListHolidayComponent } from './holiday/list-holiday/list-holiday.component';
import { RecurringHolidayComponent } from './holiday/recurring-holiday/recurring-holiday.component';
import { EditHolidayComponent } from './holiday/edit-holiday/edit-holiday.component';
import { EditRecurringComponent } from './holiday/edit-recurring/edit-recurring.component';
import { ListReportFulltimestatusComponent } from './employee/list-report-fulltimestatus/list-report-fulltimestatus.component';
import { QueueFulltimestatusComponent } from './employee/queue-fulltimestatus/queue-fulltimestatus.component';
import { ReportCensusComponent } from './employee/report-census/report-census.component';
import { ReportTerminatedComponent } from './employee/report-terminated/report-terminated.component';
import { ListOnboardingDocsComponent } from './onboardingdocs/manage/list-onboardingdocs/list-onboardingdocs.component';
import { EditOnboardingDocComponent } from './onboardingdocs/manage/edit-onboardingdoc/edit-onboardingdoc.component';
import { ListOnboardingUserDocsComponent } from './onboardingdocs/manage/list-onboardinguserdocs/list-onboardinguserdocs.component';
import { ProspectiveCandidatesAddItComponent } from './prospective-candidates/prospective-candidates-addit/prospective-candidates-addit.component';
import { SurveyProspectiveCandidatesComponent } from './prospective-candidates/survey-prospective-candidates/survey-prospective-candidates.component';
import { EmployeeReferencesComponent } from './onboarding-manage/employee-references/employee-references.component';
import { InjuryQueueComponent } from './injury-queue/injury-queue.component';
import { UpdateStaffInjuryComponent } from './injury-queue/update-staff-injury/update-staff-injury.component';
import { ListPtoSummaryComponent } from './timeoff/list-pto-summary/list-pto-summary.component';
import { ListMyPtoComponent } from './timeoff/list-my-pto/list-my-pto.component';
import { EditPtoRequestComponent } from './timeoff/edit-pto-request/edit-pto-request.component';
import { ListMyTimeoffComponent } from './timeoff/list-my-timeoff/list-my-timeoff.component';
import { EditTimeoffRequestComponent } from './timeoff/edit-timeoff-request/edit-timeoff-request.component';
import { ListPtoManageComponent } from './timeoff/list-pto-manage/list-pto-manage.component';
import { ListTimeoffManageComponent } from './timeoff/list-timeoff-manage/list-timeoff-manage.component';
import { EditPtoEarnedComponent } from './timeoff/edit-pto-earned/edit-pto-earned.component';
import { ApproveTimeoffRequestComponent } from './timeoff/approve-timeoff-request/approve-timeoff-request.component';
import { TerminationReasonComponent } from './employee-file/termination-reason/termination-reason.component';
import { EmployeeReviewTemplateComponent } from './employee-review/template/template.component';
import { KraTemplateComponentComponent } from './employee-review/template/kra-template-component/kra-template-component.component';
import { KpiTemplateComponentComponent } from './employee-review/template/kpi-template-component/kpi-template-component.component';
import { KpiCategoryTemplateComponentComponent } from './employee-review/template/kpi-category-template-component/kpi-category-template-component.component';
import { KpiMeasureableTemplateComponentComponent } from './employee-review/template/kpi-measureable-template-component/kpi-measureable-template-component.component';
import { ListComponent } from './employee-review/template/list/list.component';
import { ReviewComponent } from './employee-review/player/review/review.component';
import { OversightComponent } from './employee-review/oversight/oversight.component';
import { EmployeeReviewViewComponent } from './employee-review/player/employee-review-view/employee-review-view.component';
import { EmployeeReviewListComponent } from './employee-review/employee-review-list/employee-review-list.component';
import { OversightReviewViewComponent } from './employee-review/oversight/oversight-review-view/oversight-review-view.component';
import { ValueColorManagementComponent } from './employee-review/template/value-color-management/value-color-management.component';
import { ReportCompletedKraComponent } from './employee-review/report-completed-kra/report-completed-kra.component';
import { EthicCodeComponent } from './employee-review/ethic-code/ethic-code.component';
import { ListEthicCodeComponent } from './employee-review/ethic-code/list-ethic-code/list-ethic-code.component';
import { KpiEthicsCodesComponentComponent } from './employee-review/template/kpi-ethics-codes-component/kpi-ethics-codes-component.component';
import { SelfcareCategoryComponent } from './selfcare/selfcare-category/selfcare-category.component';
import { SelfcarePlannerComponent } from './selfcare/selfcare-planner/selfcare-planner.component';
import { SelfcareOversightComponent } from './selfcare/selfcare-oversight/selfcare-oversight.component';
import { SelfcareOversightHeaderComponent } from './selfcare/selfcare-oversight-header/selfcare-oversight-header.component';
import { SelfcareManagementComponent } from './selfcare/selfcare-management/selfcare-management.component';
import { SelfcareManagementPlannerComponent } from './selfcare/selfcare-management/selfcare-management-planner/selfcare-management-planner.component';
import { SelfcareManageCategoryComponent } from './selfcare/selfcare-management/selfcare-manage-category/selfcare-manage-category.component';
import { SelfcareManagePlannerSectionComponent } from './selfcare/selfcare-management/selfcare-manage-plannersection/selfcare-manage-plannersection.component';
import { SelfcareManagementMenuComponent } from './selfcare/selfcare-management/selfcare-management-menu/selfcare-management-menu.component';
import { JobApplicationsComponent } from './job-applications/job-applications.component';
import { ApplicationDashboardComponent } from './job-applications/application-dashboard/application-dashboard.component';
import { ReportPaylocityComponent } from './paylocity/report-paylocity/report-paylocity.component';
import { ReportBenefitPlanEDIComponent } from './benefitPlanEDI/report-benefitPlanEDI/report-benefitPlanEDI.component';
import { TransmissionBenefitPlanEDIComponent } from './benefitPlanEDI/transmission-benefitPlanEDI/transmission-benefitPlanEDI.component';
import { DependentsComponent } from './benefits-enrollment/dependents/dependents.component';
import { BenefitsEnrollmentComponent } from './benefits-enrollment/enrollment/benefits-enrollment.component';
import { BenefiPlanDetailComponent } from './benefits-enrollment/benefit-plan-detail/benefit-plan-detail.component';
import { BenefitsEnrollmentDetailComponent } from './benefits-enrollment/enrollment-detail/benefits-enrollment-detail.component';
import { SignEnrollmentFormDocComponent } from './benefits-enrollment/sign-enrollmentformdoc/sign-enrollmentformdoc.component';
import { ListMyEnrollmentFormDocsComponent } from './benefits-enrollment/list-my-enrollmentformdocs/list-my-enrollmentformdocs.component';
import { QueueEligibilityStatusComponent } from './benefits-management/queue-eligibility-status/queue-eligibility-status.component';
import { ListBenefitEligibilityStatusComponent } from './benefits-management/list-benefit-eligibility-status/list-benefit-eligibility-status.component';
import { ListBenefitsEmployeesComponent } from './benefits-management/list-benefits-employees/list-benefits-employees.component';
import { ListBenefitDeclinedComponent } from './benefits-management/list-benefit-declined/list-benefit-declined.component';
import { ListBenefitWithdrawnComponent } from './benefits-management/list-benefit-withdrawn/list-benefit-withdrawn.component';
import { ListBenefitLogsComponent } from './benefits-management/list-benefit-logs/list-benefit-logs.component';
import { ListBenefitHsaComponent } from './benefits-management/list-benefit-hsa/list-benefit-hsa.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { IndeedJobApplicationComponent } from './job-applications/indeed-application/indeed-job-application/indeed-job-application.component';
import { ListUploaddocsApprovalEmployeesComponent } from './uploaddocs/list-uploaddocs-approval-employees/list-uploaddocs-approval-employees.component';
import { UploadDocsApprovalComponent } from './uploaddocs/uploaddocs-approval/uploaddocs-approval.component';
import { ListTimecardManualEntriesComponent } from './payroll/list-timecard-manual-entries/list-timecard-manual-entries.component';
import { ListUploaddocsVaccineInfoComponent } from './uploaddocs/list-uploaddocs-vaccine-info/list-uploaddocs-vaccine-info.component';
import { FormBuilderHomeComponent } from './form-builder/form-builder-home.component';
import { ListFormsComponent } from './form-builder/form-list/list-forms.component';
import { FormsAddItComponent } from './form-builder/form-addit/form-addit.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsAvailableComponent } from './form-filling/forms-available/forms-available.component';
import { FormSignComponent } from './form-filling/form-sign/form-sign.component';
import { FormsAssignmentComponent } from './form-builder/forms-assignment/forms-assignment.component';
import { FormAssignmentCreateComponent } from './form-builder/form-assignment-create/form-assignment-create.component';
import { FormSubmissionComponent } from './form-builder/form-submission/form-submission.component';
import { FormAssignmentRoleComponent } from './form-builder/form-assignment-role/form-assignment-role.component';
import { ListSelfcareCompleteComponent } from './selfcare/list-selfcare-complete/list-selfcare-complete.component';
import { FormFieldComponent } from './form-filling/form-field/form-field.component';
import { ListCertificationComponent } from './certification/list-certification/list-certification.component';
import { EditCertificationComponent } from './certification/edit-certification/edit-certification.component';
import { InstructorListCertificationComponent } from './certification/instructor-list-certification/instructor-list-certification.component';
import { InstructorEditCertificationComponent } from './certification/instructor-edit-certification/instructor-edit-certification.component';
import { UploadsCertificationComponent } from './certification/uploads-certification/uploads-certification.component';
import { ApplicationSuccessPageComponent } from './job-applications/application-success-page/application-success-page.component';
import { ScreeningInterviewComponent } from './screening-interview/screening-interview.component';
import { CriticalNewsFeedViewComponent } from './critical-news-feed/critical-news-feed-view/critical-news-feed-view.component';
import { CriticalNewsPostComponent } from './critical-news-feed/critical-news-post/critical-news-post.component';
import { AddPostComponent } from './critical-news-feed/add-post/add-post.component';
import { EquipmentNotReturnedComponent } from './equipment/equipment-not-returned/equipment-not-returned.component';
import { EquipmentTypePermissionsComponent } from './equipment/equipment-type-permissions/equipment-type-permissions.component';
import { ReportKraComponent } from './employee-review/report-kra/report-kra.component';
import { CriticalNewsPostCommentsComponent } from './critical-news-feed/critical-news-post-comments/critical-news-post-comments.component';
import { SignEnrollmentSetComponent } from './benefits-enrollment/sign-enrollmentset/sign-enrollmentset.component';
import { DocPreviewComponent } from 'src/app/modules/doc-preview/doc-preview.component';
import { MedclinicPatientsListComponent } from './medclinic/patients-list/medclinic-patients-list.component';
import { FormAssignmentModalComponent } from './form-builder/form-assignment-modal/form-assignment-modal.component';
import { EditPayableItemComponent } from './payroll/edit-payable-item/edit-payable-item.component';
import { EditCeuEventComponent } from './certification/edit-ceu-event/edit-ceu-event.component';
import { ListCeuEventComponent } from './certification/list-ceu-event/list-ceu-event.component';
import { ListApplicationComponent } from './intern/list-application/list-application.component';
import { ListManageComponent } from './intern/list-manage/list-manage.component';
import { ListInternActionTypeManageComponent } from './intern/list-intern-action-type-manage/list-intern-action-type-manage.component';
import { EditInternActionTypeComponent } from './intern/edit-intern-action-type/edit-intern-action-type.component';
import { InternReviewProcessComponent } from './intern/intern-review-process/intern-review-process.component';
import { EditInternActionComponent } from './intern/edit-intern-action/edit-intern-action.component';
import { UploadInternDocComponent } from './intern/upload-intern-doc/upload-intern-doc.component';
import { ListInternSubstatusComponent } from './intern/list-intern-substatus/list-intern-substatus.component';
import { ListInternActionItemTemplateComponent } from './intern/list-intern-action-item-template/list-intern-action-item-template.component';
import { EditInternActionItemTemplateComponent } from './intern/edit-intern-action-item-template/edit-intern-action-item-template.component';
import { EditInternSubstatusComponent } from './intern/edit-intern-substatus/edit-intern-substatus.component';
import { TicketTypesListComponent } from './ticket-types/ticket-types-list/ticket-types-list.component';
import { TicketTypeAdditComponent } from './ticket-types/ticket-types-addit/ticket-types-addit.component';
import { FormAssignmentJobtitleComponent } from './form-builder/form-assignment-jobtitle/form-assignment-jobtitle.component';


@NgModule({
  declarations: [
    HomeComponent,
    ContactInfoComponent,
    ProfileComponent,
    TimeCardComponent,
    TimeCardAddItComponent,
    TimeCardFixComponent,
    ProfileHeaderComponent,
    PayrollComponent,
    PayrollItemsTableComponent,
    OfferLetterComponent,
    CreateOfferLetterComponent,
    ListOfferLetterComponent,
    CreateEquipmentComponent,
    ListMyEquipmentComponent,
    ListEquipmentComponent,
    AssignEquipmentComponent,
    ReturnEquipmentComponent,
    SignReturnEquipmentComponent,
    SignAssignEquipmentComponent,
    ListMyOnboardingDocsComponent,
    SignOnboardingDocComponent,
    ManagementComponent,
    PayrollApprovalComponent,
    PayrollRejectItemsTableComponent,
    DocFillInfoComponent,
    DocFillInfoBciComponent,
    ListReportOnboardingdocsComponent,
    DownloadOnboardingdocComponent,
    ListGivenCoachingLogsComponent,
    CreateCoachingLogComponent,
    FollowUpCoachingLogComponent,
    ListMyCoachingLogComponent,
    CreateImprovementPlanComponent,
    SignImprovementPlanComponent,
    ViewImprovementPlanComponent,
    CreateImprovementPlanReviewComponent,
    SignImprovementPlanReviewComponent,
    DocFillInfoCriminalBackgroundComponent,
    SurveyAnswerComponent,
    SurveyListComponent,
    SurveyReportComponent,
    SurveyReportDetailComponent,
    DocFillInfoPrismProviderAgreementComponent,
    OnboardingComponent,
    OnboardingUploadDocsComponent,
    DocFillInfoi9FormComponent,
    DocFillInfow4FormComponent,
    OnboardingManageListComponent,
    EmployeeFileListComponent,
    SignatureComponent,
    DocFillInfoHRComponent,
    DocFillInfoBciHRComponent,
    DocFillInfoi9FormHRComponent,
    UploadDocsComponent,
    ListHolidayComponent,
    RecurringHolidayComponent,
    EditHolidayComponent,
    EditRecurringComponent,
    ListReportFulltimestatusComponent,
    QueueFulltimestatusComponent,
    ReportCensusComponent,
    ReportTerminatedComponent,
    ListOnboardingDocsComponent,
    EditOnboardingDocComponent,
    ListOnboardingUserDocsComponent,
    ListPtoSummaryComponent,
    ListMyPtoComponent,
    EditPtoRequestComponent,
    ListMyTimeoffComponent,
    EditTimeoffRequestComponent,
    ListPtoManageComponent,
    ListTimeoffManageComponent,
    EditPtoEarnedComponent,
    ApproveTimeoffRequestComponent,
    ListProspectiveCandidatesComponent,
    ProspectiveCandidatesAddItComponent,
    SurveyProspectiveCandidatesComponent,
    EmployeeReferencesComponent,
    InjuryQueueComponent,
    UpdateStaffInjuryComponent,
    TerminationReasonComponent,
    EmployeeReviewTemplateComponent,
    KraTemplateComponentComponent,
    KpiTemplateComponentComponent,
    KpiCategoryTemplateComponentComponent,
    KpiMeasureableTemplateComponentComponent,
    ListComponent,
    ReviewComponent,
    OversightComponent,
    EmployeeReviewViewComponent,
    EmployeeReviewListComponent,
    OversightReviewViewComponent,
    ValueColorManagementComponent,
    ReportCompletedKraComponent,
    EthicCodeComponent,
    ListEthicCodeComponent,
    KpiEthicsCodesComponentComponent,
    SelfcareCategoryComponent,
    SelfcarePlannerComponent,
    SelfcareComponent,
    SelfcareOversightComponent,
    SelfcareOversightHeaderComponent,
    SelfcareManagementComponent,
    SelfcareManagementPlannerComponent,
    SelfcareManageCategoryComponent,
    SelfcareManagePlannerSectionComponent,
    SelfcareManagementMenuComponent,
    ReportPaylocityComponent,
    ReportBenefitPlanEDIComponent,
    TransmissionBenefitPlanEDIComponent,
    ListBenefitsPlansComponent,
    BenefitsPlanAddItComponent,
    DependentsComponent,
    BenefitsEnrollmentComponent,
    BenefiPlanDetailComponent,
    BenefitsEnrollmentDetailComponent,
    BenefitsClassesComponent,
    SignEnrollmentFormDocComponent,
    ListMyEnrollmentFormDocsComponent,
    QueueEligibilityStatusComponent,
    ListBenefitEligibilityStatusComponent,
    ListBenefitsEmployeesComponent,
    ListBenefitDeclinedComponent,
    ListBenefitWithdrawnComponent,
    ListBenefitLogsComponent,
    JobApplicationsComponent,
    ApplicationDashboardComponent,
    ListBenefitHsaComponent,
    PageNotFoundComponent,
    IndeedJobApplicationComponent,
    ListUploaddocsApprovalEmployeesComponent,
    UploadDocsApprovalComponent,
    ListTimecardManualEntriesComponent,
    ListUploaddocsVaccineInfoComponent,
    FormBuilderHomeComponent,
    ListFormsComponent,
    FormsAddItComponent,
    FormsAvailableComponent,
    FormFillComponent,
    FormFieldComponent,
    FormSignComponent,
    FormsAssignmentComponent,
    FormAssignmentCreateComponent,
    FormSubmissionComponent,
    FormAssignmentRoleComponent,
    ListSelfcareCompleteComponent,
    ListCertificationComponent,
    EditCertificationComponent,
    InstructorListCertificationComponent,
    InstructorEditCertificationComponent,
    UploadsCertificationComponent,
    ApplicationSuccessPageComponent,
    ScreeningInterviewComponent,
    CriticalNewsFeedViewComponent,
    CriticalNewsPostComponent,
    AddPostComponent,
    EquipmentNotReturnedComponent,
    EquipmentTypePermissionsComponent,
    ReportKraComponent,
    CriticalNewsPostCommentsComponent,
    SignEnrollmentSetComponent,
    DocPreviewComponent,
    MedclinicPatientsListComponent,
    MedclinicCreatePatientComponent,
    FormAssignmentModalComponent,
    EditPayableItemComponent,
    EditCeuEventComponent,
    ListCeuEventComponent,
    ListApplicationComponent,
    ListManageComponent,
    ListInternActionTypeManageComponent,
    EditInternActionTypeComponent,
    InternReviewProcessComponent,
    EditInternActionComponent,
    UploadInternDocComponent,
    ListInternSubstatusComponent,
    ListInternActionItemTemplateComponent,
    EditInternActionItemTemplateComponent,
    EditInternSubstatusComponent,
    TicketTypesListComponent,
    TicketTypeAdditComponent,
    FormAssignmentJobtitleComponent
  ],
  imports: [
    CommonModule,
    MainRoutingModule,
    SharedModule,
    CKEditorModule,
  ],
  providers: [

  ],
  exports: [
    ProfileHeaderComponent
  ]
})
export class MainModule { }
