
<div class="w-100 text-center" *ngIf="loadingBenefitPlanEnrollmentSet">
  <i  class="material-icons rotate text-muted">loop</i>
</div>
<ng-container *ngIf="!loadingBenefitPlanEnrollmentSet">
    <div class="float-right" >

      <!--<button class="benefit-btn  ml-2 " *ngIf="!profile?.benefitsEnrollmentPending && isDuringOpenEnrollment()"  (click)="clickEdit()">Edit</button>-->
      <button class="benefit-btn  ml-2 " *ngIf="hasAnyDoc && readonly && hasUpdatedProfile()"  routerLink="/benefits-enrollment/docs/list">See enrollment forms
        <span class="badge badge-danger" *ngIf="notSignedDocs>0">{{notSignedDocs}}</span>
      </button>
    </div>

    <h2  class="benefit-headers-font">Benefits Enrollment</h2>

    <div class=" mb-2" *ngIf="!readonly" >
      <div class="planner-panel">
        <h2 class="benefit-headers-font">Personal Information</h2>
        <div class="description">Verify all your personal information and save it before proceeding with the benefits enrollment.</div>
        <button class="benefit-btn"  routerLink="/profile"  [queryParams]="{backTo: '/benefits-enrollment/enrollment'}">Verify personal information</button>

      </div>
    </div>

      <app-dependents [readOnly]="readonly || !hasUpdatedProfile()"></app-dependents>

      <div class=" mb-2" *ngIf="getTotalCost()>0">
        <div class="planner-panel d-flex align-items-center justify-content-center  flex-column">
          <h5 class="benefit-headers-font">Total Cost per Pay Period</h5>
          <h1 class="description-nosize">${{getTotalCost().toFixed(2)}}</h1>
        </div>
      </div>
      <div class=" mb-2" >
        <div class="planner-panel" *ngIf="benefitPlanTypes">
          <h2  class="benefit-headers-font">Plans</h2>
          <hr class="mb-2" />
          <div class=" m-3"  *ngFor="let planType of benefitPlanTypes">
            <div class="planner-panel">
              <div class="row mb-3">
                <div class="col-3 ">
                  <h3 class="mb-4 benefit-headers-font"  style="overflow-wrap: normal;">{{planType.planTypeName}}</h3>
                  <div class=" text-center align-items-center justify-content-center d-flex">
                    <i [class]="getPlanTypeClass(planType.id)"></i>
                  </div>
                </div>
                <div class="col-9" >
                  <div *ngIf="getDeclinedPlanOfType(planType)">
                    <h4 class="description-nosize">Coverage declined</h4>
                    <div class="description">Reason: {{getDeclinedPlanOfType(planType).reason.reason}}</div>
                  </div>

                  <div  *ngIf="getPlanOfType(planType) && getPlanOfType(planType).length">
                    <ng-container *ngFor="let benefitPlan of getPlanOfType(planType)">
                      <h4 class="benefit-headers-font">{{ benefitPlan.benefitPlan.planName }}</h4>
                      <hr class="mb-2" />
                      <div class="row">
                        <div class="col-6 flex-column text-center align-items-center justify-content-center d-flex">
                          <h4 *ngIf="getCost(benefitPlan)" class="description-nosize">${{getCost(benefitPlan).toFixed(2)}}</h4>
                          <div *ngIf="getCost(benefitPlan)" class="description">Cost per pay period</div>
                          <span  class="description" *ngIf="benefitPlan.enrollment?.employerContribution">Employer Contribution: ${{benefitPlan.enrollment?.employerContribution}}</span>
                        </div>
                        <div class="col-6 description">
                        <span  *ngIf="benefitPlan.benefitPlan.benefitPlanTypeLabel!=='Employee Perk'">Effective on {{getEffectiveDate(benefitPlan) | date:'shortDate'}} <br/></span>
                        <span  *ngIf="benefitPlan.benefitPlan.benefitPlanTypeLabel!=='Employee Perk'">Coverage: {{getCostCoverageLabel(benefitPlan)}}</span><br/>
                          <div class="row description" *ngFor="let dependent of dependentsOfPlan(benefitPlan)">
                            <div class="col-12">
                              &nbsp;&nbsp; - {{dependent.firstName}} {{dependent.lastName}} ({{dependent.relationship}})
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-12">
                          <button class="benefit-btn reverse-gray float-left" (click)="openPlanDetail(benefitPlan)">Details</button>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div  *ngIf="!getDeclinedPlanOfType(planType) && (!getPlanOfType(planType) || !getPlanOfType(planType).length)">
                      No plan selected<br>


                      <small *ngIf="getPreviousPlanOfType(planType) && getPreviousPlanOfType(planType).length" >
                        Previous plan:<br>

                        <ng-container *ngFor="let previousBenefitPlan of getPreviousPlanOfType(planType)">
                          {{previousBenefitPlan.benefitPlan.benefitPlan.planName}}
                          <ng-container *ngIf="previousBenefitPlan && previousBenefitPlan.dependents">
                            (<span  *ngFor="let dependent of previousBenefitPlan.dependents">
                                &nbsp;{{dependent.firstName}} {{dependent.lastName}} ({{dependent.relationship}})
                            </span>)
                          </ng-container>
                        </ng-container>
                      </small>
                      <small *ngIf="wasPreviousPlanOfTypeDeclined(planType)">
                        Previuous plans: Declined
                    </small>
                  </div>
                  <div   class="float-right d-flex flex-column">
                    <button class="benefit-btn mb-2" *ngIf="!readonly && hasUpdatedProfile() && planType.planTypeName!=='Employee Perk'"  (click)="goToPlanType(planType.id)">{{getPlanOfType(planType)?.length?'EDIT':'ENROLL'}}</button>
                    <button class="benefit-btn" *ngIf="!readonly && hasUpdatedProfile() && planType.allowDecline && !isTypeDeclined(planType.id)"  (click)="goToDecline(planType.id)" >DECLINE COVERAGE</button>
                  </div>
                </div>

              </div>

          </div>
        </div>
      </div>
      </div>
      <div class=" mb-2" *ngIf="false && hsaPlan()">
        <div class="planner-panel">
          <h5  class="benefit-headers-font">Health Savings Account</h5>

          <div class="mb-2 description" >
          <b>{{hsaPlan().benefitPlan.planName}}</b><br>
          Per pay: ${{(getCost(hsaPlan()) + hsaPlan().enrollment.employerContribution)}}<br/>
          Yearly Contribution: ${{(getCost(hsaPlan()) + hsaPlan().enrollment.employerContribution) * 26 }}<br/>
          Started: {{getEffectiveDate(hsaPlan()) | date:'shortDate'}} <br/>
          </div>
        </div>
      </div>
      <div class="card mb-2" *ngIf="false && rejectedBenefitPlanTypes && rejectedBenefitPlanTypes.length">
        <div class="card-body">
          <h5>Declined Coverage</h5>
          <hr/>
          <div class="mb-2" *ngFor="let plan of rejectedBenefitPlanTypes">
          <b>{{plan.type.planTypeName}}</b><br>
          <small> Reason: {{plan.reason.reason}}</small>
          </div>
        </div>
      </div>
    </ng-container>


<p-dialog header="Plan Detail" [focusTrap]="false" [modal]="true" [styleClass]="'top-dialog'"
          [style]="{width: '70vw'}" [(visible)]="openedPlanDetail">
          <app-benefit-plan-detail *ngIf="planDetailToOpen" [benefitPlan]="planDetailToOpen"></app-benefit-plan-detail>
</p-dialog>

<p-confirmDialog></p-confirmDialog>
