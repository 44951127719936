
<div class="card mb-2" *ngIf="openPlansScreen && !openFinishScreen">
  <div class="card-body">
    <div class="row" >
      <div class="col-12 col-xl-8 offset-xl-2">
        <div class="planner-panel mb-2">

            <h3 class="benefit-headers-font" >{{ currentPlanType?.planTypeName }}</h3>
            <p class="description">{{ currentPlanType?.description }}</p>

        </div>
        <div class="planner-panel mb-2" *ngIf="checkedDependent && currentPlanType?.allowDependent && shouldShowCoverageList()">

            <h3  class="benefit-headers-font">Dependents enrolled on this plan</h3>
            <div class="ml-2">
              <i class="fas fa-user mr-2"></i><span class="description">Myself</span><br />
              <ng-container *ngIf="loadedDependents()">
                <div class="mt-2" *ngFor="let dependent of benefitsDependents">
                  <p-checkbox [disabled]="savingBenefitEnrollment" styleClass="mr-2" [binary]="true"  [(ngModel)]="checkedDependent[currentPlanType.id][dependent.id]" ></p-checkbox><span class="description">{{dependent.firstName}} {{dependent.lastName}} ({{dependent.relationship}})</span>
                </div>
            </ng-container>
            </div>

        </div>
        <div class="planner-panel mb-2" *ngIf="shouldShowPlanList()">

            <h3 class="benefit-headers-font">Select the plan to enroll</h3>
            <div class=" m-3"  *ngFor="let benefitPlan of availableBenefitPlansOfCurrentType">
              <div class="planner-panel">
                <div class="row mb-3">
                  <div class="col-3 text-center align-items-center justify-content-center d-flex">
                    <i [class]="getPlanTypeClass()"></i>
                  </div>
                  <div class="col-9">
                    <h3 class="benefit-headers-font">{{ benefitPlan.benefitPlan.planName }}</h3>
                    <div class="row">
                      <div class="col-6 flex-column text-center align-items-center justify-content-center d-flex">
                        <ng-container *ngIf="getCost(benefitPlan)">
                          <h3 class="description-nosize">${{getCost(benefitPlan).toFixed(2)}}</h3>
                          <div class="description">Cost per pay period</div>
                        </ng-container>
                      </div>
                      <div class="col-6 description">
                        Effective on {{getEffectiveDate(benefitPlan) | date:'shortDate'}} <br/>
                        {{getCostCoverageLabel(benefitPlan)}}

                      </div>
                    </div>
                </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <button class="benefit-btn reverse-gray float-left" (click)="openPlanDetail(benefitPlan)">Details</button>
                    <button class="benefit-btn float-right" *ngIf="!savingBenefitEnrollment" [class.reverse]="!isPlanSelected(benefitPlan)" [class.green]="isPlanSelected(benefitPlan)" (click)="selectPlan(benefitPlan)">{{isPlanSelected(benefitPlan)?'Selected':'Select'}}</button>
                  </div>
                </div>
            </div>


          </div>



        </div>

        <div class=" mb-2"  *ngIf="currentPlanType?.planTypeName==='Employee Perk'">
          <div class="planner-panel">
            <div class=" m-3"  *ngFor="let benefitPlan of availableBenefitPlansOfCurrentType">
              <div class="planner-panel">
                <div class="row mb-3">
                  <div class="col-3 text-center align-items-center justify-content-center d-flex">
                    <i [class]="getPlanTypeClass()"></i>
                  </div>
                  <div class="col-9">
                    <h3 class="benefit-headers-font">{{ benefitPlan.benefitPlan.planName }}</h3>
                    <p class="description">
                      {{ benefitPlan.benefitPlan.summary }}
                    </p>
                </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <button class="benefit-btn reverse-gray float-left" (click)="openPlanDetail(benefitPlan)">Details</button>
                  </div>
                </div>
            </div>
          </div>
        </div>
        </div>

        <div class=" mb-2" *ngIf="currentSelectedPlan && currentPlanType?.planTypeName==='Universal'">
          <div class="planner-panel">
            <h3 class="benefit-headers-font">{{currentSelectedPlan?.benefitPlan.planName}}</h3>

            <p class="description">Coverage Level: {{getCostCoverageLabel(currentSelectedPlan)}}</p>
            <p class="description">{{currentSelectedPlan?.benefitPlan.summary}}</p>
          </div>
        </div>
        <div class="mb-2" *ngIf="currentSelectedPlan && currentPlanType?.planTypeName==='Health Savings Account'">
          <div class="planner-panel">
            <h3 class="benefit-headers-font">{{currentSelectedPlan?.benefitPlan.planName}}</h3>

            <h6>Review the plan information</h6>
            <div class="ml-3">IRS Contribution Limit:  ${{getIrsContributionLimit().toFixed(2)}}</div>
            <div class="ml-3">Effective Date:  {{getEffectiveDate(currentSelectedPlan) | date:'shortDate'}}</div>
            <h6>Choose your contribution</h6>
            <div class="ml-3">Eligible Yearly Contribution: ${{getElegibleYearlyContribution(currentSelectedPlan).toFixed(2)}} Per Period: ${{getPayperiodElegibleYearContribuion(getElegibleYearlyContribution(currentSelectedPlan))}}</div>
            <div class="ml-3"
            *ngIf="!hasSelectedPlanWithSpecialPercentage() && employerContribution?.employerContribution && currentSelectedPlan
        && currentPlanType?.planTypeName!=='Employee Perk' && currentPlanType?.planTypeName!=='Universal'">
        Employer contribution used per pay period:  <input class="mb-2" type="number" [disabled]="savingBenefitEnrollment" [(ngModel)]="currentSelectedPlan.enrollment.employerContribution" (ngModelChange)="changedEmployeePerPayElection()">
        (Remaining ${{getRemainingEmployerContribution().toFixed(2)}})
      </div>
            <div class="ml-3">Additional employee election per pay period:  <input type="number" [disabled]="savingBenefitEnrollment" [(ngModel)]="currentSelectedPlan.enrollment.employeePerPayElection" (ngModelChange)="changedEmployeePerPayElection()"></div>
            <h6>Review Your Selection</h6>
            Your projected deferral through {{getEndDate(currentSelectedPlan)| date:'shortDate'}} is
            <span [class.text-danger]=" this.projectedDeferral > getElegibleYearlyContribution(this.currentSelectedPlan)"> ${{projectedDeferral?.toFixed(2)}}</span>
          </div>
        </div>


        <div class="planner-panel mb-2"  *ngIf="employerContribution?.employerContribution && currentSelectedPlan
        && currentPlanType?.planTypeName!=='Employee Perk' && currentPlanType?.planTypeName!=='Universal'
        &&  !hasSelectedPlanWithSpecialPercentage()">

            <div class="float-right description">
              <a href="javascript:void(0)" (click)="openEmployerContributionSummary=!openEmployerContributionSummary" >Employer Contribution Summary</a>
              <div class="card position-absolute z10" *ngIf="openEmployerContributionSummary">
                <div class="card-body">
                  <div *ngFor="let plan of selectedBenefitPlans">
                  <b>{{plan.benefitPlan.benefitPlanTypeLabel}}:</b> ${{plan.enrollment.employerContribution?plan.enrollment.employerContribution:0}}
                  </div>
                  <hr>
                <div ><b>Total Used:</b> ${{totalUsedEmployerContribution()}}</div>
                <div ><span [class.text-danger]="getRemainingEmployerContribution()<-0.00999999999999999999"><b>Total Remaining:</b> ${{getRemainingEmployerContribution().toFixed(2)}}</span></div>
                </div>
              </div>
            </div>
            <div >
              <div class="d-flex">
                <h4  class="benefit-headers-font">Check the costs</h4>
              </div>


              <table class="w-100 description">
                    <tr>
                        <th >Plan Cost
                        </th>
                        <th >Employer Contribution <span [class.text-danger]="getRemainingEmployerContribution()<-0.00999999999999999999"> (Remaining ${{getRemainingEmployerContribution().toFixed(2)}})
                        </span>

                        <span class="ml-2 description">
                          <a href="javascript:void(0)" (click)="openWhatIsThis=!openWhatIsThis" >What is this?</a>
                          <div class="card position-absolute z10" *ngIf="openWhatIsThis">
                            <div class="card-body">
                              <h4 class="benefit-headers-font">Information about contribution  </h4>
                              <hr>
                              Full time eligible employees are allocated a certain amount of money to apply to their benefits. This is called the Employer Contribution, and it is paid by the employer to help offset the cost of your benefits. The amount for 2022 is the full cost to cover yourself under our Premier MEC Plan, and is $297.41/month, or $137.27 per pay period. You can choose to apply that contribution to any plans that are available to you, in whatever amounts make sense to your family and situation. For more information, please reach out to HR.
                            </div>
                          </div>
                        </span>
                        </th>
                        <th >My Cost
                        </th>
                    </tr>
                    <tr >
                        <td style="vertical-align: top;">
                          ${{getCost(currentSelectedPlan)?getCost(currentSelectedPlan).toFixed(2):'-'}}
                          <div class="float-right mr-3">-</div>
                        </td>
                        <td style="vertical-align: top;">
                          $<input type="number" [disabled]="savingBenefitEnrollment" [(ngModel)]="currentSelectedPlan.enrollment.employerContribution"  (ngModelChange)="changedEmployeePerPayElection()" />
                          <br><span class="description ml-2" style="font-size: 18px;">Enter the amount of your employer contribution you wish to use for this benefit in the box above</span>
                        </td>

                        <td style="vertical-align: top;">
                          <span [class.text-danger]="getMyCost(currentSelectedPlan)<0">${{getMyCost(currentSelectedPlan).toFixed(2)}}</span>
                        </td>

                    </tr>
              </table>
            </div>
        </div>
        <div class="planner-panel mb-2" *ngIf="currentSelectedPlan && currentSelectedPlan.benefitPlan.specialPercentageContribution">

            <div >
              <h4  class="benefit-headers-font">Check the costs</h4>

              <table class="w-100 description">
                    <tr>
                        <th >Plan Cost
                        </th>
                        <th >Employer Contribution ({{currentSelectedPlan.benefitPlan.specialPercentageContribution}}% of Employee)

                          <span class="ml-2 description">
                            <a href="javascript:void(0)" (click)="openWhatIsThis=!openWhatIsThis" >What is this?</a>
                            <div class="card position-absolute z10" *ngIf="openWhatIsThis">
                              <div class="card-body">
                                <h4 class="benefit-headers-font">Information about contribution  </h4>
                                <hr>
                                Full time eligible employees are allocated a certain amount of money to apply to their benefits. This is called the Employer Contribution, and it is paid by the employer to help offset the cost of your benefits. The amount for {{yearOfOpenEnrollment()+1}} is the full cost to cover yourself under our Premier MEC Plan, and is $297.41/month, or $137.27 per pay period. You can choose to apply that contribution to any plans that are available to you, in whatever amounts make sense to your family and situation. For more information, please reach out to HR.
                              </div>
                            </div>
                          </span>
                        </th>
                        <th >My Cost
                        </th>
                    </tr>
                    <tr >
                        <td style="vertical-align: top;">
                          ${{getCost(currentSelectedPlan)?getCost(currentSelectedPlan).toFixed(2):'-'}}
                          <div class="float-right mr-3">-</div>
                        </td>
                        <td style="vertical-align: top;">
                          $ {{getSpecialPercentageContributionTotal(currentSelectedPlan).toFixed(2)}}
                          <br><span class="description ml-2" style="font-size: 18px;">Enter the amount of your employer contribution you wish to use for this benefit in the box above</span>
                        </td>

                        <td  style="vertical-align: top;">
                          <span [class.text-danger]="getMyCost(currentSelectedPlan)<0">${{getMyCost(currentSelectedPlan).toFixed(2)}}</span>
                        </td>

                    </tr>
              </table>
            </div>

        </div>
        <div class="row mb-4">
          <div class="col-12 text-right">
            <div>
              <button *ngIf="!savingBenefitEnrollment" class="benefit-btn gray  float-left mb-2" (click)="back()">BACK</button>

              <i *ngIf="savingBenefitEnrollment" class="material-icons rotate text-muted">loop</i>
              <button class="benefit-btn  mb-2" [disabled]="shouldDisableSave()" (click)="saveAndContinue()">ENROLL</button>
            </div>
            <div>
              <button *ngIf="!savingBenefitEnrollment && currentPlanType?.allowDecline" class="benefit-btn btn-sm "
              (click)="rejectCurrentPlanType()" >DECLINE COVERAGE</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4" *ngIf="false">
        <div class="card mb-2">
          <div class="card-body">
            <h5>Progress: {{getDifferentPlanTypes()}} of {{benefitPlanTypes?.length+1}}</h5>
            <div class="progress">
              <div class="progress-bar" [style.width]="((getDifferentPlanTypes()/benefitPlanTypes?.length) * 100) +'%'" role="progressbar" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
        <div class="card mb-2">
          <div class="card-body">
            <h5>My Selections</h5>
            <div *ngFor="let plan of selectedBenefitPlans">
            <b>{{plan.benefitPlan.benefitPlanTypeLabel}}:</b> {{plan.benefitPlan.planName}}
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5>My Declined</h5>
            <div *ngFor="let plan of rejectedBenefitPlanTypes">
            <b>{{plan.type.planTypeName}}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div  class="card mb-2" *ngIf="openFinishScreen">
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-xl-8 offset-xl-2">
        <div class="w-100 text-center" *ngIf="!initilializedDependets">
          <i  class="material-icons rotate text-muted">loop</i>
        </div>
        <div class=" mb-2" *ngIf="openChangeEnrollment"  >
          <div class="planner-panel">
            <h2 class="benefit-headers-font">Change Enrollment</h2>
            <div class="description">Select the life changing event in order to proceed with the changes on your enrollment.</div>
            <p-dropdown styleClass="form-control mt-2 w-100" [options]="lifeEventOptions" placeholder="Select life event..."  [(ngModel)]="lifeEventSelected"></p-dropdown>
            <div *ngIf="getSelectedLifeEventLabel()=='Other'">
              <input type="text" placeholder="Describe here the other life changing event" [value]="benefitPlanEnrollmentSet?.lifeChangingEventOther" [(ngModel)]="lifeEventOther" class="mt-2 form-control" />
            </div>
            <div class="mt-2"*ngIf="lifeEventSelected && getSelectedLifeEventLabel()!='New Hire'">
              Event date:
              <p-calendar
              inputStyleClass="form-control mt-1"
              [monthNavigator]="true"
              [yearNavigator]="false"
              styleClass="w-100"
              panelStyleClass="calendarPanel"
              appendTo="body"
              [hourFormat]="12"
              [minDate]="eventMinDate"
              [maxDate]="eventMaxDate"
              [showTime]="false"
              placeholder="Select event date..."
              [(ngModel)]="lifeEventDate"
              dataType="string"
              ></p-calendar>
            </div>
            <button class="benefit-btn mt-3 "  (click)="startChangingEnrollment()">Start changing</button>
          </div>
        </div>
        <ng-container *ngIf="!openChangeEnrollment">
          <ng-template  *ngTemplateOutlet="TopBanner;" ></ng-template>
          <div class="planner-panel mt-3" *ngIf="(notSignedDocs>0) && !loadingBenefitPlanEnrollmentSet">
            <i *ngIf="savingBenefitEnrollment" class="material-icons rotate text-muted">loop</i>
                    <button class="benefit-btn green mb-2 " [class.disabled]="savingBenefitEnrollment"  routerLink="/benefits-enrollment/docs/list">
                      SIGN PLANS FORMS
                      <span class="badge badge-danger" *ngIf="notSignedDocs>0">{{notSignedDocs}}</span>
                    </button>
          </div>
          <div class="planner-panel mt-3" *ngIf="!loadingBenefitPlanEnrollmentSet && benefitPlanEnrollmentSet?.documentToSignID  && !benefitPlanEnrollmentSet?.signedDate">
            <i *ngIf="savingBenefitEnrollment" class="material-icons rotate text-muted">loop</i>
                    <button class="benefit-btn green mb-2 " [class.disabled]="savingBenefitEnrollment"  [routerLink]="'/benefits-enrollment/sign/'+benefitPlanEnrollmentSet?.id">
                      FINISH AND SIGN
                    </button>
          </div>
          <ng-container *ngIf="isDuringOpenEnrollment() || benefitPlanEnrollmentSet">
            <div  class="mt-3 mb-3" >
              <app-benefits-enrollment-detail
              *ngIf="selectedBenefitPlans && checkedDependent && rejectedBenefitPlanTypes && availableBenefitPlans"
              [selectedBenefitPlans]="selectedBenefitPlans"
              [checkedDependent]="checkedDependent"
              [rejectedBenefitPlanTypes]="rejectedBenefitPlanTypes"
              [availableBenefitPlans]="availableBenefitPlans"
              [benefitPlanEnrollmentSetCreatedDate]="benefitPlanEnrollmentSetCreatedDate"
              [loadingBenefitPlanEnrollmentSet]="loadingBenefitPlanEnrollmentSet"
              (editDependentsClick)="goToDependents()"
              (declinePlanTypeClick)="declinePlanType($event)"
              (editPlanTypeClick)="goToPlanType($event)"
              (editClick)="setEditClicked()"
              [readonly]="initilializedDependets && !editClicked && benefitPlanEnrollmentSet?.activatedDate"
              ></app-benefits-enrollment-detail>
            </div>
            <div class="planner-panel mb-3" *ngIf="(notSignedDocs>0) && !loadingBenefitPlanEnrollmentSet">
              <i *ngIf="savingBenefitEnrollment" class="material-icons rotate text-muted">loop</i>
                      <button class="benefit-btn green mb-2 " [class.disabled]="savingBenefitEnrollment"  routerLink="/benefits-enrollment/docs/list">
                        SIGN PLANS FORMS
                        <span class="badge badge-danger" *ngIf="notSignedDocs>0">{{notSignedDocs}}</span>
                      </button>
            </div>
            <div class="planner-panel mb-3" *ngIf="!loadingBenefitPlanEnrollmentSet && benefitPlanEnrollmentSet?.documentToSignID  && !benefitPlanEnrollmentSet?.signedDate">
              <i *ngIf="savingBenefitEnrollment" class="material-icons rotate text-muted">loop</i>
                      <button class="benefit-btn green mb-2 " [class.disabled]="savingBenefitEnrollment"  [routerLink]="'/benefits-enrollment/sign/'+benefitPlanEnrollmentSet?.id">
                        FINISH AND SIGN
                      </button>
            </div>
            <ng-template  *ngTemplateOutlet="TopBanner;" ></ng-template>
          </ng-container>
          </ng-container>
        </div>
      </div>
  </div>
</div>

<p-dialog header="Plan Detail" [focusTrap]="false" [modal]="true" [styleClass]="'top-dialog'"
          [style]="{width: '70vw'}" [(visible)]="openedPlanDetail">
          <app-benefit-plan-detail *ngIf="planDetailToOpen" [benefitPlan]="planDetailToOpen"></app-benefit-plan-detail>
</p-dialog>

<p-confirmDialog></p-confirmDialog>

<p-dialog header="Choose a reason for declining this benefit" [(visible)]="showDeclineReason">
  <div *ngFor="let reason of declineReasons">
    <div>
      <p-radioButton name="delcineReason" [value]="reason" [(ngModel)]="selectedReason"></p-radioButton> {{reason.reason}}
    </div>
  </div>
  <div *ngIf="selectedReason != null && selectedReason.reason == 'Other'">
    <input type="text" [value]="otherReason" [(ngModel)]="otherReason" class="form-control" />
  </div>
  <hr/>
  <button class="btn btn-secondary float-left mb-2" (click)="cancelRejectCurrentPlan()">Cancel</button>
  <button class="btn btn-success float-right mb-2" [disabled]="!selectedReason" (click)="confirmRejectCurrentPlan()">Apply</button>

</p-dialog>

<ng-template #TopBanner>
  <div class="planner-panel" *ngIf="initilializedDependets && !editClicked && benefitPlanEnrollmentSet?.activatedDate" >
    <div class="float-right mt-3 mb-3">
      <i *ngIf="loadingHelpGuide" class="material-icons rotate text-muted">loop</i>
      <button class="benefit-btn reverse-gray" *ngIf="false" (click)="downloadHelpGuide()" >Help Guide</button>
    </div>
    <h2 class="benefit-headers-font green" *ngIf="yearOfPlan>yearOfOpenEnrollment()">Enrollment Complete {{yearOfPlan}}</h2>

    <p class="description" *ngIf="isDuringOpenEnrollment()">OPEN ENROLLMENT
      <br>
      <button class="benefit-btn "  (click)="startOpenEnrollment()">Start Open Enrollment</button>
    </p>
    <div class=" mb-2 mt-2" *ngIf="!isDuringOpenEnrollment()"  >
      <div class="description">Open enrollment period is over.
        <br> You can change your enrollment only in case of life events such as marriage, divorce, change from Part Time to Full Time, loss of coverage, ...</div>
      <button class="benefit-btn "  (click)="clickChangeEnrollment()">Change Enrollment</button>
    </div>
  </div>

  <div class="planner-panel"  *ngIf="initilializedDependets && !benefitPlanEnrollmentSet?.activatedDate">
    <div class="float-right">
      <i *ngIf="loadingHelpGuide" class="material-icons rotate text-muted">loop</i>
      <button class="benefit-btn reverse-gray" *ngIf="false" (click)="downloadHelpGuide()" >Help Guide</button>
    </div>
    <h3 class="benefit-headers-font red">Enrollment is not complete</h3>
    <p class="description">Please select a plan or decline coverage on all benefits, then click Finish and Sign at the bottom, and make sure to sign all your forms.</p>

    <p class="description" *ngIf="benefitPlanEnrollmentSet?.lifeChangingEventID>1">Life changing event: {{getLifeEventLabel(benefitPlanEnrollmentSet)}}
      <span *ngIf="benefitPlanEnrollmentSet?.lifeChangingEventID && getLifeEventLabel(benefitPlanEnrollmentSet)=='Other'">({{benefitPlanEnrollmentSet?.lifeChangingEventOther}})</span>
    </p>
    <p class="description" *ngIf="benefitPlanEnrollmentSet?.lifeChangingEventDate">Event date: {{benefitPlanEnrollmentSet?.lifeChangingEventDate | date:'shortDate'}}
    </p>
    <p class="description" *ngIf="isDuringOpenEnrollment()">OPEN ENROLLMENT

      <button class="benefit-btn " *ngIf="!benefitPlanEnrollmentSet"  (click)="startOpenEnrollment()">Start Open Enrollment</button>
    </p>
    <div class=" mb-2 mt-2" *ngIf="!isDuringOpenEnrollment() && !benefitPlanEnrollmentSet"  >
      <div class="description">Open enrollment period is over.
        <br> You can change your enrollment only in case of life events such as marriage, divorce, change from Part Time to Full Time, loss of coverage, ...</div>
      <button class="benefit-btn "  (click)="clickChangeEnrollment()">Change Enrollment</button>
    </div>

      <div class=" mb-2 mt-2" *ngIf="benefitPlanEnrollmentSet">

        <div class="description">
          If you want to cancel changing your benefits enrollment click below</div>
        <button class="benefit-btn "  (click)="clickCancelEnrollment()">Cancel Enrollment</button>
      </div>

  </div>
</ng-template>
