import { UserProfileModel } from './../../../../models/userProfileModel';
import { deleteBenefitsDependent, deleteBenefitsDependentSuccess, loadAvailableBenefitPlans, loadBenfitPlanTypes, saveBenefitsDependent, saveBenefitsDependentSuccess, loadBenefitsDeclineReasons, saveBenefitsEnrollment, loadBenefitPlanEnrollmentSet, saveBenefitsEnrollmentSuccess, loadEmployerContribution, getBenefitsFormDocumentsList, loadBenefitsSettings, loadBenefitPlanEnrollmentSetFail, loadBenefitPlanEnrollmentSetSuccess, loadHelpGuide, loadBenefitsLifeChangingEvents, startChangingEnrollment, cancelChangingEnrollment, startChangingEnrollmentSuccess, cancelChangingEnrollmentSuccess } from './../../../../core/store/benefits/benefits.actions';
import { BaseComponent } from './../../../../core/abstracts/baseComponent';
import { BenefitsState, initBenefitsDependentForm } from './../../../../core/store/benefits/benefits.reducer';
import { BenefitPlanModel, BenefitsDependentModel, BenefitPlanTypeModel, BenefitPlanFullModel, BenefitPlanDeclineReasonModel, BenefitPlanEnrollmentSetModel, BenefitPlanEnrollmentModel, BenefitPlanDeclinedModel, EmployerContributionModel, BenefitsSettingsModel } from './../../../../models/benefitsModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { MultiSelect } from 'primeng/multiselect';
import { AlertService } from '../../../../shared/services/alert.service';
import { addDays, deepClone, downloadFile, formatDateString, getAllControlsErrors, getDateString, getAge, markFormGroupTouched, updateFormGroupValidity } from '../../../../helpers/utils';
import { loadBenefitsDependents, loadBenefitsPlans, updateBenefitsDependentForm } from '../../../../core/store/benefits/benefits.actions';
import { FormGroup } from '@angular/forms';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { tap, filter } from 'rxjs/operators';
import { loadProfile } from 'src/app/core/store/profile/profile.actions';

@Component({
  selector: 'app-benefits-enrollment',
  templateUrl: './benefits-enrollment.component.html',
  styleUrls: ['./benefits-enrollment.component.scss']
})
export class BenefitsEnrollmentComponent extends BaseComponent implements OnInit {

  benefitPlanTypes: BenefitPlanTypeModel[];
  allBenefitPlanTypes: BenefitPlanTypeModel[];
  availableBenefitPlans: BenefitPlanFullModel[];
  availableBenefitPlansOfCurrentType: BenefitPlanFullModel[];
  benefitsDependents: BenefitsDependentModel[];
  currentPlanType: BenefitPlanTypeModel;
  currentPlanIndex: number;
  selectedBenefitPlans: BenefitPlanFullModel[] = [];
  checkedDependent;
  openedPlanDetail = false;
  planDetailToOpen = null;
  currentSelectedPlan: BenefitPlanFullModel;
  initilializedDependets = false;
  endYearDate = new Date(new Date().getFullYear(), 11, 31);
  endNextYearDate = new Date(new Date().getFullYear() + 1, 11, 31);
  startNextYearDate = new Date(new Date().getFullYear() + 1, 0, 1);
  nextYear = new Date().getFullYear() + 1;
  yearOfPlan;

  projectedDeferral = 0;
  rejectedBenefitPlanTypes: { type: BenefitPlanTypeModel, reason: BenefitPlanDeclineReasonModel, otherReason: string }[] = [];
  showDeclineReason = false;
  decliningTypeId;
  selectedReason;
  otherReason: string;
  declineReasons: BenefitPlanDeclineReasonModel[];
  openFinishScreen = true;
  openPlansScreen = true;
  loadingBenefitPlanEnrollmentSet = true;
  savingBenefitEnrollment = false;
  employerContribution: EmployerContributionModel;
  openEmployerContributionSummary;

  benefitPlanEnrollmentSetID: number;
  benefitPlanEnrollmentSetCreatedDate: string;
  benefitPlanEnrollmentSet: BenefitPlanEnrollmentSetModel;
  benefitsSettings: BenefitsSettingsModel;

  generatingDocs;
  hasAnyDoc;
  notSignedDocs;
  editClicked;
  openWhatIsThis;
  loadingHelpGuide;

  profile: UserProfileModel;

  openChangeEnrollment = false;
  lifeEventOptions: SelectItem[] = [
  ];
  lifeEventSelected;
  lifeEventOther;
  lifeEventDate;

  eventMinDate;
  eventMaxDate;

  initilializedPlans;
  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private confirmationService: ConfirmationService,
    private store: Store<State>,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {
    this.eventMinDate = addDays(new Date(), -30);
    this.eventMaxDate = new Date();
    window.scrollTo(0, 0);
    this.subs.push(
      this.store.select(s => s.benefitsState.loadingBenefitPlanEnrollmentSet).subscribe(loadingBenefitPlanEnrollmentSet => {
        this.loadingBenefitPlanEnrollmentSet = loadingBenefitPlanEnrollmentSet;
      }),
      this.store.select(s => s.benefitsState.benefitPlanEnrollmentSet).subscribe(benefitPlanEnrollmentSet => {
        if (benefitPlanEnrollmentSet && this.initilializedDependets) {
          this.benefitPlanEnrollmentSet = benefitPlanEnrollmentSet;
          this.benefitPlanEnrollmentSetID = benefitPlanEnrollmentSet.id;
          this.benefitPlanEnrollmentSetCreatedDate = benefitPlanEnrollmentSet.createdDate;

          this.selectedBenefitPlans = [];
          this.rejectedBenefitPlanTypes = [];

          benefitPlanEnrollmentSet.enrollments.forEach(enrollment => {
            const plan: BenefitPlanFullModel = deepClone(enrollment.benefitPlan);
            this.yearOfPlan = new Date(plan.benefitPlan.dateStart).getFullYear();
            plan.enrollment = deepClone(enrollment);
            this.selectedBenefitPlans.push(plan);
            if (enrollment.dependents && enrollment.dependents.length) {
              if (!this.checkedDependent[plan.benefitPlan.benefitPlanTypeID + '']) {
                this.checkedDependent[plan.benefitPlan.benefitPlanTypeID + ''] = {};
              }
              enrollment.dependents.forEach(dependent => {
                this.checkedDependent[plan.benefitPlan.benefitPlanTypeID + ''][dependent.id + ''] = true;
              });
            }
          });
          benefitPlanEnrollmentSet.declineds.forEach(declined => {
            this.rejectedBenefitPlanTypes.push({ type: declined.benefitPlanType, reason: declined.declineReason, otherReason: declined.otherReason });
          });
          this.selectedBenefitPlans.forEach(plan => {
            if (plan.benefitPlan.benefitPlanTypeID === this.currentPlanType.id) {
              if (!this.currentSelectedPlan) {
                this.selectPlan(plan);
              }
            }
          });
          this.initilializedPlans = true;
        }
      }),
      this.store.select(s => s.benefitsState.benefitsDependentsList).subscribe(benefitsDependentsList => {
        if (benefitsDependentsList) {
          this.benefitsDependents = benefitsDependentsList.filter(x => !x.isDeleted);
          this.initCheckedDependents();
        }
      }),

      this.store.select(s => s.benefitsState.benefitPlanTypesList).subscribe(benefitPlanTypesList => {
        if (benefitPlanTypesList) {
          this.allBenefitPlanTypes = benefitPlanTypesList;
          if (this.allBenefitPlanTypes.length > 0) {
            this.initAvailableBenefitTypes();
            this.initAvailableBenefitPlansOfCurrentType();
            this.initCheckedDependents();
          }
        }
      }),

      this.store.select(s => s.benefitsState.availableBenefitPlans).subscribe(availableBenefitPlans => {
        if (availableBenefitPlans) {
          this.availableBenefitPlans = deepClone(availableBenefitPlans);
          this.availableBenefitPlans.forEach(x => {
            if (!x.enrollment) {
              x.enrollment = {} as BenefitPlanEnrollmentModel;
            }
          });
          this.initAvailableBenefitTypes();
          this.initAvailableBenefitPlansOfCurrentType();
          this.initCheckedDependents();
        }
      }),

      this.store.select(s => s.benefitsState.declineReasonsOptions).subscribe(declineReasonsOption => {
        if (declineReasonsOption) {
          this.declineReasons = declineReasonsOption;
        }
      }),

      this.store.select(s => s.benefitsState.savingBenefitEnrollment).subscribe(savingBenefitEnrollment => {
        this.savingBenefitEnrollment = savingBenefitEnrollment;
      }),

      this.store.select(s => s.benefitsState.employerContribution).subscribe(employerContribution => {
        if (employerContribution) {
          this.employerContribution = employerContribution;
        }
      }),

      this.store.select(s => s.benefitsState.benefitsSettings).subscribe(benefitsSettings => {
        if (benefitsSettings) {
          this.benefitsSettings = benefitsSettings;
        }
      }),

      this.store.select(s => s.benefitsState.docsListBenefit).subscribe(enrollmentFormDocs => {
        if (enrollmentFormDocs) {
          this.hasAnyDoc = enrollmentFormDocs.length > 0;
          this.notSignedDocs = enrollmentFormDocs.filter(doc => !doc.signedDate).length;
        }
      }),

      this.store.select(s => s.profileState.profile).subscribe(profile => {
        this.profile = profile;
      }),

      this.store.select(s => s.benefitsState.benefitsLifeChangingEvents).subscribe(benefitsLifeChangingEvents => {
        if (benefitsLifeChangingEvents) {
          if (benefitsLifeChangingEvents.find(x => x.eventLabel === 'New Hire')) {
            this.lifeEventOptions =
              benefitsLifeChangingEvents.filter(x => x.eventLabel === 'New Hire').map(x => ({ label: x.eventLabel, value: x.id }));
          } else {
            this.lifeEventOptions = benefitsLifeChangingEvents.map(x => ({ label: x.eventLabel, value: x.id }));
          }
        }
      }),

      this.actions$.pipe(
        ofType(saveBenefitsEnrollmentSuccess),
        tap(action => {
          //this.alertService.success('Enrollment saved!');
          if (!this.openFinishScreen) {
            this.openFinishScreen = true;
            window.scrollTo(0, 0);
          }
          this.editClicked = false;
          this.store.dispatch(loadProfile());
          this.store.dispatch(getBenefitsFormDocumentsList({ userId: null }));
          if (this.generatingDocs) {
            this.router.navigate(['/benefits-enrollment/docs/list'], { replaceUrl: true });
          }
        })
      ).subscribe(),


      this.actions$.pipe(
        ofType(loadBenefitPlanEnrollmentSetFail, loadBenefitPlanEnrollmentSetSuccess),
        tap(action => {
          if (!this.selectedBenefitPlans.some(x => x.benefitPlan.benefitPlanTypeLabel === 'Employee Perk')) {
            const perkPlans = this.availableBenefitPlans.filter(x => x.benefitPlan.benefitPlanTypeLabel === 'Employee Perk');
            for (const plan of perkPlans) {
              this.selectedBenefitPlans.push(plan);
            }
          }
          this.initilializedPlans = true;
        })
      ).subscribe(),


      this.actions$.pipe(
        ofType(startChangingEnrollmentSuccess, cancelChangingEnrollmentSuccess),
        tap(action => {
          if (!this.openFinishScreen) {
            this.openFinishScreen = true;
            window.scrollTo(0, 0);
          }
          this.editClicked = false;
          this.store.dispatch(loadProfile());
          this.benefitPlanEnrollmentSet = null;
          this.benefitPlanEnrollmentSetID = null;
          this.benefitPlanEnrollmentSetCreatedDate = null;
          this.store.dispatch(loadBenefitPlanEnrollmentSet());
        })
      ).subscribe(),

    );

    this.store.dispatch(loadBenefitsDependents());
    this.store.dispatch(loadBenfitPlanTypes());
    this.store.dispatch(loadAvailableBenefitPlans());
    this.store.dispatch(loadBenefitsDeclineReasons());
    this.store.dispatch(loadEmployerContribution());
    this.store.dispatch(loadBenefitsSettings());
    this.store.dispatch(loadBenefitsLifeChangingEvents());
  }

  totalUsedEmployerContribution() {
    let total = 0;
    this.selectedBenefitPlans.forEach(x => {
      if (x.enrollment && x.enrollment.employerContribution) {
        total += x.enrollment.employerContribution;
      }
    });
    return total;
  }

  initAvailableBenefitTypes() {
    if (this.availableBenefitPlans && this.availableBenefitPlans.length > 0
      && this.allBenefitPlanTypes && this.allBenefitPlanTypes.length > 0) {
      this.benefitPlanTypes = this.allBenefitPlanTypes
        .filter(type => this.availableBenefitPlans.find(plan => plan.benefitPlan.benefitPlanTypeID === type.id));
      this.currentPlanIndex = 0;
      this.currentPlanType = this.benefitPlanTypes[this.currentPlanIndex];
    }
  }

  initAvailableBenefitPlansOfCurrentType() {
    if (this.availableBenefitPlans && this.currentPlanType) {
      this.availableBenefitPlansOfCurrentType =
        this.availableBenefitPlans.filter(x => {
          if (x.benefitPlan.benefitPlanTypeID === this.currentPlanType.id) {
            if (x.benefitPlan.dependOnPlanID) {
              if (!this.selectedBenefitPlans.find(y => y.benefitPlan.id === x.benefitPlan.dependOnPlanID)) {
                return false;
              }
            }
            return true;
          }
          return false;
        }
        );
    }
  }

  initCheckedDependents() {
    if (this.benefitsDependents && this.currentPlanType && this.allBenefitPlanTypes && this.allBenefitPlanTypes.length > 0 && !this.checkedDependent) {
      this.checkedDependent = {};
      this.allBenefitPlanTypes.forEach(type => {
        this.checkedDependent[type.id] = {};
        this.benefitsDependents.forEach(x => {
          this.checkedDependent[type.id][x.id] = false;
        });
      });

      this.initilializedDependets = true;
      this.store.dispatch(loadBenefitPlanEnrollmentSet());
    }
  }

  loadedDependents() {
    return this.initilializedDependets && this.benefitsDependents && this.checkedDependent;
  }

  getPlanTypeClass() {
    const baseClass = 'display-3 ';
    if (this.currentPlanType && this.currentPlanType.planTypeName === 'Medical') {
      return baseClass + 'fas fa-clinic-medical redicon';
    }
    if (this.currentPlanType && this.currentPlanType.planTypeName === 'Cash Rewards') {
      return baseClass + 'fas fa-badge-dollar greenicon';
    }
    if (this.currentPlanType && this.currentPlanType.planTypeName === 'Universal') {
      return baseClass + 'far fa-handshake';
    }
    if (this.currentPlanType && this.currentPlanType.planTypeName === 'Select Health (Prescription)') {
      return baseClass + 'fas fa-prescription-bottle-alt';
    }
    if (this.currentPlanType && this.currentPlanType.planTypeName === 'Dental') {
      return baseClass + 'far fa-tooth oceanblue';
    }
    if (this.currentPlanType && this.currentPlanType.planTypeName === 'Vision') {
      return baseClass + 'fas fa-glasses purple';
    }
    if (this.currentPlanType && this.currentPlanType.planTypeName === 'Accident') {
      return baseClass + 'far fa-hospital orange';
    }
    if (this.currentPlanType && this.currentPlanType.planTypeName === 'Supplemental Medical') {
      return baseClass + 'far fa-heartbeat';
    }
    if (this.currentPlanType && this.currentPlanType.planTypeName === 'Health Savings Account') {
      return baseClass + 'fas fa-badge-dollar greenicon';
    }
    if (this.currentPlanType && this.currentPlanType.planTypeName === 'Employee Perk') {
      return baseClass + 'fad fa-users-crown';
    }
  }

  getCost(benefitPlan: BenefitPlanFullModel) {
    if (!benefitPlan.costs) {
      return 0;
    }
    if (benefitPlan.benefitPlan.benefitPlanTypeLabel === 'Health Savings Account') {
      return (benefitPlan.enrollment.employeePerPayElection ? benefitPlan.enrollment.employeePerPayElection : 0)
        + (benefitPlan.enrollment.employerContribution ? benefitPlan.enrollment.employerContribution : 0);
    }
    let CostCoverageId: number = this.getCostCoverageId(benefitPlan);
    let cost = benefitPlan.costs.find(x => x.coverageLevelID === CostCoverageId);
    while (!cost && CostCoverageId > 0) {
      CostCoverageId--;
      cost = benefitPlan.costs.find(x => x.coverageLevelID === CostCoverageId);
    }
    if (!cost) {
      return 0;
    }
    return benefitPlan.costs.find(x => x.coverageLevelID === CostCoverageId).costPerPayPeriod * 12 / 26;
  }

  getCostCoverageId(benefitPlan: BenefitPlanFullModel) {
    let hasSpouse = false;
    let hasChild = false;
    let dependentCount = 0;
    // tslint:disable-next-line:forin
    for (const i in this.checkedDependent[benefitPlan.benefitPlan.benefitPlanTypeID]) {
      if (this.checkedDependent[benefitPlan.benefitPlan.benefitPlanTypeID][i]) {
        dependentCount++;
        const dependent = this.benefitsDependents.find(x => (x.id + '') === i);
        if (dependent.relationship === 'Spouse') {
          hasSpouse = true;
        }
        if (dependent.relationship === 'Child' ||
          dependent.relationship === 'Legal Guardian') {
          hasChild = true;
        }
      }
    }

    let coverageLevelID;
    if (benefitPlan.costs.find(x => x.coverageLevelID === 5) && dependentCount === 1 && hasChild) {
      coverageLevelID = 5;
    } else if (hasSpouse && hasChild) {
      coverageLevelID = 4;
    } else if (hasChild) {
      coverageLevelID = 3;
    } else if (hasSpouse) {
      coverageLevelID = 2;
    } else {
      coverageLevelID = 1;
    }


    //Check if the coverage level has any cost, if not, get the family
    if (benefitPlan.costs && benefitPlan.costs.length) {
      // use employee+famaily if not found
      if (!benefitPlan.costs.find(x => x.coverageLevelID === coverageLevelID)
        && benefitPlan.costs.find(x => x.coverageLevelID === 4)) {
        return 4;
      }
    }
    return coverageLevelID;

  }
  getCostCoverageLabel(benefitPlan: BenefitPlanFullModel) {
    const CostCoverageId = this.getCostCoverageId(benefitPlan);

    if (CostCoverageId === 5) {
      return 'Employee + Child';
    } else if (CostCoverageId === 4) {
      return 'Employee + Family';
    } else if (CostCoverageId === 3) {
      return 'Employee + Children';
    } else if (CostCoverageId === 2) {
      return 'Employee + Spouse';
    } else {
      return 'Employee';
    }
  }

  getEffectiveDate(benefitPlan: BenefitPlanFullModel) {

    if (new Date() > new Date(benefitPlan.benefitPlan.dateStart)) {
      if (this.benefitPlanEnrollmentSet?.lifeChangingEventID == 8 && this.benefitPlanEnrollmentSet?.lifeChangingEventDate) {
        // New Baby Born
        return new Date(this.benefitPlanEnrollmentSet.lifeChangingEventDate);
      }
      else if (benefitPlan.benefitPlan.ellegibilityBeginLabel == '1st of the month after') {
        return new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1);
      } else {
        return new Date();
      }
    } else {
      return new Date(benefitPlan.benefitPlan.dateStart);
    }

  }

  getEndDate(benefitPlan: BenefitPlanFullModel) {
    return new Date(benefitPlan.benefitPlan.dateEnd);
  }

  getPlanTypeName(id: number) {
    return this.benefitPlanTypes.find(x => x.id === id).planTypeName;
  }

  selectPlan(benefitPlan: BenefitPlanFullModel) {
    if (this.currentSelectedPlan === benefitPlan) {
      const filteredPlans = this.selectedBenefitPlans
        .filter(x => x.benefitPlan.benefitPlanTypeID !== benefitPlan.benefitPlan.benefitPlanTypeID);
      this.selectedBenefitPlans = filteredPlans;
      this.currentSelectedPlan = null;

    } else {
      this.currentSelectedPlan = benefitPlan;

      const filteredPlans = this.selectedBenefitPlans
        .filter(x => x.benefitPlan.benefitPlanTypeID !== benefitPlan.benefitPlan.benefitPlanTypeID);
      filteredPlans.push(benefitPlan);
      this.selectedBenefitPlans = filteredPlans;

      if (this.currentSelectedPlan.benefitPlan.specialPercentageContribution) {
        this.selectedBenefitPlans.forEach(x => {
          if (x.enrollment && x.enrollment.employerContribution) {
            x.enrollment.employerContribution = 0;
          }
        });
      }

      this.rejectedBenefitPlanTypes = this.rejectedBenefitPlanTypes.filter(x => x.type.id !== this.currentPlanType.id);
    }

    //check if there is any selected plan that should not be available anymore
    let shouldRemove = [];
    this.selectedBenefitPlans.forEach(selectedPlan => {
      if (selectedPlan.benefitPlan.dependOnPlanID) {
        if (!this.selectedBenefitPlans.find(y => y.benefitPlan.id === selectedPlan.benefitPlan.dependOnPlanID)) {
          shouldRemove.push(selectedPlan);
        }
      }
    });
    this.selectedBenefitPlans = this.selectedBenefitPlans.filter(x => !shouldRemove.find(y => x.benefitPlan.id === y.benefitPlan.id));
  }

  isPlanSelected(benefitPlan: BenefitPlanFullModel) {
    return !!this.selectedBenefitPlans.find(x => x.benefitPlan.id === benefitPlan.benefitPlan.id);
  }

  openPlanDetail(benefitPlan: BenefitPlanFullModel) {
    this.openedPlanDetail = true;
    this.planDetailToOpen = benefitPlan;
  }

  saveAndContinue() {
    if (this.currentSelectedPlan) {
      if (this.getRemainingEmployerContribution() < -0.00999999999) {
        this.alertService.error('You cannot use more employer contribution than available');
        return;
      }
      if (!this.currentRejected() && this.getMyCost(this.currentSelectedPlan) < -0.00999999999) {
        this.alertService.error('You cannot use more employer contribution than the cost of the plan');
        return;
      }

      if (!this.currentRejected() && this.currentSelectedPlan.benefitPlan.disclosure) {
        this.confirmationService.confirm({
          header: 'Disclosure',
          message: this.currentSelectedPlan.benefitPlan.disclosure,
          acceptLabel: 'Agree',
          rejectLabel: 'Cancel',
          accept: () => {
            this.saveAndSign(false);
          }
        });
      } else {
        this.saveAndSign(false);
      }
    } else {
      if (this.currentRejected()) {
        this.saveAndSign(false);
      } else {
        this.alertService.error('Please select a plan or decline coverage.');
      }
    }
  }

  goToDependents() {
    this.goToFirstPlanType();
    this.openPlansScreen = false;
  }

  goToFirstPlanType() {
    this.currentPlanIndex = -1;
    this.goToNextPlanType(true);
  }

  goToPlanType(id: number) {
    const nextPlanTypeID = this.benefitPlanTypes.findIndex(x => x.id === id);
    this.currentPlanIndex = nextPlanTypeID - 1;
    this.goToNextPlanType();
  }

  goToNextPlanType(back: boolean = false) {
    this.currentPlanIndex++;
    if (this.currentPlanIndex >= this.benefitPlanTypes.length) {
      // finished plan types, go to last page
      this.openFinishScreen = true;
    } else {
      this.openFinishScreen = false;
      this.currentPlanType = this.benefitPlanTypes[this.currentPlanIndex];
      this.initAvailableBenefitPlansOfCurrentType();

      if (this.availableBenefitPlansOfCurrentType.length === 0) {
        if (back) {
          this.currentPlanIndex--;
          this.currentPlanIndex--;
        }
        this.goToNextPlanType(back);
        return;
      }

      this.currentSelectedPlan = null;
      if (this.currentPlanType.planTypeName === 'Universal' && this.availableBenefitPlansOfCurrentType.length) {
        this.selectPlan(this.availableBenefitPlansOfCurrentType[0]);
      }

      if (this.currentPlanType.planTypeName === 'Employee Perk' && this.availableBenefitPlansOfCurrentType.length) {
        const filteredPlans = this.selectedBenefitPlans
          .filter(x => x.benefitPlan.benefitPlanTypeID !== this.currentPlanType.id);
        this.availableBenefitPlansOfCurrentType.forEach(plan => {
          this.currentSelectedPlan = plan;
          filteredPlans.push(plan);
        });
        this.selectedBenefitPlans = filteredPlans;
      }
      this.selectedBenefitPlans.forEach(plan => {
        if (plan.benefitPlan.benefitPlanTypeID === this.currentPlanType.id) {
          this.currentSelectedPlan = plan;
        }
      });
    }
    window.scrollTo(0, 0);
  }

  back() {
    this.openFinishScreen = true;
    window.scrollTo(0, 0);
  }


  shouldShowPlanList() {
    return (this.currentPlanType
      && this.currentPlanType.planTypeName !== 'Universal'
      && this.currentPlanType.planTypeName !== 'Employee Perk');
  }

  hasSelectedPlanWithSpecialPercentage() {
    return !!this.selectedBenefitPlans.find(x => !!x.benefitPlan.specialPercentageContribution);
  }

  getSpecialPercentageContributionTotal(plan: BenefitPlanFullModel) {
    const costEmployee = plan.costs.find(x => x.coverageLevelID === 1).costPerPayPeriod * 12 / 26; // 'Employee'

    return costEmployee * plan.benefitPlan.specialPercentageContribution / 100;
  }


  shouldShowCoverageList() {
    return (this.currentPlanType && this.currentPlanType.planTypeName !== 'Health Savings Account'
      && this.currentPlanType.planTypeName !== 'Employee Perk');
  }

  getPayperiodElegibleYearContribuion(value) {
    return (Math.floor((value / 26) * 100) / 100).toFixed(2);
  }

  changedEmployeePerPayElection() {
    const dateDiff = this.getEndDate(this.currentSelectedPlan).valueOf() - this.getEffectiveDate(this.currentSelectedPlan).valueOf();
    const dateDiffDays = (dateDiff / 1000 / 60 / 60 / 24);
    const dateDiffPayPeriod = Math.round(dateDiffDays / 14);
    this.projectedDeferral = (Number(this.currentSelectedPlan.enrollment.employeePerPayElection ?
      this.currentSelectedPlan.enrollment.employeePerPayElection : 0) +
      Number(this.currentSelectedPlan.enrollment.employerContribution ?
        this.currentSelectedPlan.enrollment.employerContribution : 0)) * dateDiffPayPeriod;
  }

  getDifferentPlanTypes() {
    const distinctBenefitPlanType = (value, index, self) => {
      return self.findIndex(x => x.benefitPlan.benefitPlanTypeID === value.benefitPlan.benefitPlanTypeID) === index;
    };
    return this.selectedBenefitPlans.filter(distinctBenefitPlanType).length;
  }

  shouldDisableSave() {
    if (!this.currentSelectedPlan) {
      return true;
    }

    if (this.currentPlanType.planTypeName === 'Health Savings Account'
      && this.projectedDeferral > this.getElegibleYearlyContribution(this.currentSelectedPlan)) {
      return true;
    }

    if (this.savingBenefitEnrollment) {
      return true;
    }
    return false;
  }

  declinePlanType(typeId: number) {
    this.showDeclineReason = true;
    this.decliningTypeId = typeId;
    this.currentPlanType = this.allBenefitPlanTypes.find(x => x.id === this.decliningTypeId);
  }

  rejectCurrentPlanType() {
    this.showDeclineReason = true;
  }
  cancelRejectCurrentPlan() {
    this.showDeclineReason = false;
    this.decliningTypeId = null;
  }
  confirmRejectCurrentPlan() {
    const typeId = this.decliningTypeId ? this.decliningTypeId : this.currentPlanType.id;
    const planType = this.decliningTypeId ? this.allBenefitPlanTypes.find(x => x.id === this.decliningTypeId) : this.currentPlanType;
    this.showDeclineReason = false;
    // Erase dependents
    this.benefitsDependents.forEach(x => {
      this.checkedDependent[typeId][x.id] = false;
    });
    this.selectedBenefitPlans = this.selectedBenefitPlans.filter(x => x.benefitPlan.benefitPlanTypeID !== typeId);
    if (!this.rejectedBenefitPlanTypes.find(x => x.type.id === typeId)) {
      this.rejectedBenefitPlanTypes.push({ type: planType, reason: this.selectedReason, otherReason: this.otherReason });
    } else {
      this.rejectedBenefitPlanTypes.find(x => x.type.id === typeId).reason = this.selectedReason;
      this.rejectedBenefitPlanTypes.find(x => x.type.id === typeId).otherReason = this.otherReason;
    }

    //check if there is any selected plan that should not be available anymore
    let shouldRemove = [];
    this.selectedBenefitPlans.forEach(selectedPlan => {
      if (selectedPlan.benefitPlan.dependOnPlanID) {
        if (!this.selectedBenefitPlans.find(y => y.benefitPlan.id === selectedPlan.benefitPlan.dependOnPlanID)) {
          shouldRemove.push(selectedPlan);
        }
      }
    });
    this.selectedBenefitPlans = this.selectedBenefitPlans.filter(x => !shouldRemove.find(y => x.benefitPlan.id === y.benefitPlan.id));


    this.selectedReason = null;
    this.otherReason = null;
    this.decliningTypeId = null;
    this.saveAndContinue();
  }

  currentRejected() {
    return this.rejectedBenefitPlanTypes.find(x => x.type.id === this.currentPlanType.id);
  }

  openPlans() {
    this.openPlansScreen = true;

  }

  saveAndSign(generateDocs = true) {
    this.generatingDocs = generateDocs;
    if (!this.savingBenefitEnrollment) {
      this.savingBenefitEnrollment = true;
      const enrollmentSet: BenefitPlanEnrollmentSetModel = {
        id: this.benefitPlanEnrollmentSetID,
        enrollments: [],
        declineds: [],
        generateDocs,
        plansYear: null
      };

      this.selectedBenefitPlans.forEach(plan => {
        const employerContribution = plan.enrollment ? plan.enrollment.employerContribution : 0;
        const enrollment: BenefitPlanEnrollmentModel = {
          id: plan.enrollment ? plan.enrollment.id : null,
          dateStart: getDateString(this.getEffectiveDate(plan)),
          dateEnd: formatDateString(plan.benefitPlan.dateEnd),
          benefitPlanID: plan.benefitPlan.id,
          costPerPayPeriod: this.getMyCost(plan),
          coverageLevelID: this.getCostCoverageId(plan),
          employeePerPayElection: plan.benefitPlan.benefitPlanTypeLabel === 'Health Savings Account' ? (plan.enrollment.employeePerPayElection) : null,
          employerContribution: employerContribution,
          dependents: [],
        };

        // tslint:disable-next-line:forin
        for (const dependentID in this.checkedDependent[plan.benefitPlan.benefitPlanTypeID]) {
          if (this.checkedDependent[plan.benefitPlan.benefitPlanTypeID][dependentID]) {
            const dependent = this.benefitsDependents.find(x => x.id === Number(dependentID));
            enrollment.dependents.push(dependent);
          }
        }
        enrollmentSet.enrollments.push(enrollment);
      });
      this.rejectedBenefitPlanTypes.forEach(planType => {
        const declined: BenefitPlanDeclinedModel = {
          dateStart: new Date().getMonth() === 11 ? getDateString(this.startNextYearDate) : getDateString(new Date()),
          dateEnd: new Date().getMonth() === 11 ? getDateString(this.endNextYearDate) : getDateString(this.endYearDate),
          benefitPlanTypeID: planType.type.id,
          declineReasonID: planType.reason.id,
          otherReason: planType.otherReason
        };
        enrollmentSet.declineds.push(declined);
      });


      this.store.dispatch(saveBenefitsEnrollment({ enrollmentSet }));
    }
  }


  getMyCost(plan: BenefitPlanFullModel) {
    if (plan && plan.enrollment && plan.enrollment.employerContribution) {
      return Number(this.getCost(plan) - plan.enrollment.employerContribution);
    }
    if (plan && plan.benefitPlan.specialPercentageContribution) {
      return Number(this.getCost(plan) - this.getSpecialPercentageContributionTotal(plan));
    }
    return this.getCost(plan);
  }

  getRemainingEmployerContribution() {
    return (this.hasSelectedPlanWithSpecialPercentage() ? 0 : this.employerContribution.employerContribution) - this.totalUsedEmployerContribution();
  }

  hasNotEnrollOrDeclinedType() {
    if (this.initilializedDependets && this.initilializedPlans) {
      let benefitPlanTypes = this.benefitPlanTypes
        .filter(type =>
          this.availableBenefitPlans
            .filter(x => !x.benefitPlan.dependOnPlanID || this.selectedBenefitPlans.find(y => y.benefitPlan.id === x.benefitPlan.dependOnPlanID))
            .find(plan => plan.benefitPlan.benefitPlanTypeID === type.id));
      return benefitPlanTypes.some(x => !this.selectedBenefitPlans.some(y => y.benefitPlan.benefitPlanTypeID == x.id) && !this.rejectedBenefitPlanTypes.some(y => y.type.id == x.id));
    }
  }

  setEditClicked() {
    this.editClicked = true;
  }

  downloadHelpGuide() {
    this.loadingHelpGuide = true;
    this.store.dispatch(loadHelpGuide());
    const subDocBuffer = this.store.select(s => s.benefitsState.docArrayBufferBenefitHelpGuide).subscribe(docArrayBuffer => {
      if (docArrayBuffer) {
        downloadFile(new Blob([docArrayBuffer], { type: 'application/pdf' }), 'OE Slides 2022.pdf');
        this.loadingHelpGuide = false;
        subDocBuffer.unsubscribe();
      }
    });
  }

  getIrsContributionLimit() {
    let total = this.benefitsSettings.irsContributionLimit;
    const medicalPlan = this.selectedBenefitPlans.find(x => x.benefitPlan.benefitPlanTypeLabel === 'Medical');
    if (!medicalPlan) return 0;
    const coverage = this.getCostCoverageId(medicalPlan);
    if (coverage > 1) { //Any above employee
      total = total * 2;
    }
    if (getAge(this.profile.employee.birthdate) >= 55) {
      total += 1000;
    }
    return total;
  }

  getElegibleYearlyContribution(plan: BenefitPlanFullModel) {
    let total = plan.benefitPlan.elegibleYearlyContribution;
    const medicalPlan = this.selectedBenefitPlans.find(x => x.benefitPlan.benefitPlanTypeLabel === 'Medical');
    if (!medicalPlan) return 0;
    const coverage = this.getCostCoverageId(medicalPlan);
    if (coverage > 1) { //Any above employee
      total = total * 2;
    }
    if (getAge(this.profile.employee.birthdate) >= 55) {
      total += 1000;
    }
    return total;
  }

  isDuringOpenEnrollment() {
    const now = new Date();
    return this.benefitsSettings
      && now < new Date(this.benefitsSettings.openEnrollmentEnd)
      && now > new Date(this.benefitsSettings.openEnrollmentStart);
  }

  yearOfOpenEnrollment() {
    const now = new Date();
    return this.benefitsSettings ? new Date(this.benefitsSettings.openEnrollmentStart).getFullYear() : now.getFullYear();
  }


  clickChangeEnrollment() {
    this.openChangeEnrollment = true;
  }

  startChangingEnrollment() {
    if (this.getSelectedLifeEventLabel() === 'Other' && !this.lifeEventOther) {
      this.alertService.error('Please fill in the other life changing event field.');
      return;
    }
    if (this.getSelectedLifeEventLabel() !== 'New Hire' && !this.lifeEventDate) {
      this.alertService.error('Please fill in date the life changing event occured.');
      return;
    }
    this.openChangeEnrollment = false;
    this.loadingBenefitPlanEnrollmentSet = true;
    this.store.dispatch(startChangingEnrollment({
      lifeChangingEventID: this.lifeEventSelected,
      lifeChangingEventOther: this.getSelectedLifeEventLabel() === 'Other' ? this.lifeEventOther : '',
      lifeChangingEventDate: this.getSelectedLifeEventLabel() !== 'New Hire' ? this.lifeEventDate : null,
    }));
  }

  startOpenEnrollment() {
    this.loadingBenefitPlanEnrollmentSet = true;
    this.store.dispatch(startChangingEnrollment({
    }));
  }

  clickCancelEnrollment() {
    this.loadingBenefitPlanEnrollmentSet = true;
    this.store.dispatch(cancelChangingEnrollment());
  }
  getLifeEventLabel(benefitPlanEnrollmentSet: BenefitPlanEnrollmentSetModel) {
    return this.lifeEventOptions.find(x => x.value === benefitPlanEnrollmentSet.lifeChangingEventID) ?
      this.lifeEventOptions.find(x => x.value === benefitPlanEnrollmentSet.lifeChangingEventID).label : '';
  }

  getSelectedLifeEventLabel() {
    return this.lifeEventOptions.find(x => x.value === this.lifeEventSelected) ?
      this.lifeEventOptions.find(x => x.value === this.lifeEventSelected).label : '';
  }
}


