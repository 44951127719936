
import { downloadFile } from 'src/app/helpers/utils';
import { loadJobTitles, loadProfile } from 'src/app/core/store/profile/profile.actions';
import {
  saveFBFormSuccess, getFormTypesList, getFormFieldTypesList, updateFBFormField,
  removeFBFormField, updateFBFormForm, getMappingColumnsList, updateFBFormFieldGroup, removeFBFormFieldGroup, addFBFormLanguage, removeFBFormLanguage
} from './../../../../core/store/form-builder/form-builder.actions';
import { FBFormFieldGroupModel, FBFormFieldModel, FBFormModel, FBFieldType, FBFormLanguageModel, FBFormFieldLanguageModel, FBFormFieldGroupLanguageModel } from './../../../../models/formBuilderModels';
import { filter, tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { EmailValidator, FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import {
  markFormGroupTouched, getAllControlsErrors, updateFormGroupValidity, deepClone,
  formatDateString, base64ToArrayBuffer, arrayBufferToBase64
} from './../../../../../app/helpers/utils';
import { BaseComponent } from './../../../../core/abstracts/baseComponent';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { AlertService } from 'src/app/shared/services/alert.service';
import { clearForm, getFBForm, saveFBForm } from 'src/app/core/store/form-builder/form-builder.actions';
import { initFormBuilderForm } from 'src/app/core/store/form-builder/form-builder.reducer';
import { OverlayPanel } from 'primeng/overlaypanel';
import * as Editor from 'src/assets/ckeditor/build/ckeditor.js';
import PageBreak from 'src/assets/ckeditor/build/ckeditor.js';
import Mention from 'src/assets/ckeditor/build/ckeditor.js';
import Base64UploadAdapter from 'src/assets/ckeditor/build/ckeditor.js';
import { FormBuilderHelpDescription } from './../../../../models/formBuilderHelpDescriptions';



import { environment } from 'src/environments/environment';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { loadInsuranceProviders } from '../../../../core/store/medclinic/medclinic.actions';

import { html_beautify } from 'js-beautify';
import { ModifiedSelectItem } from '../../../../models/utilModels';

@Component({
  selector: 'app-form-addit',
  templateUrl: './form-addit.component.html',
  styleUrls: ['./form-addit.component.scss']
})
export class FormsAddItComponent extends BaseComponent implements OnInit {

  fbForm: FBFormModel;
  form: FormGroup;
  formID: number;
  fileToUpload: File = null;
  formFieldTypeOptions = [];
  mappingColumnsAvailable = [];
  mappingColumnsFiltered = [];
  formTypeOptions = [];
  formFieldWidthRatioOptions: SelectItem[] = [
    { label: '100%', value: 1 },
    { label: '50%', value: 2 },
    { label: '33%', value: 3 },
    { label: '25%', value: 4 },
    { label: '20%', value: 5 },
    { label: '16.6%', value: 6 },
    { label: '8.3%', value: 12 },
  ];
  savingForm = false;
  loadingForm = false;

  fieldOpened: FBFormFieldModel;
  fieldForm: FormGroup;

  yesNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  clientOrEmployeeOptions = [
    { label: 'Client Form', value: 'Client' },
    { label: 'Employee Form', value: 'Employee' },
  ];
  showSignatureOptions = [
    { label: 'Requires Signature', value: true },
    { label: 'Signature Not Needed', value: false },
  ];
  createOrPDFOptions = [
    { label: 'Create document', value: 'html' },
    { label: 'Use PDF file', value: 'pdf' },
  ];



  formLanguageOptions = [
    { label: 'Spanish', value: { id: 1, languageID: 1, languageCode: 'es', languageName: 'Spanish' } },
  ];
  selectedFormLanguage: FBFormLanguageModel;
  addLanguageOpened = false;
  addingLanguage = null;
  fieldLanguageOpened: FBFormFieldLanguageModel;

  public initialEditor = Editor;
  currentEditor;
  editorConfig;

  public initialSummaryEditor = Editor;
  currentSummaryEditor;
  editorSummaryConfig;

  openAdvancedSettings;
  fieldsToSelect;
  fieldsToSelectPDF = [
    { label: 'Signature', value: '#Signature#' },
    { label: 'DateSignature', value: '#DateSignature#' },
    { label: 'Initials', value: '#Initials#' },
    { label: 'SignatureOptional', value: '#SignatureOptional#' },
    { label: 'InitialsOptional', value: '#InitialsOptional#' }
  ];
  numberFieldsOptions;

  fieldToAddPDF;
  advancedOpened = false;

  hmtlOrPDF = 'html';
  currentPDFFile: ArrayBuffer;
  changeFile = false;


  currentFormTypeID: number;
  editorDescription: string;
  showInstructions: string = "Hide";


  jobTitleOptions: ModifiedSelectItem[] = [
    { label: '-', value: null },
  ];
  allJobTitleOptions: ModifiedSelectItem[] = [
    { label: '-', value: null },
  ];

  insurancePayorsOptions: SelectItem[] = [
    { label: '-', value: null },
  ];

  fieldGroupsOptions: SelectItem[] = [];
  sectionsOptions: SelectItem[] = [];

  seeAsHTML = false;
  seeSummaryAsHTML = false;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router) {
    super();
  }

  ngOnInit() {

    this.editorConfig = {
      plugin: { PageBreak, Mention, Base64UploadAdapter },
      mention: {
        feeds: [
          {
            marker: '#',
            feed: (queryText) => this.getFeedItems(queryText),
            minimumCharacters: 1
          },
        ]
      },
      image: {
        upload: {
          types: ['jpeg', 'png', 'bmp', 'webp', 'tiff']
        }
      }
    };
    this.editorSummaryConfig = {
      plugin: { PageBreak, Mention, Base64UploadAdapter },
      mention: {
        feeds: [
          {
            marker: '#',
            feed: (queryText) => this.getFeedItems(queryText),
            minimumCharacters: 1
          },
        ]
      },
      image: {
        upload: {
          types: ['jpeg', 'png', 'bmp', 'webp', 'tiff']
        }
      }
    };
    this.editorDescription = FormBuilderHelpDescription.create(0);
    this.route.params.subscribe(params => {
      this.store.dispatch(clearForm());
      if (params.formID) {
        this.formID = params.formID;
        this.store.dispatch(getFBForm({ formID: params.formID }));
      } else {
        this.fbForm = { formFields: [] } as FBFormModel;
        this.initForm();
      }
    });
    this.subs.push(
      this.store.select(s => s.formBuilderState.form).subscribe(fbForm => {
        if (fbForm) {
          this.fbForm = deepClone(fbForm);
          if (this.selectedFormLanguage) {
            this.selectedFormLanguage = this.fbForm.formLanguages.find(x => x.languageID === this.selectedFormLanguage.languageID);
          }
          if (this.fbForm.formFieldGroups) {
            this.fbForm.formFieldGroups.sort((a, b) => a.order > b.order ? 1 : -1);
            this.fbForm.formFieldGroups.forEach(x => { if (x.id) { x.localID = x.id; } });
            this.fieldGroupsOptions = this.fbForm.formFieldGroups.map(x => ({ label: x.groupName, value: x.localID }));
            this.fieldGroupsOptions.unshift({ label: '-', value: null });
          }
          if (this.fbForm.formFields) {
            this.fbForm.formFields.forEach(x => {
              if (x.calculatedRelatedFieldIDs && !x.calculatedRelatedFieldIDsArray) {
                x.calculatedRelatedFieldIDsArray = x.calculatedRelatedFieldIDs.split(',').map(y => +y);
              }
            });
            this.fbForm.formFields = this.fbForm.formFields.filter(x => !x.inactive);
            this.fbForm.formFields = this.fbForm.formFields.sort((a, b) => a.fieldOrder > b.fieldOrder ? 1 : -1);
            this.currentFormTypeID = this.fbForm.formTypeID;
            this.fieldsToSelect = this.fbForm.formFields.filter(x => x.id).map(x => ({ label: x.fieldName, value: x.id }));
            if (this.fieldsToSelect != null && this.fieldsToSelect.length > 0) {
              var newOptions = this.fieldsToSelect;
              newOptions.unshift({ label: "N/A", value: null })
              this.fieldsToSelect = newOptions;
            }
            this.numberFieldsOptions = this.fbForm.formFields.filter(x => x.id && (x.fieldTypeID == 5 || x.fieldTypeID == 7 || x.fieldTypeID == 14 || x.fieldTypeID == 15)).map(x => ({ label: x.fieldName, value: x.id }));
            if (this.numberFieldsOptions != null && this.numberFieldsOptions.length > 0) {
              var newOptions = this.numberFieldsOptions;
              newOptions.unshift({ label: "N/A", value: null })
              this.numberFieldsOptions = newOptions;
            }
            this.fieldsToSelectPDF = this.fbForm.formFields.map(x => ({ label: x.fieldName, value: '#' + x.fieldName + '#' }));
            this.fieldsToSelectPDF.push({ label: 'Signature', value: '#Signature#' });
            this.fieldsToSelectPDF.push({ label: 'DateSignature', value: '#DateSignature#' });
            this.fieldsToSelectPDF.push({ label: 'Initials', value: '#Initials#' });
            this.fieldsToSelectPDF.push({ label: 'SignatureOptional', value: '#SignatureOptional#' });
            this.fieldsToSelectPDF.push({ label: 'InitialsOptional', value: '#InitialsOptional#' });
            if (this.fbForm.requireHRSignature) {
              this.fieldsToSelectPDF.push({ label: 'HRSignature', value: '#Signature#HRUser#' });
            }

            this.fbForm.formFields.forEach(x => { if (!x.localID && x.id) { x.localID = x.id; } });
            this.sectionsOptions = this.fbForm.formFields
              .filter(x => x.fieldTypeID === FBFieldType.Section).map(x => ({ label: x.fieldName, value: x.localID }));
            this.sectionsOptions.unshift({ label: '-', value: null });
            this.fbForm.formFields.forEach(field => {
              if (field.sectionFormFieldID) {
                field.sectionName = this.sectionsOptions.find(x => x.value === field.sectionFormFieldID)?.label;
              } else {
                field.sectionName = null;
              }
            });
          }
          if (!this.currentPDFFile && this.fbForm.baseDocFile && this.fbForm.baseDocFile.base64Data) {
            const myBuffer = base64ToArrayBuffer(this.fbForm.baseDocFile.base64Data);
            this.currentPDFFile = myBuffer;
            this.hmtlOrPDF = 'pdf';
            this.changedHmtlOrPDF();
          }
          if (!this.form) {
            this.initForm();
          }
        }
      }),
      this.store.select(s => s.formBuilderState.formTypes).subscribe(formTypes => {
        if (formTypes) {
          this.formTypeOptions = formTypes.map(x => ({ label: x.formType, value: x.id }));
        }
      }),
      this.store.select(s => s.formBuilderState.formFieldTypes).subscribe(formFieldTypes => {
        if (formFieldTypes) {
          this.formFieldTypeOptions = formFieldTypes.map(x => ({ label: x.formFieldType, value: x.id }));
        }
      }),
      this.store.select(s => s.formBuilderState.mappingColumnsList).subscribe(mappingColumnsList => {
        if (mappingColumnsList) {
          this.mappingColumnsAvailable = mappingColumnsList;
          if (this.form && this.form.get('formTypeID') && this.form.get('formTypeID').value) {
            this.mappingColumnsFiltered = this.mappingColumnsAvailable.filter(f => f.formTypeIDs.some(s => s == this.form.get('formTypeID').value)).map(x => ({ label: x.friendlyName, value: x.mappingColumnID }));
          }
        }
      }),
      this.store.select(s => s.formBuilderState.savingForm).subscribe(savingForm => {
        this.savingForm = savingForm;
      }),

      this.store.select(s => s.formBuilderState.loadingForm).subscribe(loadingForm => {
        this.loadingForm = loadingForm;
      }),

      this.store.select(s => s.medClinicState.insuranceProviders).subscribe(insurancePayors => {

        const insurancePayorsList = insurancePayors && insurancePayors.length > 0 ? insurancePayors.map(c => ({ label: c.insuranceProvider, value: c.id }))
          : [{ label: 'loading...', value: undefined }];
        this.insurancePayorsOptions = insurancePayorsList.sort((a, b) => {
          const labelA = a.label.toUpperCase(); // ignore upper and lowercase
          const labelB = b.label.toUpperCase(); // ignore upper and lowercase
          if (labelA < labelB) {
            return -1;
          }
          if (labelA > labelB) {
            return 1;
          }
          // names must be equal
          return 0;
        });
      }),
      this.store.select(s => s.profileState.jobTitles).subscribe(jobTitles => {

        this.allJobTitleOptions = jobTitles && jobTitles.length > 0 ? jobTitles.map(c => ({ label: c.jobTitle, value: c.id, archived: c.archived }))
          : [{ label: 'loading...', value: undefined, archived: false }];

        this.getJobTitles();
      }),
      this.actions$.pipe(
        ofType(saveFBFormSuccess),
        tap(action => {
          this.alertService.success('Form saved!');
          this.router.navigate(['/form-builder/forms']);
        })
      ).subscribe()

    );

    this.store.dispatch(loadInsuranceProviders());
    this.store.dispatch(loadJobTitles());
    this.store.dispatch(getFormTypesList());
    this.store.dispatch(getFormFieldTypesList());
    this.store.dispatch(getMappingColumnsList());
  }
  toggleInstructions() {
    if (this.showInstructions == "Hide") {
      this.showInstructions = "Show"
    }
    else {
      this.showInstructions = "Hide"
    }
  }

  onChanges(): void {
    this.form.get('formTypeID').valueChanges.subscribe(val => {
      this.mappingColumnsFiltered = this.mappingColumnsAvailable.filter(f => f.formTypeIDs.some(x=>x == val)).map(x => ({ label: x.friendlyName, value: x.mappingColumnID }));
      this.editorDescription = FormBuilderHelpDescription.create(val);
      this.currentFormTypeID = val;
      if (!this.form.get('clientOrEmployee').value) {
        if (val == 1 || val == 3 || val == 4) {
          this.form.get('clientOrEmployee').setValue("Employee");
        }
        else {
          this.form.get('clientOrEmployee').setValue("Client");
        }
      }
    });
  }

  checkClientOrEmployeeChange() {
    const currentValue = this.form.get('clientOrEmployee').value;
    if (this.form.get('formTypeID').value != null) {
      this.alertService.info('Please make sure that is the correct selection for Employee or Client forms.');
    }
  }

  initForm() {
    this.form = initFormBuilderForm(this.fbForm);
    this.subs.push(
      this.form.valueChanges.subscribe(formValues => {
        const formErrors = getAllControlsErrors(this.form);
        if (this.selectedFormLanguage) {
          this.fbForm.formLanguages.find(x => x.languageID === this.selectedFormLanguage.languageID).name = formValues.languageName;
          formValues.formLanguages = this.fbForm.formLanguages;
        }
        this.store.dispatch(updateFBFormForm({ formValues, formErrors }));
      }));

    if (this.fbForm && this.fbForm.formTypeID > 0) {
      this.mappingColumnsFiltered = this.mappingColumnsAvailable
        .filter(f => f.formTypeIDs.some(s => s == this.fbForm.formTypeID))
        .map(x => ({ label: x.friendlyName, value: x.mappingColumnID }));
    }

    this.fieldForm = new FormGroup({
      fieldName: new FormControl('New Field', [Validators.required]),
      fieldDescription: new FormControl('', []),
      fieldTypeID: new FormControl(1, [Validators.required]),
      fieldWidthRatio: new FormControl(1, [Validators.required]),
      fieldOptions: new FormControl(null, []),
      isRequired: new FormControl(false, [Validators.required]),
      fillableBeforeSending: new FormControl(null, []),
      allowOther: new FormControl(false, [Validators.required]),
      isEncrypted: new FormControl(false, [Validators.required]),
      readOnly: new FormControl(false, [Validators.required]),
      fieldOrder: new FormControl(null, [Validators.required]),
      mappingColumnGet: new FormControl(null, []),
      mappingColumnsSet: new FormControl(null, []),
      requiredIfFieldID: new FormControl(null, []),
      relatedFormFieldID: new FormControl(null, []),
      showIfRelatedAnswerIs: new FormControl(null, []),
      requiredIfFieldAnswerIs: new FormControl(null, []),
      isHRField: new FormControl(null, []),
      minValue: new FormControl(null, []),
      maxValue: new FormControl(null, []),
      minLength: new FormControl(null, []),
      fieldSize: new FormControl(null, []),
      calculatedRelatedFieldIDsArray: new FormControl(null, []),
      hideLabel: new FormControl(false, []),
      tooltip: new FormControl(null, []),
      showAsDropdown: new FormControl(null, []),
      hasGlobalCounter: new FormControl(null, []),
      defaultText: new FormControl(null, []),
      formFieldGroupID: new FormControl(null, []),
      sectionFormFieldID: new FormControl(null, []),
      numbered: new FormControl(null, []),
      optionsTable: new FormControl(null, []),
    });
    this.onChanges();
    this.getJobTitles();
  }

  getFieldTypeLabel(field: FBFormFieldModel) {
    const formFieldTypeOption = this.formFieldTypeOptions.find(x => x.value === field.fieldTypeID);
    if (formFieldTypeOption) {
      return formFieldTypeOption.label;
    }
  }

  formInconsistences() {
    const errros = [];

    // Check if the form has invalid groups
    if (this.fbForm.formFieldGroups && this.fbForm.formFieldGroups.some(x => !x.groupName)) {
      errros.push('Please add a name to all field groups.');
    }

    if (!this.fbForm.formFieldGroups) {
      this.fbForm.formFieldGroups = [];
    }

    const fieldsWithwrongGroups = this.fbForm.formFields
      .filter(field => field.formFieldGroupID && !this.fbForm.formFieldGroups.some(group => group.localID === field.formFieldGroupID));
    if (fieldsWithwrongGroups && fieldsWithwrongGroups.length > 0) {
      errros.push('Some fields have a field group that does not exist anymore ('
        + fieldsWithwrongGroups.map(x => x.fieldName).join(',') + ').');
    }

    const groupsNotUsed = this.fbForm.formFieldGroups
      .filter(group => !this.fbForm.formFields.some(field => field.formFieldGroupID === group.localID));
    if (groupsNotUsed && groupsNotUsed.length > 0) {
      errros.push('Some groups are not being used on any field ('
        + groupsNotUsed.map(x => x.groupName).join(',') + ').');
    }

    const fieldsWithoutGroup = this.fbForm.formFields.filter(field => !field.formFieldGroupID);
    if (this.fbForm.formFieldGroups.length > 0 && fieldsWithoutGroup && fieldsWithoutGroup.length > 0) {
      errros.push('Some fields are not assigned to a group ('
        + fieldsWithoutGroup.map(x => x.fieldName).join(',') + '). If you have any group, all fields must be assigned to a group.');
    }

    // Check if the form has invalid sections
    const fieldsWithMissingSection = this.fbForm.formFields.filter(x => x.sectionFormFieldID &&
      !this.fbForm.formFields.some(y => y.localID === x.sectionFormFieldID));
    if (fieldsWithMissingSection && fieldsWithMissingSection.length > 0) {
      errros.push('Some fields have a section that does not exist anymore ('
        + fieldsWithMissingSection.map(x => x.fieldName).join(',') + ').');
    }

    // Fields of a section before it
    const fieldsBeforeSection = this.fbForm.formFields.filter(x => x.sectionFormFieldID &&
      this.fbForm.formFields.some(y => y.localID === x.sectionFormFieldID && y.fieldOrder > x.fieldOrder))
    if (fieldsBeforeSection && fieldsBeforeSection.length > 0) {
      errros.push('Some fields of a section are placed before the section. ('
        + fieldsBeforeSection.map(x => x.fieldName).join(',') + ').');
    }

    // Section without any fields
    const sectionNotUsed = this.fbForm.formFields
      .filter(section => section.fieldTypeID === FBFieldType.Section &&
        !this.fbForm.formFields.some(field => field.sectionFormFieldID === section.localID));
    if (sectionNotUsed && sectionNotUsed.length > 0) {
      errros.push('Section without any fields ('
        + sectionNotUsed.map(x => x.fieldName).join(',') + ').');
    }

    // Check if the form has invalid fields (related, mapping, )
    // Related that does not exist
    const relatedDontExist = this.fbForm.formFields.filter(x => x.relatedFormFieldID &&
      !this.fbForm.formFields.some(y => y.localID === x.relatedFormFieldID));
    if (relatedDontExist && relatedDontExist.length > 0) {
      errros.push('Some fields with related ones that dont exist anymore ('
        + relatedDontExist.map(x => x.fieldName).join(',') + ').');
    }
    const requiredDontExist = this.fbForm.formFields.filter(x => x.requiredIfFieldID &&
      !this.fbForm.formFields.some(y => y.localID === x.requiredIfFieldID));
    if (requiredDontExist && requiredDontExist.length > 0) {
      errros.push('Some fields with "requerid if" that dont exist anymore ('
        + requiredDontExist.map(x => x.fieldName).join(',') + ').');
    }

    // Mapping that dows not exist
    const mappingGetDontExist = this.fbForm.formFields.filter(x => x.mappingColumnGet &&
      !this.mappingColumnsFiltered.some(y => y.value === x.mappingColumnGet));
    if (mappingGetDontExist && mappingGetDontExist.length > 0) {
      errros.push('Some fields with mapping DB get that dont exist anymore ('
        + mappingGetDontExist.map(x => x.fieldName).join(',') + ').');
    }
    const mappingSetDontExist = this.fbForm.formFields.filter(x => x.mappingColumnsSet &&
      x.mappingColumnsSet.length > 0 &&
      x.mappingColumnsSet.some(setColumnID => !this.mappingColumnsFiltered.some(y => y.value === setColumnID)));
    if (mappingSetDontExist && mappingSetDontExist.length > 0) {
      errros.push('Some fields with mapping DB set that dont exist anymore ('
        + mappingSetDontExist.map(x => x.fieldName).join(',') + ').');
    }

    // One or Many option without any options or optionstable
    const optionWithoutOptions = this.fbForm.formFields.filter(x =>
      (x.fieldTypeID === FBFieldType.OneOption || x.fieldTypeID === FBFieldType.ManyOptions)
      && !(x.fieldOptions || x.optionsTable));
    if (optionWithoutOptions && optionWithoutOptions.length > 0) {
      errros.push('Some fields of type OneOption or ManyOptions without any options or options table ('
        + optionWithoutOptions.map(x => x.fieldName).join(',') + ').');
    }

    // Check Document for inexistent fields and wrong formated fields
    if (this.fbForm.baseDocHTML) {
      // Get all field names in the baseDocHTML in the format '<span class="mention" data-mention="#FIELDNAME>'
      const matchedFields = this.fbForm.baseDocHTML
        .match(/<span class="mention" data-mention="#([^"]*)">/g);
      if (matchedFields) {
        const fieldsInDocument = matchedFields
          .map(x => x.replace('<span class="mention" data-mention="', '').replace('">', ''));

        // Check if the fields exist
        const allFields = this.getAvailableDocumentFields();
        const inexistentFields = fieldsInDocument.filter(x => !allFields.some(y => y === x) && x.startsWith('IF[') && !x.startsWith('IF[NOTEMPTY'));
        if (inexistentFields.length > 0) {
          errros.push('Some fields in the document dont exist anymore ('
            + inexistentFields.join(',') + ').');
        }
      }
    }

    // Check Document Summary for inexistent fields and wrong formated fields
    if (this.fbForm.baseSummaryDocHTML) {
      // Get all field names in the baseDocHTML in the format '<span class="mention" data-mention="#FIELDNAME>'
      const matchedFields = this.fbForm.baseSummaryDocHTML
        .match(/<span class="mention" data-mention="#([^"]*)">/g);
      if (matchedFields) {
        const fieldsInDocument = matchedFields
          .map(x => x.replace('<span class="mention" data-mention="', '').replace('">', ''));

        // Check if the fields exist
        const allFields = this.getAvailableDocumentFields();
        const inexistentFields = fieldsInDocument.filter(x => !allFields.some(y => y === x) && x.startsWith('IF[') && !x.startsWith('IF[NOTEMPTY'));
        if (inexistentFields.length > 0) {
          errros.push('Some fields in the document summary dont exist anymore ('
            + inexistentFields.join(',') + ').');
        }
      }
    }

    return errros;
  }

  async save() {
    updateFormGroupValidity(this.form);
    const inconsitencesFound = this.formInconsistences();
    if (inconsitencesFound && inconsitencesFound.length > 0) {
      const allErrors = inconsitencesFound.reduce((acc, curr) => acc + '; ' + curr);
      this.alertService.error('Please check the form for consistence: ' + allErrors);
      return;
    }

    if (this.form.valid) {
      if (this.hmtlOrPDF === 'html' && !this.fbForm.baseDocHTML && this.currentFormTypeID != 22 && this.currentFormTypeID != 5) {
        this.alertService.error('You have not added anything to the document.');
        return;
      }
      if (this.hmtlOrPDF === 'html' && this.currentFormTypeID == 17 && this.fbForm.showSignature == true && this.fbForm.baseDocHTML.indexOf('#Signature') < 0) {
        this.alertService.error('You have selected to show signature field on this notice. The document requires #Signature on the page in order to save.');
        return;
      }
      if (this.hmtlOrPDF === 'html' && this.fbForm.formTypeID !== 5 && (this.currentFormTypeID != 17 && this.currentFormTypeID != 19 && this.currentFormTypeID != 21 && this.currentFormTypeID != 22 && this.currentFormTypeID != 23 && this.currentFormTypeID != 25 && this.currentFormTypeID != 27 && this.fbForm.baseDocHTML.indexOf('#Signature') < 0)) {
        this.alertService.error('The document must have the #Signature mark somewhere.');
        return;
      } else {
        if (this.hmtlOrPDF === 'pdf') {
          this.fbForm.baseDocHTML = '';
          if (!this.fbForm.baseDocFile) {
            this.fbForm.baseDocFile = {} as any;
          }
          const base64String = arrayBufferToBase64(this.currentPDFFile);
          this.fbForm.baseDocFile.base64Data = base64String;
        }
      }
      this.fbForm.formFields.forEach(x => {
        if (x.calculatedRelatedFieldIDsArray) {
          x.calculatedRelatedFieldIDs = x.calculatedRelatedFieldIDsArray.join(',');
        }
      });
      this.store.dispatch(saveFBForm({ form: this.fbForm }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

  changedFile(fileInput) {
  }

  getPreviewUrl(doc) {
    if (doc.fileType.indexOf('image') >= 0) {
      return doc.resumeURL;
    }

    if (doc.fileType.indexOf('pdf') >= 0) {
      return '/assets/img/pdf.png';
    }

    if (doc.fileType.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') >= 0
      || doc.fileType.indexOf('doc') >= 0
      || doc.fileType.indexOf('msword') >= 0) {
      return '/assets/img/doc.png';
    }
  }

  removeField(field: FBFormFieldModel) {
    this.store.dispatch(removeFBFormField({ field }));
  }

  closeField(event, element) {
    element.hide(event);
  }

  addField() {
    let maxLocalID = 0;
    let maxOrder = 0;
    if (this.fbForm.formFields && this.fbForm.formFields.some(x => !!x.localID)) {
      maxLocalID = Math.max.apply(Math, this.fbForm.formFields.map((o) => o.localID ? o.localID : 0));
    }
    if (this.fbForm.formFields && this.fbForm.formFields.some(x => !!x.fieldOrder)) {
      maxOrder = Math.max.apply(Math, this.fbForm.formFields.map((o) => o.fieldOrder ? o.fieldOrder : 0));
    }
    const locaID = (maxLocalID ? maxLocalID : 0) + 1;
    this.store.dispatch(updateFBFormField({
      field: {
        fieldName: 'New Field',
        fieldTypeID: 1,
        fieldWidthRatio: 4,
        opened: true,
        localID: locaID,
        isRequired: false,
        allowOther: false,
        isEncrypted: false,
        readOnly: false,
        fieldOrder: (maxOrder ? maxOrder : 0) + 1,
        mappingColumnsSet: [],
      }
    }));
    setTimeout(() => {
      const element = document.getElementById('DivField' + locaID);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 500);
  }

  openFieldSettings(field: FBFormFieldModel, op: OverlayPanel, event) {
    op.hide();
    if (this.selectedFormLanguage) {
      if (!field.formFieldLanguages) {
        field.formFieldLanguages = [];
      }
      let fieldLanguageOpened = field.formFieldLanguages.find(x => x.languageID === this.selectedFormLanguage.languageID);
      if (!fieldLanguageOpened) {
        fieldLanguageOpened = {
          languageID: this.selectedFormLanguage.languageID,
          fieldDescription: '', tooltip: '', fieldOptions: '', defaultText: ''
        };
      }
      this.fieldLanguageOpened = fieldLanguageOpened;
    } else {
      this.fieldLanguageOpened = null;
    }
    this.fieldOpened = field;
    op.show(event);
  }

  editField(field: FBFormFieldModel) {
    if (field.fieldTypeID && (!field.formFieldType || field.formFieldType.id !== field.fieldTypeID)) {
      field.formFieldType = {
        id: field.fieldTypeID,
        formFieldType: this.formFieldTypeOptions.find(x => x.value === field.fieldTypeID).label,
      };
    }
    if (field.formFieldGroupID && (!field.formFieldGroup || field.formFieldGroup.id !== field.formFieldGroupID)) {
      field.formFieldGroup = this.fbForm.formFieldGroups.find(x => x.localID === field.formFieldGroupID);
    }

    if (this.selectedFormLanguage && this.fieldLanguageOpened) {
      const indexFieldLanguege = field.formFieldLanguages.findIndex(x => x.languageID === this.selectedFormLanguage.languageID);
      if (indexFieldLanguege >= 0) {
        field.formFieldLanguages = deepClone(field.formFieldLanguages);
        field.formFieldLanguages[indexFieldLanguege] = deepClone(this.fieldLanguageOpened);
      } else {
        if (!field.formFieldLanguages) {
          field.formFieldLanguages = [];
        }
        field.formFieldLanguages.push(deepClone(this.fieldLanguageOpened));
      }
    }

    this.store.dispatch(updateFBFormField({ field: { ...field, ...{ opened: true } } }));
  }

  changeOrderUp(field: FBFormFieldModel) {
    let indexOfField;
    this.fbForm.formFields.forEach((f, i) => {
      if (f === field) {
        indexOfField = i;
      }
    });
    if (indexOfField > 0) {
      field.fieldOrder = this.fbForm.formFields[indexOfField - 1].fieldOrder;
      this.store.dispatch(updateFBFormField({ field }));
    }
  }

  changeOrderDown(field: FBFormFieldModel) {
    let indexOfField;
    this.fbForm.formFields.forEach((f, i) => {
      if (f === field) {
        indexOfField = i;
      }
    });
    if (indexOfField < this.fbForm.formFields.length - 1) {
      field.fieldOrder = this.fbForm.formFields[indexOfField + 1].fieldOrder + 1;
      this.store.dispatch(updateFBFormField({ field }));
    }
  }

  formTypeChange() {
    this.mappingColumnsFiltered =
      this.mappingColumnsAvailable
        .filter(f => f.formTypeID === this.fbForm.formTypeID)
        .map(x => ({ label: x.friendlyName, value: x.mappingColumnID }));
  }

  saveField(event, element) {
    updateFormGroupValidity(this.fieldForm);

    if (this.fieldForm.valid) {
      this.store.dispatch(updateFBFormField({ field: this.fieldOpened }));
      this.fieldOpened = null;
      this.fieldLanguageOpened = null;
      this.fieldForm.reset();

    } else {
      markFormGroupTouched(this.fieldForm);
    }
  }

  public onReady(editor) {
    this.currentEditor = editor;
    editor.execute('fontFamily', { value: 'Arial' });
    const documentEditor = document.querySelector('#divDocumentEditor');
    documentEditor.appendChild(editor.ui.view.toolbar.element);
    const documentEditorContainer = document.querySelector('#divDocumentEditorEditableContainter');
    documentEditorContainer.appendChild(editor.ui.getEditableElement());
    documentEditor.appendChild(documentEditorContainer);
  }

  public onSummaryReady(editor) {
    this.currentSummaryEditor = editor;
    editor.execute('fontFamily', { value: 'Arial' });
    const documentSummaryEditor = document.querySelector('#divDocumentSummaryEditor');
    documentSummaryEditor.appendChild(editor.ui.view.toolbar.element);
    const documentSummaryEditorContainer = document.querySelector('#divDocumentSummaryEditorEditableContainter');
    documentSummaryEditorContainer.appendChild(editor.ui.getEditableElement());
    documentSummaryEditor.appendChild(documentSummaryEditorContainer);
  }

  getAvailableDocumentFields() {
    const allItems = this.fbForm.formFields.map(x => '#' + x.fieldName);

    allItems.push(...this.fbForm.formFields.map(x => '#IF[NOTEMPTY(' + x.fieldName + ')]'));
    allItems.push(...this.fbForm.formFields.map(x => '#ENDIF[NOTEMPTY(' + x.fieldName + ')]'));


    allItems.push(...this.fbForm.formFields.filter(x => x.fieldTypeID === FBFieldType.Section).map(x => '#' + x.fieldName + '-SECTIONSTART'));
    allItems.push(...this.fbForm.formFields.filter(x => x.fieldTypeID === FBFieldType.Section).map(x => '#' + x.fieldName + '-SECTIONEND'));

    // Special fields
    allItems.push('#Signature');
    allItems.push('#DateSignature');
    allItems.push('#Initials');
    allItems.push('#SignatureOptional');
    allItems.push('#InitialsOptional');
    //allItems.push('#ENDIF');
    allItems.push('#CompanyName');
    allItems.push('#FASTChart');
    allItems.push('#BSPTitle');

    if (this.fbForm.requireHRSignature) {
      allItems.push('#Signature#HRUser#');
    }

    if (this.fbForm.clientOrEmployee === 'Client') {
      allItems.push('#ClientFullName');
      allItems.push('#ClientFirstName');
      allItems.push('#ClienLastName');
    }
0
    if (this.fbForm.formTypeID === 4) { //Offerletter
      var offerLetterItems = [];
      offerLetterItems.push('#OfferletterFullName');
      offerLetterItems.push('#OfferletterDate');
      offerLetterItems.push('#OfferletterPosition');
      offerLetterItems.push('#OfferletterReportingTo');
      offerLetterItems.push('#OfferletterRegion');
      offerLetterItems.push('#OfferletterHourlySalary');
      offerLetterItems.push('#OfferletterAnnualSalary');
      offerLetterItems.push('#OfferletterRequiredBilledHours');
      offerLetterItems.push('#OfferletterBiweeklySalary');
      offerLetterItems.push('#OfferletterFlexibleSchedule');
      offerLetterItems.push('#OfferletterTrainingRate');
      offerLetterItems.push('#OfferletterBonusByTypeValue');
      offerLetterItems.push('#OfferletterHealthBenefitAnnualCost');
      offerLetterItems.push('#OfferletterHealthBenefitAnnualCostMonth');
      offerLetterItems.push('#OfferletterHealthBenefitAnnualCostBiweekly');
      offerLetterItems.push('#OfferletterK401Value');
      offerLetterItems.push('#OfferletterMedClinicValue');
      offerLetterItems.push('#OfferletterMentalHealthValue');
      offerLetterItems.push('#OfferletterGymValue');
      offerLetterItems.push('#OfferletterStudentLoan');
      offerLetterItems.push('#OfferletterStudentLoanYear');
      offerLetterItems.push('#OfferletterCellphoneStipendBiweek');
      offerLetterItems.push('#OfferletterCellphoneStipend');
      offerLetterItems.push('#OfferletterCellphoneStipendYear');
      offerLetterItems.push('#OfferletterGasCardBiweek');
      offerLetterItems.push('#OfferletterGasCard');
      offerLetterItems.push('#OfferletterGasCardYear');
      offerLetterItems.push('#OfferletterRelocationStipend');
      offerLetterItems.push('#OfferletterPTOValue');
      offerLetterItems.push('#OfferletterHolidayValue');
      offerLetterItems.push('#OfferletterLiabilityInsurance');
      offerLetterItems.push('#OfferletterEletronicDevicesValue');
      offerLetterItems.push('#OfferletterRegulatoryOversight');
      offerLetterItems.push('#OfferletterTuitionValue');
      offerLetterItems.push('#OfferletterSupervisionValue');
      offerLetterItems.push('#OfferletterOtherBenefit');
      offerLetterItems.push('#OfferletterOtherBenefitAmount');
      offerLetterItems.push('#OfferletterTotalCompensationAndBenefit');
      offerLetterItems.push('#OfferletterMonthsAfter');
      offerLetterItems.push('#OfferletterSalaryPayRateAfterMonths');
      offerLetterItems.push('#OfferletterAnnualOrHourly');
      offerLetterItems.push('#OfferletterTotalCompensationAndBenefitAfterMonths');
      offerLetterItems.push('#OfferletterCreatedBy');
      offerLetterItems.push('#OfferletterCreatedByPosition');
      offerLetterItems.push('#OfferletterValidUntilDate');
      offerLetterItems.push('#OfferletterNewOrAdjustment');
      offerLetterItems.push('#OfferletterHasProfitSharing');
      offerLetterItems.push('#OfferletterHasIncentivePrograms');
      offerLetterItems.push('#OfferletterHealthBenefitPartTime');
      offerLetterItems.push('#OfferletterHealthBenefitFullTime');


      offerLetterItems.push('#OfferletterHideK401Value');
      offerLetterItems.push('#OfferletterHideMedClinicValue');
      offerLetterItems.push('#OfferletterHideMentalHealthValue');
      offerLetterItems.push('#OfferletterHideGymValue');
      offerLetterItems.push('#OfferletterHideStudentLoan');
      offerLetterItems.push('#OfferletterHideCellphoneStipend');
      offerLetterItems.push('#OfferletterHideGasCard');
      offerLetterItems.push('#OfferletterHideRelocationStipend');
      offerLetterItems.push('#OfferletterHidePTOValue');
      offerLetterItems.push('#OfferletterHideHolidayValue');
      offerLetterItems.push('#OfferletterHideLiabilityInsurance');
      offerLetterItems.push('#OfferletterHideEletronicDevicesValue');
      offerLetterItems.push('#OfferletterHideRegulatoryOversight');
      offerLetterItems.push('#OfferletterHideTuitionValue');
      offerLetterItems.push('#OfferletterHideSupervisionValue');
      offerLetterItems.push('#OfferletterHideOtherBenefitValue');
      offerLetterItems.push('#OfferletterHideBonusByTypePositionValue');
      offerLetterItems.push('#OfferletterHideHealthBenefitAnnualCostValue');
      
      
      
      
        
      allItems.push(...offerLetterItems);
      allItems.push(...offerLetterItems.map(x => '#IF[NOTEMPTY(' + x.replace('#','') + ')]'));
      allItems.push(...offerLetterItems.map(x => '#ENDIF[NOTEMPTY(' + x.replace('#','') + ')]'));
      
    }
    return allItems;
  }

  getFeedItems(queryText) {
    return new Promise(resolve => {

      const allItems = this.getAvailableDocumentFields();
      const itemsToDisplay =
        allItems.filter(x => x.toLowerCase().includes(queryText.toLowerCase()));


      resolve(itemsToDisplay);
    });
  }

  changeSeeAsHTML() {
    this.seeAsHTML = !this.seeAsHTML;
    if (this.selectedFormLanguage) {
      this.fbForm.formLanguages.find(x => x.languageID === this.selectedFormLanguage.languageID).baseDocHTML =
        html_beautify(this.fbForm.formLanguages.find(x => x.languageID === this.selectedFormLanguage.languageID).baseDocHTML, { indent_size: 2, space_in_empty_paren: true, preserve_newlines: false });
    } else {
      this.fbForm.baseDocHTML =
        html_beautify(this.fbForm.baseDocHTML, { indent_size: 2, space_in_empty_paren: true, preserve_newlines: false });
    }
  }

  changeSeeSummaryAsHTML() {
    this.seeSummaryAsHTML = !this.seeSummaryAsHTML;
    this.fbForm.baseSummaryDocHTML =
      html_beautify(this.fbForm.baseSummaryDocHTML, { indent_size: 2, space_in_empty_paren: true, preserve_newlines: false });
  }

  changedBaseDocHTML(language?: FBFormLanguageModel) {
    if (language) {
      this.store.dispatch(updateFBFormForm({ formValues: { formLanguages: this.fbForm.formLanguages }, formErrors: [] }));
    } else {
      this.store.dispatch(updateFBFormForm({ formValues: { baseDocHTML: this.fbForm.baseDocHTML }, formErrors: [] }));
    }
  }

  changedBaseSummaryDocHTML(language?: FBFormLanguageModel) {
    if (language) {
      this.store.dispatch(updateFBFormForm({ formValues: { formLanguages: this.fbForm.formLanguages }, formErrors: [] }));
    } else {
      this.store.dispatch(updateFBFormForm({ formValues: { baseSummaryDocHTML: this.fbForm.baseSummaryDocHTML }, formErrors: [] }));
    }
  }

  changedEditorData({ editor }: ChangeEvent, language?: FBFormLanguageModel) {
    const prettyHTML = html_beautify(editor.getData(), { indent_size: 2, space_in_empty_paren: true, preserve_newlines: false });
    if (language) {
      this.fbForm.formLanguages.find(x => x.languageID === language.languageID).baseDocHTML = prettyHTML;
      this.store.dispatch(updateFBFormForm({ formValues: { formLanguages: this.fbForm.formLanguages }, formErrors: [] }));
    } else {
      this.store.dispatch(updateFBFormForm({ formValues: { baseDocHTML: prettyHTML }, formErrors: [] }));
    }
  }
  changedSummaryEditorData({ editor }: ChangeEvent, language?: FBFormLanguageModel) {
    const prettyHTML = html_beautify(editor.getData(), { indent_size: 2, space_in_empty_paren: true, preserve_newlines: false });
    if (language) {
      this.fbForm.formLanguages.find(x => x.languageID === language.languageID).baseSummaryDocHTML = prettyHTML;
      this.store.dispatch(updateFBFormForm({ formValues: { formLanguages: this.fbForm.formLanguages }, formErrors: [] }));
    } else {
      this.store.dispatch(updateFBFormForm({ formValues: { baseSummaryDocHTML: prettyHTML }, formErrors: [] }));
    }
  }

  async changedHmtlOrPDF() {
    if (this.hmtlOrPDF === 'pdf') {
      setTimeout(() => {
        if (this.currentPDFFile) {
          //this.LoadFileOnPDFEditor(this.currentPDFFile);
        }
      }, 100);
    } else {
      // if (this.gcPdfViewer) {
      //   await this.gcPdfViewer.saveChanges();
      //   const downloadURL = this.gcPdfViewer.supportApi.getDownloadUrl('savingFile.pdf', 'PDF', '');
      //   await this.gcPdfViewer.open(downloadURL);
      //   this.currentPDFFile = this.gcPdfViewer.fileData;

      //   this.gcPdfViewer.close();
      //   this.gcPdfViewer.dispose();
      //   this.gcPdfViewer = null;
      //   document.getElementById('pdf-viewer').innerHTML = '';

      // }
    }

  }


  downloadCurrentPDF() {
    downloadFile(this.currentPDFFile, this.fbForm.name + '_base.pdf', true);
  }

  selectedPDFFile(fileInput) {
    if (fileInput.target.files.length > 0) {
      const file: File = fileInput.target.files[0];
      this.changeFile = false;
      file.arrayBuffer().then(arrayBufferFileParam => {
        const arrayBufferFile = arrayBufferFileParam;
        //this.LoadFileOnPDFEditor(arrayBufferFile);
        this.currentPDFFile = arrayBufferFile;
      });
    }
  }

  addFieldGroup() {
    let maxLocalID = 0;
    let maxOrder = 0;
    if (this.fbForm.formFieldGroups && this.fbForm.formFieldGroups.some(x => !!x.localID)) {
      maxLocalID = Math.max.apply(Math, this.fbForm.formFieldGroups.map((o) => o.localID ? o.localID : 0));
    }
    if (this.fbForm.formFieldGroups && this.fbForm.formFieldGroups.some(x => !!x.order)) {
      maxOrder = Math.max.apply(Math, this.fbForm.formFieldGroups.map((o) => o.order ? o.order : 0));
    }
    const localID = (maxLocalID ? maxLocalID : 0) + 1;
    this.store.dispatch(updateFBFormFieldGroup({
      fieldGroup: {
        groupName: 'New Field Group',
        localID: localID,
        order: (maxOrder ? maxOrder : 0) + 1,
      }
    }));
  }

  changeGroupOrderUp(fieldGroup: FBFormFieldGroupModel) {
    let indexOfField;
    this.fbForm.formFieldGroups.forEach((f, i) => {
      if (f === fieldGroup) {
        indexOfField = i;
      }
    });
    if (indexOfField > 0) {
      fieldGroup.order = this.fbForm.formFieldGroups[indexOfField - 1].order;
      this.store.dispatch(updateFBFormFieldGroup({ fieldGroup: fieldGroup }));
    }
  }

  changeGroupOrderDown(fieldGroup: FBFormFieldGroupModel) {
    let indexOfField;
    this.fbForm.formFieldGroups.forEach((f, i) => {
      if (f === fieldGroup) {
        indexOfField = i;
      }
    });
    if (indexOfField < this.fbForm.formFieldGroups.length - 1) {
      fieldGroup.order = this.fbForm.formFieldGroups[indexOfField + 1].order + 1;
      this.store.dispatch(updateFBFormFieldGroup({ fieldGroup: fieldGroup }));
    }
  }

  deleteFieldGroup(fieldGroup: FBFormFieldGroupModel) {
    if (this.fbForm.formFields.some(x => x.formFieldGroupID === fieldGroup.localID)) {
      this.alertService.info('This field group is being used by some fields. Please remove the fields from the group before deleting it.');
      return;
    }
    this.store.dispatch(removeFBFormFieldGroup({ fieldGroup }));
  }

  getFieldGroupLanguage(fieldGroup: FBFormFieldGroupModel) {
    if (!fieldGroup.formFieldGroupLanguages) {
      fieldGroup.formFieldGroupLanguages = [];
    }
    let fieldGroupLanguage = fieldGroup.formFieldGroupLanguages.find(x => x.languageID === this.selectedFormLanguage.languageID);
    if (fieldGroupLanguage) {
      return fieldGroupLanguage;
    } else {
      fieldGroupLanguage = { languageID: this.selectedFormLanguage.languageID, groupName: '' } as FBFormFieldGroupLanguageModel;
      fieldGroup.formFieldGroupLanguages.push(fieldGroupLanguage);
      return fieldGroupLanguage;
    }
  }

  editFieldGroupLanguage(newValue, fieldGroupLanguage, fieldGroup: FBFormFieldGroupModel) {
    fieldGroupLanguage.groupName = newValue;
    fieldGroup = deepClone(fieldGroup);
    this.store.dispatch(updateFBFormFieldGroup({ fieldGroup: { ...fieldGroup } }));
  }

  editFieldGroup(fieldGroup: FBFormFieldGroupModel) {
    this.store.dispatch(updateFBFormFieldGroup({ fieldGroup: { ...fieldGroup } }));
  }

  getFieldGroupName(fieldGroupID: number) {
    if (fieldGroupID) {
      return this.fbForm.formFieldGroups.find(x => x.localID === fieldGroupID).groupName;
    }
  }

  trackByFn(index, item) {
    return index;
  }
  addLanguage() {
    if (this.addingLanguage) {
      this.store.dispatch(addFBFormLanguage({ language: this.addingLanguage }));
    }
    this.addingLanguage = null;
    this.addLanguageOpened = false;
  }
  deleteLanguage(formLanguage: FBFormLanguageModel) {
    this.alertService.confirm('Confirm', 'Are you sure you want to delete this language?').subscribe(result => {
      if (result) {
        this.store.dispatch(removeFBFormLanguage({ language: formLanguage }));
      }
    });
  }

  selectLanguage(formLanguage: FBFormLanguageModel) {
    this.selectedFormLanguage = formLanguage;
    this.form.get('languageName').setValue(formLanguage.name);
  }

  getJobTitles() {
    var ids = this.form ? this.form.get('jobTitleIDs').value : [];
    const jobTitleIds = ids ? ids : [];
    this.jobTitleOptions = this.allJobTitleOptions.filter(x => x.archived == false || jobTitleIds.includes(x.value));
  }

}
