import { BaseComponent } from './../../../../core/abstracts/baseComponent';
import { BenefitsState } from './../../../../core/store/benefits/benefits.reducer';
import { BenefitPlanModel } from './../../../../models/benefitsModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { MultiSelect } from 'primeng/multiselect';
import { AlertService } from '../../../../shared/services/alert.service';
import { deepClone, downloadFile, getTimeStampString } from '../../../../helpers/utils';
import { deleteBenefitsPlans, deleteBenefitsPlansSuccess, downloadEnrollmentSignedForms, downloadEnrollmentSignedFormsSuccess, exportAllDentalSelectEnrollmentListToExcel, exportAllDentalSelectEnrollmentListToExcelSuccess, exportEnrollmentListToExcel, exportEnrollmentListToExcelSuccess, loadBenefitsPlans } from '../../../../core/store/benefits/benefits.actions';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { tap } from 'rxjs/operators';
import { DateRangeModel } from '../../../../models/dateRangeModel';
import { DropdownFilterService } from '../../../../core/services/dropdown-filter.service';

@Component({
  selector: 'app-list-benefits-plans',
  templateUrl: './list-benefits-plans.component.html',
  styleUrls: ['./list-benefits-plans.component.scss']
})
export class ListBenefitsPlansComponent extends BaseComponent implements OnInit {

  benefitPlanList: PagingResultsModel<BenefitPlanModel>;
  canEdit: boolean;
  loadingBenefitPlanList = false;
  loadingExcelDownload = false;
  downloadingZipFile = false;
  filter: any;
  yesNoAllOptions = [
    { label: 'All', value: null },
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  datesOptions = [
    { label: 'This Year', value: 1 },
    { label: 'Last Year', value: 2 },
    { label: 'Next Year', value: 3 },
    { label: 'Custom', value: 0 },
  ];

  planNameFilter;
  carrierFilter;
  periodDateFilter;
  periodDateOptionFilter;

  excelDownloadCarrier: string = "BenefitEnrollment";
  zipDownloadCarrier: string = "ZipFile";

  dateEnrolledOptionList: SelectItem[];
  dateEnrolledRange: DateRangeModel;
  dateEnrolledStart: string = null;
  dateEnrolledEnd: string = null;
  isCustomDateEnrolled: boolean = false;
  dateEnrolledCustomFilter;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router,
    private confirmationService: ConfirmationService,
    private dropdownFilterService: DropdownFilterService
  ) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 6050);
      }),
      this.store.select(s => s.benefitsState.benefitPlanList).subscribe(benefitPlanList => {
        if (benefitPlanList) {
          this.benefitPlanList = benefitPlanList;
        }
      }),

      this.store.select(s => s.benefitsState.loadingBenefitPlanList).subscribe(loadingBenefitPlanList => {
        this.loadingBenefitPlanList = loadingBenefitPlanList;
      }),
      this.store.select(s => s.benefitsState.loadingEnrollmentsExcel).subscribe(loadingExcelDownload => {
        this.loadingExcelDownload = loadingExcelDownload;
      }),
      this.store.select(s => s.benefitsState.downloadingSignedDocumentsZipFile).subscribe(downloadingZipFile => {
        this.downloadingZipFile = downloadingZipFile;
      }),
      this.actions$.pipe(
        ofType(deleteBenefitsPlansSuccess),
        tap(action => {
          this.loadPage(this.filter);
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(exportEnrollmentListToExcelSuccess),
        tap(action => {
          downloadFile(action.doc, this.getExcelFileName());
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(exportAllDentalSelectEnrollmentListToExcelSuccess),
        tap(action => {
          downloadFile(action.doc, this.getExcelFileName());
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(downloadEnrollmentSignedFormsSuccess),
        tap(action => {
          downloadFile(action.doc, this.getZipFileName());
        })
      ).subscribe()
    );

    this.dateEnrolledOptionList = this.dropdownFilterService.getWeekMonthAllDateRanges().map(c => ({ label: c.text, value: c }));
  }

  getZipFileName() {
    let fileName = this.zipDownloadCarrier + "_" + getTimeStampString() + ".zip";

    return fileName;
  }

  getExcelFileName() {
    let fileName = 'BenefitPlanEnrollment_';
    if (this.excelDownloadCarrier.indexOf("JP Farley") >= 0) {
      fileName = "JPFarleyEnrollment_";
    }
    else if (this.excelDownloadCarrier.indexOf("Assurity") >= 0) {
      fileName = "AssurityCensus_UtahBehaviorServices_";
    }

    fileName += getTimeStampString();
    fileName += ".xlsx"

    return fileName;
  }

  loadPage(event) {
    if (!this.loadingBenefitPlanList) {
      this.loadingBenefitPlanList = true;
      this.filter = deepClone(event);
      this.filter.filters.planNameFilter = { Name: 'planNameFilter', Value: { value: this.planNameFilter } };
      this.filter.filters.carrierFilter = { Name: 'carrierFilter', Value: { value: this.carrierFilter } };
      if (this.periodDateOptionFilter > 0) {
        let year = new Date().getFullYear();
        if (this.periodDateOptionFilter === 2) {
          year = year - 1;
        }
        if (this.periodDateOptionFilter === 3) {
          year = year + 1;
        }
        const start = new Date(year, 0, 1);
        const end = new Date(year, 11, 31);
        this.filter.filters.createdDateFilterStart = { Name: 'createdDateFilterStart', Value: { value: start.toISOString() } };
        this.filter.filters.createdDateFilterEnd = { Name: 'createdDateFilterEnd', Value: { value: end.toISOString() } };
      } else {
        if (this.periodDateFilter) {
          this.filter.filters.createdDateFilterStart = { Name: 'createdDateFilterStart', Value: { value: this.periodDateFilter[0] } };
          this.filter.filters.createdDateFilterEnd = { Name: 'createdDateFilterEnd', Value: { value: this.periodDateFilter[1] } };
        } else {
          this.filter.filters.createdDateFilterStart = { Name: 'createdDateFilterStart', Value: { value: null } };
          this.filter.filters.createdDateFilterEnd = { Name: 'createdDateFilterEnd', Value: { value: null } };
        }
      }

      this.filter.filters.dateEnrolledStart = { Name: 'dateEnrolledStart', Value: { value: this.dateEnrolledStart } };
      this.filter.filters.dateEnrolledEnd = { Name: 'dateEnrolledEnd', Value: { value: this.dateEnrolledEnd } };

      this.store.dispatch(loadBenefitsPlans({
        filter: deepClone(this.filter)
      }));
    }
  }

  changedDateEnrolledCustomFilter() {
    if (this.dateEnrolledCustomFilter) {
      this.dateEnrolledStart = this.dateEnrolledCustomFilter[0];
      this.dateEnrolledEnd = this.dateEnrolledCustomFilter[1];
    }
    else {
      this.dateEnrolledStart = null;
      this.dateEnrolledEnd = null;
    }

    this.changedFilters();
  }

  changedEnrolledDateRange() {
    if (this.dateEnrolledRange.isCustom) {
      this.isCustomDateEnrolled = true;
      return; //reload after manually changing date
    }

    this.isCustomDateEnrolled = false;
    this.dateEnrolledStart = this.dateEnrolledRange.startDate;
    this.dateEnrolledEnd = this.dateEnrolledRange.endDate;
    this.changedFilters();
  }

  changedFilters() {
    this.loadPage(this.filter);
  }


  editBenefitsPlan(benefitPlan: BenefitPlanModel) {
    this.router.navigate(['/benefits-management/benefit-plan/addit/' + benefitPlan.id]);
  }

  deleteBenefitsPlan(benefitPlan: BenefitPlanModel) {
    this.confirmationService.confirm({
      header: 'Delete',
      message: 'Are you sure you want to deactivate this plan?',
      acceptLabel: 'Deactivate',
      rejectLabel: 'Cancel',
      accept: () => {
        this.store.dispatch(deleteBenefitsPlans({ benefitsPlanID: benefitPlan.id }));
      }
    });
  }

  downloadSignDocumentZipFile(benefitPlan: BenefitPlanModel) {
    if (this.downloadingZipFile) {
      return;
    }

    this.zipDownloadCarrier = benefitPlan.carrier;

    this.store.dispatch(downloadEnrollmentSignedForms({ benefitPlanId: benefitPlan.id }));
  }

  exportEnrollments(benefitPlan: BenefitPlanModel) {
    if (this.loadingExcelDownload) {
      return;
    }

    this.excelDownloadCarrier = benefitPlan.carrier;
    let exportFilter:any = {};
    exportFilter.filters = {};
    exportFilter.filters.planId = { Name: 'planId', Value: { value: benefitPlan.id.toString() } };
    exportFilter.filters.dateEnrolledStart = { Name: 'dateEnrolledStart', Value: { value: this.dateEnrolledStart } };
    exportFilter.filters.dateEnrolledEnd = { Name: 'dateEnrolledEnd', Value: { value: this.dateEnrolledEnd } };


    this.store.dispatch(exportEnrollmentListToExcel({ filter: exportFilter }));
  }

  exportAllDentalSelectEnrollments(benefitPlan: BenefitPlanModel) {
    if (this.loadingExcelDownload) {
      return;
    }

    this.excelDownloadCarrier = benefitPlan.carrier;
    let exportFilter: any = {};
    exportFilter.filters = {};
    exportFilter.filters.planId = { Name: 'planId', Value: { value: benefitPlan.id.toString() } };
    exportFilter.filters.dateEnrolledStart = { Name: 'dateEnrolledStart', Value: { value: this.dateEnrolledStart } };
    exportFilter.filters.dateEnrolledEnd = { Name: 'dateEnrolledEnd', Value: { value: this.dateEnrolledEnd } };


    this.store.dispatch(exportAllDentalSelectEnrollmentListToExcel({ filter: exportFilter }));
  }

  showExport(benefitPlan: BenefitPlanModel) {
    if (this.canEdit == false) {
      return false;
    }

    return benefitPlan.carrier == "JP Farley" || benefitPlan.carrier == "MotivHealth" || benefitPlan.carrier == "Assurity Life Insurance Company" || benefitPlan.carrier == "Dental Select";
  }

  showDentalSelectExport(benefitPlan: BenefitPlanModel) {
    if (this.canEdit == false) {
      return false;
    }

    return benefitPlan.carrier == "Dental Select";
  }

  openMultiSelect(multiselect: MultiSelect) {
    multiselect.show();
  }
}

